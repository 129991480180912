import React, { useCallback } from 'react';

import { FormProducts, FormCheckbox } from 'helpers/form';
import { ISmartGroupFormComponent } from 'types/smart-group';
import { Stack } from '@mantine/core';

export const keysProducts = ['product_ids', 'include_descendants'];

export const SmartGroupFormProducts: React.FC<ISmartGroupFormComponent> = ({ form }) => {
  const { handlers } = form;
  const handleChangeDescendants = useCallback(
    ({ include_descendants }) => {
      handlers.onChange({ include_descendants, ...(include_descendants ? {} : { include_future_descendants: false }) });
    },
    [handlers],
  );

  return (
    <div className="smart-group-wizard__products-tab">
      <FormProducts name="product_ids" label="Products" fitInParent {...form.formData.product_ids} {...form.handlers} />
      <Stack gap={0}>
        <FormCheckbox
          name="include_descendants"
          label="Include descendant product(s)"
          {...form.formData.include_descendants}
          {...form.handlers}
          onChange={handleChangeDescendants}
        />
        <FormCheckbox
          name="include_future_descendants"
          label="Automatically include all future descendant products"
          {...form.formData.include_future_descendants}
          {...form.handlers}
          className="ms-3 mb-0"
          disabled={!form.values.include_descendants}
        />
      </Stack>
    </div>
  );
};
