import React, { useCallback } from 'react';
import { FormAssetStatus, useMm3Form } from 'helpers/form';
import { useStore } from 'store';
import { IAsset, IMm3Asset, ItemId } from 'types';
import { Model } from 'helpers/filters/types';
import { useRefreshDataSection } from 'utils/hooks';
import { changeAssetAccessImmediate } from 'utils/apis/asset';
import { FormSubmitSection } from 'components/form-submit-section';

type IAssetStatus = Pick<IAsset | IMm3Asset, 'status'> & { item_ids: ItemId[] };

const ChangeStatusForm: React.FC<{
  asset: IAsset | IMm3Asset;
  setAsset?: React.Dispatch<React.SetStateAction<IAsset | IMm3Asset>>;
}> = ({ asset, setAsset }) => {
  const refreshDataSection = useRefreshDataSection();
  const {
    toastStore,
    dialogStore: { close },
  } = useStore();

  const initialValues: IAssetStatus = {
    status: asset?.status,
    item_ids: [asset?.id || 0],
  };

  const submitForm = useCallback(
    async (data: IAssetStatus) => {
      try {
        if (!data.item_ids?.[0]) {
          return;
        }
        const { objects } = await changeAssetAccessImmediate(data);
        setAsset?.((asset) => ({ ...asset, status: objects[0].status }));
        toastStore.success('Status changed');
        close();
        refreshDataSection();
      } catch (error) {
        toastStore.error(error);
      }
    },
    [close, refreshDataSection, setAsset, toastStore],
  );

  const { formData, handlers, valid, onSubmit } = useMm3Form<IAssetStatus>(initialValues, Model.MM3_ASSETS, submitForm);

  return (
    <form onSubmit={onSubmit}>
      <FormAssetStatus name="status" label="Status" large required {...formData.status} {...handlers} />
      <FormSubmitSection submitDisabled={!valid} />
    </form>
  );
};

export const useChangeAssetStatusDialog = (
  asset: IAsset | IMm3Asset,
  setAsset?: React.Dispatch<React.SetStateAction<IAsset | IMm3Asset>>,
): (() => void) => {
  const {
    dialogStore: { openModal },
  } = useStore();

  const openDialog = useCallback((): void => {
    openModal({
      title: "Change asset's status",
      body: () => <ChangeStatusForm asset={asset} setAsset={setAsset} />,
    });
  }, [openModal, asset, setAsset]);

  return openDialog;
};
