import React, { useCallback } from 'react';
import { FormMultiSelect } from 'helpers/form';

import { IGuildFormComponent } from './types';
import { IQueryParams, IItem } from 'types';
import { queryContacts } from 'utils/apis/contacts';
import { queryContactSelections } from 'utils/apis/recipients';
import { queryOrganizations } from 'utils/apis/organization';

export const keysContacts = ['contacts', 'organization_ids'];

const fetchValues = async ({ q, ids }: IQueryParams): Promise<IItem[]> => {
  const contacts = await queryContacts({ q, ids });
  const selections = await queryContactSelections({ q, ids });
  return [...contacts, ...selections];
};

export const GuildFormContacts: React.FC<IGuildFormComponent> = ({ form }) => {
  const onContactChange = useCallback(
    (contacts) => {
      form.handlers.onChange({ contacts, contact_ids: contacts?.map((e) => e?.value) });
    },
    [form.handlers],
  );

  return (
    <div className="d-flex flex-column gap-1 h-100">
      <div className="access-group-wizard__recipients-container">
        <FormMultiSelect
          name="contact_ids"
          label="Contacts"
          omni
          fetchValues={fetchValues}
          {...form.formData.contact_ids}
          onSelectedItemsChange={onContactChange}
          onBlur={form.handlers.onBlur}
          fitInParent
        />
        <FormMultiSelect
          omni
          name="organization_ids"
          label="Organizations"
          fetchValues={queryOrganizations}
          {...form.formData.organization_ids}
          {...form.handlers}
          fitInParent
        />
      </div>
    </div>
  );
};
