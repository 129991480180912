import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  prepareInitialValues,
  preparePropOptions,
  loadMultiselectRemoteOptions,
} from 'helpers/form/fields/form-select/utils';
import { IFormRemoteSelectOption, IOptionId, IRemoteSelectorFetcher } from 'helpers/form/fields/form-select/types';
import { getRootStore } from 'store';
import { ToastError } from 'components/toast';
import { IFilterOption } from 'utils/hooks';
import { IItem } from 'types';
import { parseItem } from 'helpers/form/fields/select-helpers';

export interface IOption {
  value: number | string;
  label: string;
}

interface IObject {
  id?: number | string | null;
  name: string;
}

export function parseObjectsToOptions(objects: IObject[], entireObject = false): IOption[] {
  if (entireObject) return objects.map((obj) => ({ ...obj, label: obj.name, value: obj?.id || '' }));

  return objects.map(({ name, id }) => ({ label: name, value: id ?? '' }));
}

export function useFetchFieldOptions(
  getOptions: () => Promise<IObject[]>,
  parseOptions: (objects: IObject[], entireObject?: boolean) => IOption[] = parseObjectsToOptions,
  entireObject?: boolean,
): IOption[] {
  const parseResult = useCallback(parseOptions, [parseOptions]);
  const [options, setOptions] = useState<IOption[]>([]);

  useEffect(() => {
    getOptions().then((objects) => {
      setOptions(parseResult(objects, entireObject));
    });
  }, [getOptions, parseResult, entireObject]);

  return options;
}

export const usePreventBackspaceClick = (inputRef): void => {
  useEffect(() => {
    const el = inputRef.current;
    if (!el) return;

    const handleKeyDown = (e): void => {
      if (e.key === 'Backspace') {
        e.stopPropagation();
      }
    };

    el.addEventListener('keydown', handleKeyDown);
    return () => {
      el.removeEventListener('keydown', handleKeyDown);
    };
  }, [inputRef]);
};

export const useMultiselectStaticSetup = (
  options,
  isRemoteMode,
  setItems,
  setSelectedItems,
  value,
  isRemoteInitialized,
): void => {
  const isStaticInitializedRef = useRef(false);

  // init static options and selected items
  useEffect((): void => {
    const isArray = Array.isArray(options);

    if (!isArray || !options?.length || isRemoteMode || isStaticInitializedRef.current) {
      return;
    }

    isStaticInitializedRef.current = true;

    const items = preparePropOptions(options as IOptionId[]);
    setItems(items);

    if (value && items?.length) {
      setSelectedItems(prepareInitialValues(value, items));
    }
  }, [isRemoteMode, options, value, isRemoteInitialized, setSelectedItems, setItems]);

  // reset selected on value update
  useEffect(() => {
    if (!isStaticInitializedRef.current || !isRemoteMode) {
      return;
    }

    setItems((items) => {
      if (value && items?.length) {
        setSelectedItems(prepareInitialValues(value, items));
      } else {
        setSelectedItems([]);
      }

      return items;
    });
  }, [value, setSelectedItems, setItems, isRemoteMode]);
};

export const useMultiselectRemoteSetup = (
  isRemoteMode,
  value,
  setIsRemoteInitialized,
  fetchValues,
  setSelectedItems,
  canLoadRemoteOptions,
  setIsLoading,
  setItems,
  debouncedInputValue,
  filters,
  forceReInitiating,
): void => {
  const isRemoteInitializedRef = useRef(false);
  const latestRequestRef = useRef(1);

  // init selected remote items
  useEffect((): void => {
    if ((!isRemoteMode || isRemoteInitializedRef.current) && !forceReInitiating) {
      return;
    }

    if (!value?.length) {
      setIsRemoteInitialized(true);
      isRemoteInitializedRef.current = true;
      setSelectedItems([]);
      return;
    }

    async function loadSelected(): Promise<void> {
      try {
        const formattedValue = Number.isNaN(Number(value?.[0])) ? value : value.map((id) => Number(id), []);
        const items = await loadMultiselectRemoteOptions({ fetchValues, options: { ids: formattedValue } });

        setSelectedItems(prepareInitialValues(value, items));
      } catch (error) {
        const { toastStore } = getRootStore();
        toastStore.error(<ToastError error={error} placeholder="Sorry, something went wrong!" />);
      } finally {
        isRemoteInitializedRef.current = true;
        setIsRemoteInitialized(true);
      }
    }

    loadSelected();
  }, [isRemoteMode, fetchValues, value, setIsRemoteInitialized, setSelectedItems, forceReInitiating]);

  // reset selected on empty value change
  useEffect((): void => {
    if (!isRemoteMode || !isRemoteInitializedRef.current || !Array.isArray(value)) {
      return;
    }

    if (!value?.length) {
      setSelectedItems([]);
      return;
    }

    // Note: If you want to completely sync component with new values after it was already initialized,
    // change the key prop in the parent after new value array is ready.

    // Changing key = remount React component -> example in StoryBook
  }, [isRemoteMode, value, setIsRemoteInitialized, setSelectedItems]);

  // init remote options
  useEffect((): void => {
    if (!canLoadRemoteOptions || !isRemoteMode) {
      return;
    }

    async function loadOptions(): Promise<void> {
      setIsLoading(true);
      setItems([]);

      try {
        latestRequestRef.current += 1;
        const requestId = latestRequestRef.current;

        const items = await loadMultiselectRemoteOptions({ fetchValues, options: { q: '' } });

        if (requestId !== latestRequestRef.current) {
          return;
        }

        setItems(items);
        setIsLoading(false);
      } catch (error) {
        const { toastStore } = getRootStore();
        toastStore.error(<ToastError error={error} placeholder="Sorry, something went wrong!" />);
        setIsLoading(false);
      }
    }

    loadOptions();
  }, [isRemoteMode, fetchValues, canLoadRemoteOptions, setIsLoading, setItems]);

  // update remote options on query
  useEffect((): void => {
    if (!isRemoteMode || !isRemoteInitializedRef.current || !canLoadRemoteOptions) {
      return;
    }

    async function updateOptions(): Promise<void> {
      setIsLoading(true);
      setItems([]);

      try {
        latestRequestRef.current += 1;
        const requestId = latestRequestRef.current;
        const items = await loadMultiselectRemoteOptions({
          fetchValues,
          options: { q: debouncedInputValue[0] },
          additionalFilters: filters?.current,
        });
        if (requestId !== latestRequestRef.current) {
          return;
        }
        setItems(items);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        const { toastStore } = getRootStore();
        toastStore.error(<ToastError error={error} placeholder="Sorry, something went wrong!" />);
      }
    }

    updateOptions();
  }, [isRemoteMode, fetchValues, debouncedInputValue, setItems, setIsLoading, filters, canLoadRemoteOptions]);
};

export interface IUseRemoteSelectSetupParams {
  value?: string | number | null;
  filters?: IFilterOption[];
  shouldInitiateOptions: boolean;
  onQueryChange: (query: string) => void;
  setIsInitiating: React.Dispatch<React.SetStateAction<boolean>>;
  fetchOptions: IRemoteSelectorFetcher;
  activeItem: IFormRemoteSelectOption | null;
  setActiveItem: React.Dispatch<React.SetStateAction<IFormRemoteSelectOption | null>>;
  enableClearing: boolean;
}

export const useRemoteSelectSetup = ({
  value,
  filters,
  shouldInitiateOptions,
  onQueryChange,
  setIsInitiating,
  fetchOptions,
  activeItem,
  setActiveItem,
  enableClearing,
}: IUseRemoteSelectSetupParams): void => {
  useEffect(() => {
    if (!shouldInitiateOptions) return;
    onQueryChange('');
  }, [onQueryChange, shouldInitiateOptions]);

  useEffect(() => {
    if (!value && enableClearing) {
      setActiveItem(null);
    }
  }, [value, setActiveItem, enableClearing]);

  useEffect(() => {
    if (!value || activeItem?.value == value) {
      return;
    }
    setIsInitiating(true);
    fetchOptions('', value, filters)
      .then((data) => {
        let selected;
        typeof data?.[0] === 'string'
          ? (selected = (data as string[]).find((item) => item == value))
          : (selected = (data as IItem[]).find((item) => item?.id && item?.id == value));
        setActiveItem(selected ? (parseItem(selected) as IFormRemoteSelectOption) : null);
      })
      .finally(() => {
        setIsInitiating(false);
      });
  }, [fetchOptions, value, activeItem, setIsInitiating, setActiveItem, filters]);
};
