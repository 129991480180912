import React, { useCallback } from 'react';

import { IAsset, IMm3Asset } from 'types';

import { useDialogStore } from 'store/hooks';
import { IModalSize } from 'components/dialogs/types';

import { IAssetActionName, IAssetActionConfig } from './types';
import { shouldDisplayGenerateSubtitles } from './actions-acl';
import { GenerateSubtitleForm } from 'components/generate-subtitle-form';

export const useGenerateSubtitlesAction = (asset: IMm3Asset | IAsset): IAssetActionConfig => {
  const { openModal } = useDialogStore();
  const handler = useCallback(
    () =>
      openModal({
        title: 'Generate Subtitles',
        size: IModalSize.M,
        body: () => <GenerateSubtitleForm asset={asset} />,
      }),
    [asset, openModal],
  );

  return {
    name: IAssetActionName.GENERATE_SUBTITLES,
    icon: 'generate',
    title: 'Generate subtitles',
    shouldDisplay: shouldDisplayGenerateSubtitles,
    handler,
  };
};
