import React, { useCallback, useEffect } from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import ScrollWrapper from 'components/scroll-wrapper';

import { FormCheckbox, FormImage, FormInput, FormNumericInput, FormSelect, useFetchFieldOptions } from 'helpers/form';
import { IUseFormReturn } from 'helpers/form/use-form';
import { IAffiliationForm } from 'pages/affiliation/types';
import { fetchDomains } from 'utils/apis/affiliation';
import { STORAGE_KEYS, getStorageItem } from 'utils/storage';
import { Model } from 'helpers/filters/types';
import { Globals } from 'types';

const AffiliationEditGeneralTab: React.FC<{
  affiliationForm: IUseFormReturn<Partial<IAffiliationForm>>;
  disableFields: boolean;
}> = ({ affiliationForm, disableFields }) => {
  const { formData, handlers, values } = affiliationForm || {};

  const buyerDomainFetcher = useCallback(async () => {
    const domains = await fetchDomains(Model.BUYER_DOMAINS);
    return domains;
  }, []);

  const sellerDomainFetcher = useCallback(async () => {
    const domains = await fetchDomains(Model.SELLER_DOMAINS);
    return domains;
  }, []);

  const buyerDomains = useFetchFieldOptions(buyerDomainFetcher);
  const sellerDomains = useFetchFieldOptions(sellerDomainFetcher);

  useEffect(() => {
    window.addEventListener(Globals.AFFILIATION_LOGO_UPDATE, () => {
      const newLogourl = getStorageItem<string | null>(STORAGE_KEYS.WEBSITE_LOGO);
      newLogourl && handlers.onChange({ logoUrl: newLogourl });
    });
  }, [handlers]);

  return (
    <ScrollWrapper>
      <InfoBoxV2 wrapperClassName="py-3" gridTemplateColumns="1fr 1fr">
        <FormInput className="id" name="id" key="id" label="Id" value={values?.id} disabled />
        <FormInput
          className="brand_header"
          name="brand_header"
          key="brand_header"
          label="Brand header"
          {...formData?.brand_header}
          {...handlers}
        />
        <FormCheckbox
          className="public_access pt-4"
          name="public_access"
          key="public_access"
          label="Public Access"
          {...formData?.public_access}
          {...handlers}
          disabled={disableFields}
        />
        <FormNumericInput
          className="password_expiration_interval"
          name="password_expiration_interval"
          key="password_expiration_interval"
          label="Password Expiration (Days)"
          {...formData?.password_expiration_interval}
          {...handlers}
          disabled={disableFields}
        />
        <FormSelect
          className="primarySellerDomain"
          name="primarySellerDomain"
          key="primarySellerDomain"
          label="Primary Seller Domain"
          {...formData?.primarySellerDomain}
          {...handlers}
          options={sellerDomains}
          large
          disabled={disableFields}
        />
        <FormSelect
          className="primaryBuyerDomain"
          name="primaryBuyerDomain"
          key="primaryBuyerDomain"
          label="Primary Buyer Domain"
          {...formData?.primaryBuyerDomain}
          {...handlers}
          options={buyerDomains}
          large
          disabled={disableFields}
        />
        <FormImage type="preview" name="logoUrl" label="Logo" large {...formData?.logoUrl} {...handlers} />
      </InfoBoxV2>
    </ScrollWrapper>
  );
};

export default AffiliationEditGeneralTab;
