import { UmGenericListItem } from '@mediafellows/mm3-types';

import { IEntity } from 'types';

export enum IWebsitePageValues {
  ABOUT = 'about',
  BRAND_LICENSING = 'brand-licensing',
  CINEMA = 'cinema-page',
  CINEMA_COLLECTIONS = 'cinema-collections',
  CINEMA_MARKETS_AND_EVENTS = 'cinema-markets-and-events',
  COLLECTIONS = 'collections',
  COLLECTION_PAGES = 'collection-pages',
  CONTACT = 'contact',
  CONTACTS = 'contacts',
  CLIP_SALES = 'clip-sales',
  DOWNLOADS = 'downloads',
  MICROSITES = 'microsites',
  EVENTS = 'events',
  GENRES = 'genres',
  HOME = 'home',
  LOCATIONS = 'locations',
  MARKET_EVENTS = 'tv-markets-events-page',
  NEWS = 'news',
  PRODUCTION = 'production',
  REGISTRATION = 'registration',
  DIGITAL_CHANNELS = 'digital-channels',
  TV_PAGE = 'tv-page',
  ADVERTISEMENTS = 'advertisements',
}

export enum IAnalyticsPageValues {
  ANALYTICS_CONTACTS = 'analytics_contacts',
  ANALYTICS_ASSETS = 'analytics_assets',
  ANALYTICS_PRODUCTS = 'analytics_products',
  ANALYTICS_RECOMMENDATION = 'analytics_recommendations',
}

export type IListItem = UmGenericListItem & {
  isRemoved?: boolean;
  edited?: boolean;
  entity: IEntity;
};

export interface ISeparator {
  type: 'separator';
  name: string;
  id: string;
}

export type IAllowedListItemType =
  | 'product'
  | 'asset'
  | 'user'
  | 'organization'
  | 'group/event'
  | 'category'
  | 'list/collection'
  | 'separator'
  | 'news';
