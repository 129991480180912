import React, { useCallback } from 'react';
import { Checkbox, MantineSize } from '@mantine/core';

import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { FormGroup } from 'helpers/form/fields/form-group';

import { IStyled } from 'types';

import cx from 'classnames';
import './style.scss';

export interface IFormCheckboxProps extends IFieldData<boolean | null>, IFieldHandlers<boolean>, IStyled {
  name: string;
  label?: string;
  labelElement?: JSX.Element;
  disabled?: boolean;
  formId?: string;
  groupClassName?: string;
  helperText?: string;
  indeterminate?: boolean;
  converter?: (boolean) => boolean;
  mainLabel?: string;
  description?: string;
  inline?: boolean;
  size?: MantineSize;
  withExtraTopPadding?: boolean;
  hasBottomMargin?: boolean;
  hideLabel?: boolean;
}

export const FormCheckbox: React.FC<IFormCheckboxProps> = ({
  formId = 'id',
  name,
  value,
  label,
  labelElement,
  disabled,
  touched,
  validation,
  className,
  helperText,
  style,
  inline,
  hasBottomMargin,
  withExtraTopPadding,
  groupClassName,
  indeterminate,
  onChange,
  onBlur,
  converter = (value) => value,
  mainLabel,
  size = 'sm',
  description,
  hideLabel = false,
}) => {
  const showError = touched && !validation?.valid;

  const handleChange: React.FormEventHandler<HTMLInputElement> = (ev): void => {
    onChange?.({ [name]: converter(ev.currentTarget.checked) });
  };

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  return (
    <FormGroup
      labelFor={name}
      helperText={showError ? validation?.errorMessage : helperText}
      style={style}
      inline={inline}
      hasBottomMargin={hasBottomMargin}
      // This hack is needed to align different form elements, because
      // for inputs we have label on the top of a field
      // and for checkboxes it's next to the input
      label={mainLabel || (withExtraTopPadding ? <span dangerouslySetInnerHTML={{ __html: '&#160;' }}></span> : null)}
      className={cx('form-control-wrap', groupClassName)}
    >
      <Checkbox
        id={`${formId}-${name}`}
        disabled={disabled}
        onChange={handleChange}
        checked={converter(value)}
        size={size}
        indeterminate={indeterminate}
        label={!hideLabel && (mainLabel || label || labelElement)}
        description={description}
        name={name}
        onBlur={handleBlur}
        className={className}
        error={showError}
      />
    </FormGroup>
  );
};

export default FormCheckbox;
