import React from 'react';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';
import startCase from 'lodash/startCase';

import { FormInput, FormMultiSelect, FormSelect, FormAccessLevel } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { ShowcaseCommonForm, useShowcaseFormHandlers } from 'components/showcase-form/showcase-common-parts';

import { IWebsitePageValues } from 'types';

export const filterPages = (page: IWebsitePageValues): boolean => page !== IWebsitePageValues.COLLECTIONS;

const ShowcaseFormFirstStep: React.FC<
  IUseMm3FormReturn<UmGenericListShowcase> & {
    hideNextButton?: boolean;
    section?: IWebsitePageValues;
  }
> = ({ handlers, formData, onSubmit, valid, values, section, hideNextButton = false }) => {
  const pageOptions = formData.meta?.page?.options?.filter(filterPages);

  const { allowedItemTypes, onAllowedItemTypesChange } = useShowcaseFormHandlers<UmGenericListShowcase>(
    false,
    values,
    formData,
    handlers,
  );

  return (
    <ShowcaseCommonForm
      handlers={handlers}
      formData={formData}
      onSubmit={onSubmit}
      valid={valid}
      values={values}
      hideNextButton={hideNextButton}
      section={section}
    >
      <FormSelect
        large
        name="meta.page"
        label="Website page"
        {...formData.meta?.page}
        options={pageOptions}
        formatLabel={startCase}
        {...handlers}
      />
      <FormMultiSelect
        name="allowed_item_types"
        label="Allowed objects"
        {...handlers}
        onChange={onAllowedItemTypesChange}
        {...formData?.allowed_item_types}
        options={undefined}
        fetchValues={allowedItemTypes}
      />
      <FormAccessLevel large name="access_level" label="Access level" {...handlers} {...formData?.access_level} />
      <FormInput textarea name="meta.description" label="Description" {...handlers} {...formData?.meta?.description} />
    </ShowcaseCommonForm>
  );
};

export default ShowcaseFormFirstStep;
