import { Model } from 'helpers/filters/types';
import React from 'react';
import { IProductAsset, IPreviewImage, IAsset, IMm3Asset, IQueryParams } from 'types';
import { flags } from 'utils/flags';
import { AssetType } from 'utils/format-asset-type';
import { chipmunk, IResult } from './chipmunk';

import { map, flatten, filter, startsWith } from 'lodash';

export enum AssetFilters {
  ALL = 'All',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  DOCUMENT = 'document',
}

export function filterPreviewAssets(assets: IProductAsset[], assetFilter: AssetFilters): IProductAsset[] {
  return (assets || []).filter(
    ({ marketing_use, asset = {} }) =>
      marketing_use &&
      readyStatuses.has(asset?.file_status || '') &&
      (assetFilter === AssetFilters.ALL || asset.classification?.includes(assetFilter)),
  );
}

export function getPreviewImageUrl(preview_image?: IPreviewImage | null): string {
  if (!preview_image?.url && !preview_image?.source_url) {
    return '';
  }
  const { status, url = '', source_url = '' } = preview_image;

  if (status !== undefined) {
    return readyStatuses.has(status || '') ? url : source_url;
  }
  return url || source_url;
}

export const getAvailableTabs = (assets?: IProductAsset[]): { filter: string; title: string }[] => {
  const tabs = [
    { filter: 'ALL', title: 'All' },
    { filter: 'IMAGE', title: 'Images' },
    { filter: 'VIDEO', title: 'Videos' },
    { filter: 'AUDIO', title: 'Audio' },
    { filter: 'DOCUMENT', title: 'Documents' },
  ];
  return tabs.filter(
    ({ filter }) => filter === 'ALL' || filterPreviewAssets(assets || [], AssetFilters[filter]).length,
  );
};

export const readyStatuses = new Set(['ready', 'available']);
export function getIngestStatus(
  asset?: Pick<IAsset | IMm3Asset, 'status' | 'file_status' | 'preview_image' | 'file_size'>,
): string {
  const { file_status = '', preview_image, file_size } = asset || {};
  const { status: previewImageStatus = '' } = preview_image || {};
  if (readyStatuses.has(file_status) && readyStatuses.has(previewImageStatus)) {
    return 'ready';
  }
  if (!readyStatuses.has(file_status) && file_size === null && !previewImageStatus) {
    return 'shellAsset';
  }
  if (!readyStatuses.has(file_status) || !previewImageStatus) {
    return file_status || '';
  }

  return `preview_${previewImageStatus}`;
}

export const ingestStatuses = new Set(['pending', 'ingesting', 'reingesting', 'created']);
export function isAssetIngesting({
  file_status,
  preview_image,
  preview_image_id,
}: Pick<IAsset | IMm3Asset, 'file_status' | 'preview_image' | 'preview_image_id'>): boolean {
  return Boolean(
    preview_image_id && (ingestStatuses.has(file_status || '') || ingestStatuses.has(preview_image?.status || '')),
  );
}

export const refreshIngestingAssetsDelay = 30 * 1000; // 30 seconds

export function withAssetVersion<T, P>(
  OldAssetComponent: React.FC<T>,
  Mm3AssetComponent: React.FC<P>,
): React.FC<{ [S in keyof T]: S extends keyof P ? T[S] | P[S] : T[S] }> {
  const { isMm3Assets } = flags;
  if (isMm3Assets) {
    return ((props: P) => <Mm3AssetComponent isMm3Assets={isMm3Assets} {...props} />) as React.FC<{
      [S in keyof T]: S extends keyof P ? T[S] | P[S] : T[S];
    }>;
  }
  return ((props: T) => <OldAssetComponent isMm3Assets={isMm3Assets} {...props} />) as React.FC<{
    [S in keyof T]: S extends keyof P ? T[S] | P[S] : T[S];
  }>;
}

export function selectAssetHelper<T, P>(oldAssetsHelper: T, mm3AssetsHelper: P): T | P {
  return flags.isMm3Assets ? mm3AssetsHelper : oldAssetsHelper;
}

export function getAssetModel(): Model.ASSETS | Model.MM3_ASSETS {
  return flags.isMm3Assets ? Model.MM3_ASSETS : Model.ASSETS;
}
export const getMainClassification = (asset: Partial<IAsset> | Partial<IMm3Asset>): string => {
  return (asset as IAsset)?.main_classification || asset?.classification?.split('/')[0] || '';
};

export const getAssetTypeFromModel = (asset: IAsset | IMm3Asset): string => {
  return flags.isMm3Assets ? (asset as IMm3Asset).type : ((asset as IAsset).main_classification as string);
};

export const getMainType = (asset: IAsset | IMm3Asset): string => {
  const type = getAssetTypeFromModel(asset);

  switch (type) {
    case AssetType.IMAGE:
      return AssetFilters.IMAGE;
    case AssetType.DOCUMENT:
      return AssetFilters.DOCUMENT;
    case AssetType.AUDIO:
      return AssetFilters.AUDIO;
    case AssetType.VIDEO:
      return AssetFilters.VIDEO;

    default:
      return type;
  }
};
export const isAsset = (type: string): boolean => {
  const assetTypes = ['asset', AssetFilters.IMAGE, AssetFilters.DOCUMENT, AssetFilters.AUDIO, AssetFilters.VIDEO];
  return assetTypes.includes(type.toLowerCase());
};

const autosuggestValues = async (model: string, property: string, query: string): Promise<string[]> => {
  return chipmunk.run(async (ch) => {
    const result = await ch.action(model, 'search', {
      body: {
        per: 1,
        stats: [property],
        search: {
          filters: [[`${property}.fts`, 'q', `${query}*`]],
        },
      },
    });
    const values = map(flatten(map(result.aggregations)), 'value');

    return filter(values, (value) => typeof value === 'string' && startsWith(value.toLowerCase(), query.toLowerCase()));
  });
};

export const getAssetSuggestions = async (
  { q }: IQueryParams,
  property: string,
): Promise<IResult<{ value: string }>> => {
  const a = await autosuggestValues(getAssetModel(), property, q || '');
  const objects = a.map((e) => ({ value: e }));
  return { objects, object: objects[0] };
};

export const createAssetFieldSuggestion = (name: string) => {
  return (params: IQueryParams) => getAssetSuggestions(params, name);
};
