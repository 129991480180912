import { useNavigate, useParams } from 'react-router-dom';

import React, { useCallback } from 'react';
import { downloadBatchAssets } from 'utils/actions/asset';
import { useRemote } from 'utils/hooks';
import { Routes } from 'utils/routes';
import { useStore } from 'store';
import { ToastError } from 'components/toast';
import { Loading } from 'components/loading';

export const AssetsBatchDownloads: React.FC = () => {
  const { batchId } = useParams<{ batchId: string }>();
  const navigate = useNavigate();
  const { toastStore } = useStore();

  const downloadAssets = useCallback(async () => {
    try {
      const result = await downloadBatchAssets(batchId);
      if (result?.download_url) {
        window.location.href = result.download_url;
      } else {
        toastStore.error(<ToastError placeholder="Sorry, Assets batch download failed..." />);
      }
    } catch (error) {
      toastStore.error(<ToastError error={error} placeholder="Sorry, Something went wrong..." />);
    } finally {
      navigate(Routes.DASHBOARD);
    }
  }, [batchId, toastStore, navigate]);

  useRemote(downloadAssets);

  return <Loading text="Downloading..." />;
};
