import React from 'react';
import { observer } from 'mobx-react-lite';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import { useBasicStore } from 'store/hooks';
import { formatDuration } from 'utils/date';
import { formatAssetType, getEntityType, joinLanguages } from 'utils/general';
import { IAsset, IMm3Asset, IMm3AssetType } from 'types';
import { AssetFilters, withAssetVersion } from 'utils/asset';
import { CustomAssetMetadataRow, CustomAboutRow } from 'pages/asset-details/asset-metadata-tab/custom-row';

export const AssetOldMetadataAboutSection: React.FC<{ asset?: IAsset }> = observer(({ asset }) => {
  const { languages } = useBasicStore();
  const { burned_in_subtitles_language_id, classification, default_layer, duration, name } = asset || {};

  const { notes } = default_layer || {};
  const assetItemType = getEntityType(classification);
  const isVideo: boolean = assetItemType === AssetFilters.VIDEO;
  const isAudio: boolean = assetItemType === AssetFilters.AUDIO;

  return (
    <InfoBoxV2 gridTemplateColumns="160px 1fr" title="About">
      <Row label="Name" value={name} size="m" />
      <Row label="Asset Type" value={formatAssetType(classification)} size="m" />
      <CustomAssetMetadataRow asset={asset as IAsset} />
      <Row label="Duration" value={formatDuration(duration, 'seconds')} size="m" hidden={!(isVideo || isAudio)} />
      <Row label="Notes" value={notes} size="m" html />
      <Row
        rawLabel
        label="Burned-in Subtitles"
        value={joinLanguages(languages, [burned_in_subtitles_language_id as string])}
        size="m"
        hidden={!isVideo}
      />
    </InfoBoxV2>
  );
});

export const AssetMm3MetadataAboutSection: React.FC<{ asset?: IMm3Asset }> = observer(({ asset }) => {
  const { classification, name, type } = asset || {};
  const { notes } = asset?.meta || {};
  const { duration } = asset?.source_meta || {};

  const isVideo: boolean = type === IMm3AssetType.VIDEO;
  const isAudio: boolean = type === IMm3AssetType.AUDIO;
  const isSubtitle: boolean = type === IMm3AssetType.SUBTITLE;

  return (
    <InfoBoxV2 gridTemplateColumns="160px 1fr" title="About">
      <Row label="Name" value={name} size="m" />
      <Row label="Asset Type" value={formatAssetType(classification)} size="m" />
      <CustomAssetMetadataRow asset={asset as IMm3Asset} />
      <CustomAboutRow asset={asset as IMm3Asset} />
      <Row label="Duration" value={formatDuration(duration, 'seconds')} size="m" hidden={!(isVideo || isAudio)} />
      <Row label="Label for video player" value={asset?.meta?.label} size="m" hidden={!(isSubtitle || isAudio)} />
      <Row label="Notes" value={notes} size="m" html />
      {/* <Row
        rawLabel
        label="Burned-in Subtitles"
        value={joinLanguages(languages, [burned_in_subtitles_language_id as string])}
        size="m"
        hidden={!isVideo}
      /> */}
    </InfoBoxV2>
  );
});

export const AssetMetadataAboutSection = withAssetVersion(
  AssetOldMetadataAboutSection,
  AssetMm3MetadataAboutSection,
) as React.FC<{ asset: IMm3Asset | IAsset }>;
