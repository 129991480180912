import React from 'react';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';

import { IWebsitePageValues } from 'types';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { ShowcaseForm, CollectionForm, AdvertisementForm } from 'components/showcase-form';

interface IShowcaseDetailForm {
  form: IUseMm3FormReturn<UmGenericListShowcase>;
  section?: IWebsitePageValues;
}

const ShowcaseDetailForm: React.FC<IShowcaseDetailForm> = ({ form, section }) => {
  const isCollection = section === IWebsitePageValues.COLLECTIONS;
  const isAdvertisement = section === IWebsitePageValues.ADVERTISEMENTS;

  const renderForm = (): JSX.Element | null => {
    if (isCollection) {
      return <CollectionForm hideNextButton {...form} section={section} />;
    }

    if (isAdvertisement) {
      return <AdvertisementForm hideNextButton {...form} section={section} />;
    }

    return <ShowcaseForm {...form} hideNextButton section={section} />;
  };

  return <div className="form-container">{renderForm()}</div>;
};

export default ShowcaseDetailForm;
