import React, { useCallback, useMemo } from 'react';
import { startCase } from 'lodash';

import { Text } from 'components/text';
import { FormCheckbox, FormMultiSelect, FormProducts, FormRemoteSelect, IUseFormReturn } from 'helpers/form';

import { useFutureDescendantsFlag } from 'components/access-privilege';
import { GroupTypes, IAsset, IGroup, IGroupVariety, IItem, IQueryParams, ISearchFilter } from 'types';
import { getEntityLabel } from 'utils/general';
import { formatGroupName } from 'utils/group';
import { buildFilters } from './utils';
import { IAddToGroupForm, IAddToGroupEntityType } from './types';

import { queryContacts } from 'utils/apis/contacts';
import { queryEvents } from 'utils/apis/events';
import { fetchGroups } from 'utils/apis/groups';
import { queryOrganizations } from 'utils/apis/organization';
import { queryProducts, fetchProductsWithAncestry } from 'utils/apis/product';
import { queryQueue, queryRecipientContacts, queryContactSelections } from 'utils/apis/recipients';
import { queryMainAssets } from 'utils/apis/asset';
import { queryAssetSelections } from 'utils/apis/selection';
import { getRecommendationAssetsFilters } from 'utils/recommendation';

import { IMarketingEvent } from 'types';

interface IFormAddToGroupItemsForm {
  form: IUseFormReturn<IAddToGroupForm>;
  entity: IAddToGroupEntityType;
  disabledItemsSelector?: boolean;
  disabledGroupSelector?: boolean;
  hideGroupSelector?: boolean;
  hideProductsParams?: boolean;
  addSelectedProductAncestors?: boolean;

  items?: Array<{ label: string; value: string }>;
  group?: IGroupVariety;
  groupType?: string;
  groupLabel?: string;

  omni?: boolean;
}

const addParentProductsMessage = (
  <Text>
    All products will be added with their full ancestry (necessary for the correct display of privileged access on the
    client-facing site).
  </Text>
);

const ProductCheckboxes: React.FC<
  Pick<IFormAddToGroupItemsForm, 'form' | 'hideProductsParams' | 'groupType' | 'entity'>
> = ({ form, hideProductsParams, groupType, entity }) => {
  const isProducts = entity === 'products';
  if (!isProducts || hideProductsParams) return null;

  const { formData, handlers } = form;

  const isAccessGroup = new Set([GroupTypes.ACCESS_PRIVILEGE, GroupTypes.ACCESS_GROUPS, GroupTypes.GUILD]).has(
    groupType as GroupTypes,
  );
  const productFields = [
    {
      condition: true,
      element: (
        <FormCheckbox
          name="include_descendants"
          label="Include descendant product(s)"
          {...formData.include_descendants}
          {...handlers}
        />
      ),
    },
    {
      condition: new Set([GroupTypes.ACCESS_PRIVILEGE, GroupTypes.ACCESS_GROUPS, GroupTypes.SMART_GROUPS]).has(
        groupType as GroupTypes,
      ),
      element: (
        <FormCheckbox
          label="Automatically include all future descendant products"
          name="include_future_descendants"
          className="ms-3 mb-4"
          {...formData.include_future_descendants}
          {...handlers}
          disabled={!formData.include_descendants?.value}
        />
      ),
    },
    {
      condition: isAccessGroup,
      element: (
        <FormCheckbox
          name="delegates_access"
          label="Include the Products’ unpublished Assets"
          {...formData.delegates_access}
          {...handlers}
        />
      ),
    },
    {
      condition: isAccessGroup,
      element: (
        <FormCheckbox
          label="Permit asset download"
          name="permit_download"
          {...formData.permit_download}
          {...handlers}
        />
      ),
    },
  ];

  return (
    <>
      {productFields
        .filter((field) => field.condition)
        .map((field, index) => (
          <React.Fragment key={index}>{field.element}</React.Fragment>
        ))}
    </>
  );
};
export const FormAddToGroupItemsForm: React.FC<IFormAddToGroupItemsForm> = ({
  items,
  entity,
  group,
  groupType,
  disabledItemsSelector = false,
  disabledGroupSelector = false,
  hideGroupSelector = false,
  form,
  omni = false,
  hideProductsParams = false,
  groupLabel: groupLabelOverride,
  addSelectedProductAncestors = false,
}) => {
  const { formData, handlers } = form;

  const groupLabel = groupLabelOverride ?? formatGroupName(groupType);
  const itemLabel = startCase(getEntityLabel(entity));

  const loadGroups = useCallback(
    async (query: string): Promise<IGroup[]> => {
      if (!groupType) {
        return [];
      }
      const filters = buildFilters({ groupId: group?.id, query, groupType, entity });
      return fetchGroups(filters);
    },
    [entity, groupType, group?.id],
  );

  const fetchProductsOverride = useCallback(
    (params, filters) => {
      const defaultFilter = filters || [['parent_id', 'not_exist']];

      if (group?.id && groupType !== GroupTypes.ACCESS_PRIVILEGE) {
        defaultFilter.push(['group_ids', 'ne', group.id]);
      }

      return fetchProductsWithAncestry(params, defaultFilter);
    },
    [group?.id, groupType],
  );

  const fetchValues = useMemo(
    (): {
      [k in IAddToGroupEntityType];
    } => ({
      assets: queryMainAssets,
      assets_and_selections: queryQueue<IAsset | IGroup>([queryMainAssets, queryAssetSelections]),
      products: queryProducts,
      users: queryContacts,
      events: queryEvents,
      organizations: queryOrganizations,
      contacts_and_selections: queryQueue([queryRecipientContacts, queryContactSelections]),
      contact_selections: queryContactSelections,
    }),
    [],
  );
  const fetchItems = useCallback(
    async (params: IQueryParams): Promise<IItem[]> => {
      const filters: ISearchFilter[] = entity === 'assets' ? [['parent_id', 'not_exist']] : [];

      if (group?.id && !(groupType === GroupTypes.EVENT && entity === 'events')) {
        filters.push(['group_ids', 'ne', group?.id]);
      }

      if (groupType === GroupTypes.EVENT) {
        const event = group as IMarketingEvent;
        event?.subevent_group_ids &&
          entity === 'events' &&
          filters.push(['id', 'not_in', [...event?.subevent_group_ids]]);
        entity === 'assets' && filters.push(['status', 'eq', 'available']);
      }
      if (groupType === GroupTypes.MOBILE_APP_SYNC && entity === 'assets') {
        filters.push(...getRecommendationAssetsFilters());
      }
      return fetchValues[entity](params, filters);
    },
    [entity, fetchValues, group, groupType],
  );

  const handleContactsChange = useCallback(
    (values) => {
      handlers.onChange({ contacts: values, item_ids: (values || []).reduce((acc, item) => [item.id, ...acc], []) });
    },
    [handlers],
  );

  useFutureDescendantsFlag(form);

  const isProducts = entity === 'products';
  const isAssets = entity === 'assets' || entity === 'assets_and_selections';

  return (
    <>
      {isProducts ? (
        <FormProducts
          name="item_ids"
          label={itemLabel}
          {...formData.item_ids}
          disabled={disabledItemsSelector}
          omni={omni}
          fetchValues={fetchProductsOverride}
          {...handlers}
        />
      ) : (
        <FormMultiSelect
          fetchValues={fetchItems}
          name="item_ids"
          label={itemLabel}
          {...formData.item_ids}
          onBlur={handlers.onBlur}
          options={items}
          disabled={disabledItemsSelector}
          omni={omni}
          onSelectedItemsChange={handleContactsChange}
        />
      )}
      {addSelectedProductAncestors && addParentProductsMessage}
      {!hideGroupSelector && (
        <FormRemoteSelect
          name="group_id"
          label={groupLabel}
          {...formData.group_id}
          {...handlers}
          fetchOptions={loadGroups}
          disabled={disabledGroupSelector}
          large
        />
      )}
      <ProductCheckboxes form={form} entity={entity} hideProductsParams={hideProductsParams} groupType={groupType} />
      {isAssets &&
        new Set([GroupTypes.ACCESS_GROUPS, GroupTypes.GUILD, GroupTypes.SMART_GROUPS]).has(groupType as GroupTypes) && (
          <FormCheckbox
            label="Permit download"
            name="permitAssetDownload"
            {...form.formData.permitAssetDownload}
            {...form.handlers}
          />
        )}
    </>
  );
};
