import React, { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { CloseButton, Combobox, ComboboxOptionProps, InputBase, Loader, Menu, useCombobox } from '@mantine/core';
import { useFocusWithin } from '@mantine/hooks';

import { IStyled, IItem, ICustomItemRendererProps, IProduct } from 'types';
import { formatFormLabel, getFieldPlaceholder } from 'helpers/form/fields/helpers';
import { ValidationMarker } from 'helpers/form/fields/validation-marker';
import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { IFormMultiSelectOption, itemRenderer, parseItem } from 'helpers/form/fields/select-helpers';
import { FormGroup } from 'helpers/form/fields/form-group';

import { useRemoteSelectSetup, usePreventBackspaceClick } from './hooks';
import { DefaultCreateTagElement } from './utils';
import { Classes } from 'utils/ui';

import { IBreadcrumbProps } from './ancestry-dropdown/ancestry-dropdown';
import { AncestryDropdown } from './ancestry-dropdown/ancestry-dropdown';
import { IFilterOption } from 'utils/hooks';
import { IFormRemoteSelectOption, IRemoteSelectorFetcher } from './types';

import { MantineIcon } from 'utils/ui/icon';
import { Intent } from 'utils/ui';
import { IContact } from 'types';

import './style.scss';

export interface IFormRemoteSelectProps
  extends IFieldData<string | number | undefined | null, (number | string | IFormRemoteSelectOption)[]>,
    IFieldHandlers<string | number | null | undefined>,
    IStyled {
  name: string;
  label?: string;
  large?: boolean;
  inline?: boolean;
  disabled?: boolean;
  fetchOptions: IRemoteSelectorFetcher;
  placeholder?: string;
  allowNewItems?: boolean;
  validateNewItem?: (query: string) => boolean;
  createTagElement?: React.FC<{ text: string | number; onClick: VoidFunction }>;
  isAncestryMode?: boolean;
  emptyValue?: null | '' | 0;
  parseItemRenderProps?: (item: IFormRemoteSelectOption) => ICustomItemRendererProps;
  isEmptyValueNull?: boolean;
}

const preparePropOptions = (options: (IItem | string | number)[]): IFormRemoteSelectOption[] => {
  const props: IFormRemoteSelectOption[] = (options || []).map((op): IFormRemoteSelectOption => {
    return typeof op === 'object'
      ? parseItem(op as IItem)
      : ({ label: '' + op, value: op.toString() } as IFormRemoteSelectOption);
  });

  return props;
};

const defaultBreadcrumbs: IBreadcrumbProps[] = [{ href: 'root', icon: 'double-chevron-left', text: 'Top' }];

export const FormRemoteSelect: React.FC<IFormRemoteSelectProps> = (props) => {
  const {
    name,
    label,
    large,
    inline,
    fetchOptions,
    disabled,
    placeholder,
    onChange,
    onBlur,
    validation,
    touched,
    value,
    required,
    style,
    className,
    allowNewItems = false,
    isAncestryMode = false,
    validateNewItem = (s: string): boolean => s?.trim().length > 0,
    createTagElement,
    parseItemRenderProps,
    emptyValue,
    isEmptyValueNull,
  } = props;

  const formattedPlaceholder = getFieldPlaceholder({
    placeholder,
    disabled,
    defaultPlaceholder: allowNewItems ? `Add ${label}` : `Select ${label}`,
  });

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => {
      combobox.updateSelectedOptionIndex('active');
      combobox.focusSearchInput();
    },
  });

  const CreateTagElement = createTagElement || DefaultCreateTagElement;
  const { ref, focused: isSearchInFocus } = useFocusWithin();

  const showError = Boolean(touched && !validation?.valid && validation?.errorMessage && !isSearchInFocus);
  const intent = showError ? Intent.DANGER : undefined;

  const [activeItem, setActiveItem] = useState<IFormRemoteSelectOption | null>(null);
  const [options, setOptions] = useState<IFormRemoteSelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitiating, setIsInitiating] = useState(Boolean(value));
  const [shouldInitiateOptions, setShouldInitiateOptions] = useState(false);
  const latestRequestRef = useRef(0);
  const [inputValue, setInputValue] = useState<string>('');
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbProps[]>(defaultBreadcrumbs);

  const filters = useRef<IFilterOption[] | undefined>([]);

  usePreventBackspaceClick(ref);

  const onQueryChange = useMemo(() => {
    const debouncedQuery = debounce(async (query) => {
      latestRequestRef.current = latestRequestRef.current + 1;
      const requestNumber = latestRequestRef.current;
      try {
        const options = await fetchOptions(query, undefined, filters?.current);
        if (requestNumber !== latestRequestRef.current) {
          return;
        }
        const parsedOptions = preparePropOptions(options);
        setOptions(parsedOptions);
        setIsLoading(false);
      } catch (err) {
        setOptions([]);
        setIsLoading(false);
      }
    }, 400);

    return (query: string): void => {
      setOptions([]);
      setIsLoading(true);
      setInputValue(query);
      debouncedQuery(query);
    };
  }, [fetchOptions]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      combobox.updateSelectedOptionIndex();
      onQueryChange(event.currentTarget.value);
    },
    [combobox, onQueryChange],
  );

  // Update useEffect to handle undefined values
  useEffect(() => {
    if (value === undefined || value === null) {
      setActiveItem(null);
      setInputValue('');
    }
  }, [value]);

  useRemoteSelectSetup({
    value,
    shouldInitiateOptions,
    onQueryChange,
    setIsInitiating,
    fetchOptions,
    activeItem,
    setActiveItem,
    enableClearing: !required,
    filters: filters.current,
  });

  const createNewItemFromQuery = useMemo(() => {
    if (!allowNewItems) {
      return;
    }
    return (query: string) => {
      const value = query.trim();
      return { label: value, value };
    };
  }, [allowNewItems]);

  const handleChange = useCallback(
    (item: IFormRemoteSelectOption): void => {
      const newValue = Number.isNaN(Number(item.value)) ? item.value : Number(item.value);
      onChange?.({ [name]: newValue });
      setActiveItem(item);
    },
    [name, onChange],
  );

  const handleAdd = useCallback((): void => {
    const trimmedValue = inputValue.trim();
    if (!validateNewItem(trimmedValue) || !trimmedValue || activeItem?.value === trimmedValue) {
      return;
    }
    const newItem = createNewItemFromQuery?.(trimmedValue);
    if (!newItem) {
      return;
    }
    handleChange(newItem as IFormRemoteSelectOption);
  }, [activeItem?.value, createNewItemFromQuery, handleChange, inputValue, validateNewItem]);

  const resetDropdownValues = useCallback(() => {
    setShouldInitiateOptions(false);
    setInputValue('');
    combobox.closeDropdown();
  }, [combobox]);

  const onItemSelect = useCallback(
    (val: string, itemProps?: ComboboxOptionProps) => {
      const targetEl = document.getElementById(val);

      const isAncestryArrowBtn =
        targetEl?.classList.contains('js-product-search-item__ancestry') ||
        targetEl?.closest('.js-product-search-item__ancestry');
      const hasItemProps = Object.keys(itemProps || {}).length;

      if (isAncestryArrowBtn && !hasItemProps) {
        const parentId = targetEl?.dataset.parentId;
        const text = targetEl?.dataset.text;
        filters.current = [['parent_id', 'eq', parentId || '']];
        setIsLoading(true);
        setBreadcrumbs((breadcrumbs) => [...breadcrumbs, { text, href: parentId }]);
        onQueryChange('');
        setInputValue('');
        return;
      }
      if (val === '$create') {
        handleAdd();
        resetDropdownValues();
      } else {
        const item = options.find((item) => item.value == val);
        if (item) {
          handleChange(item);
        }
        onBlur?.(name);
        resetDropdownValues();
      }
    },
    [onQueryChange, handleAdd, resetDropdownValues, options, onBlur, name, handleChange],
  );

  const handleEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        handleAdd();
      }
    },
    [handleAdd],
  );

  const handleBreadcrumbClick = async (e): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target;
    const menuItem = target.closest(`.${Classes.MENU_ITEM}`);
    const isLink = target.classList.contains(Classes.BREADCRUMB);
    let parentId;

    if (isLink) {
      parentId = target.getAttribute('href');
    }

    if (!isLink && menuItem) {
      parentId = menuItem.getAttribute('href');
    }

    if (!parentId) return;

    filters.current = parentId === 'root' ? undefined : [['parent_id', 'eq', parentId]];

    const breadcrumbIndex = breadcrumbs.findIndex((item) => item.href === parentId);
    const updated = breadcrumbs.slice(0, breadcrumbIndex + 1);

    setIsLoading(true);
    setBreadcrumbs(updated);
    setInputValue('');
    onQueryChange('');
  };

  const handleItemRenderer = (item: IFormMultiSelectOption): JSX.Element | null => {
    const itemProps = parseItemRenderProps ? parseItemRenderProps(item) : { disabled: false };
    return itemRenderer(item, onItemSelect, itemProps, { isAncestryMode });
  };

  const handleItemListRender = (): React.ReactElement => {
    const items = options.filter((item) => item !== null && item.value !== activeItem?.value);
    const hasItems = items.length;

    if (isAncestryMode) {
      return (
        <AncestryDropdown
          isLoading={isLoading}
          items={items as IProduct[]}
          renderItem={handleItemRenderer}
          breadcrumbs={breadcrumbs}
          onBreadcrumbClick={handleBreadcrumbClick}
        />
      );
    }
    if (isLoading) {
      return <Combobox.Empty>Loading...</Combobox.Empty>;
    }

    const trimmedValue = inputValue.trim();
    const exactEmailMatch = items.some(
      (item) => item.itemType === 'user' && (item as IFormRemoteSelectOption & IContact).email === trimmedValue,
    );
    const showCreateTag = allowNewItems && validateNewItem(trimmedValue) && !exactEmailMatch;

    if (hasItems) {
      return (
        <Menu>
          {showCreateTag && <CreateTagElement text={inputValue} onClick={handleAdd} />}
          {items.map(handleItemRenderer)}
        </Menu>
      );
    }

    if (showCreateTag) {
      return (
        <Menu>
          <CreateTagElement text={inputValue} onClick={handleAdd} />
        </Menu>
      );
    }

    return <Combobox.Empty> Nothing found.</Combobox.Empty>;
  };
  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const handleClearAll = useCallback(
    (name: string): void => {
      setActiveItem(null);
      setInputValue('');
      onChange?.({ [name]: isEmptyValueNull ? null : emptyValue });
    },
    [emptyValue, isEmptyValueNull, onChange],
  );

  const showClearBtn = activeItem && !required;

  return (
    <FormGroup
      label={formatFormLabel(label, required)}
      labelFor={name}
      intent={intent}
      inline={inline}
      className={classNames(className, { 'form-select-inline__container': inline })}
      style={style}
    >
      <Combobox
        store={combobox}
        onOptionSubmit={onItemSelect}
        portalProps={{
          className: 'form-remote-select-popover__portal',
        }}
        position="bottom-start"
        withinPortal
      >
        <Combobox.Target>
          <InputBase
            disabled={disabled}
            rightSection={
              isInitiating ? (
                <Loader size={18} />
              ) : showClearBtn ? (
                <CloseButton
                  size="md"
                  onMouseDown={(event) => event.preventDefault()}
                  onClick={() => handleClearAll(name)}
                  aria-label="Clear field"
                  className="clear-all__button"
                />
              ) : showError ? (
                <ValidationMarker {...validation} />
              ) : (
                <MantineIcon icon="caret-down" />
              )
            }
            rightSectionProps={{
              className: classNames(
                { 'form-remote-select__button': !showClearBtn },
                { empty: !value && !showClearBtn },
              ),
            }}
            onClick={(e) => {
              e.preventDefault();
              combobox.toggleDropdown();
            }}
            onFocus={(e) => {
              e.preventDefault();
              setShouldInitiateOptions(true);
            }}
            onBlur={handleBlur}
            placeholder={formattedPlaceholder}
            size={large ? 'md' : 'xs'}
            component="button"
            pointer
            className={classNames('form-remote-select__selected-label', {
              'form-remote-select__placeholder': !activeItem,
            })}
            error={showError && <></>}
          >
            {activeItem?.label || formattedPlaceholder}
          </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown hidden={disabled}>
          <Combobox.Search
            ref={ref}
            value={inputValue}
            onChange={handleInputChange}
            onKeyUp={handleEnter}
            placeholder={'Filter ...'}
            leftSection={<MantineIcon icon="search" />}
          />
          <Combobox.Options style={{ overflowY: 'auto', listStyle: 'none' }}>{handleItemListRender()}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </FormGroup>
  );
};

export default FormRemoteSelect;
