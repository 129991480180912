import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { formatDate } from 'utils/date';
import { flags } from 'utils/flags';

import { IAsset, IMm3Asset } from 'types';
export const AssetMetadataTimestamps: React.FC<{ asset?: IAsset | IMm3Asset }> = ({ asset }) => {
  const { created_at, published_at, updated_at } = asset || {};
  const { available_since } = asset as IMm3Asset;

  return (
    <InfoBoxV2 gridTemplateColumns="160px 1fr" title="Timestamps">
      <Row label="Created at" rawLabel value={formatDate(created_at)} size="m" />
      <Row label="Last Updated" value={formatDate(updated_at)} size="m" />
      <Row label="Published at" rawLabel value={formatDate(published_at)} size="m" />
      {flags.isMm3Assets && <Row label="Available since" rawLabel value={formatDate(available_since)} />}
    </InfoBoxV2>
  );
};
