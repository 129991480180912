import { omit } from 'lodash';
import { useCallback } from 'react';
import { Model } from 'helpers/filters/types';
import { IContact, IQueryParams, ISearchFilter, ItemId } from 'types';

import { loadSelectionItems } from 'utils/apis/selection';
import { IItemId } from 'components/form-selection-items';
import { IResult } from '@mediafellows/chipmunk';
import { chipmunk } from 'utils/chipmunk';
import { parseToSearchParams } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { contactListSchema } from 'utils/schemas/contact';

interface IGetContactsParams {
  contactIds?: ItemId | ItemId[];
  schema?: string;
}

export interface IContactStatistics {
  groups_count: number;
  organizations_active: number;
  organizations_count: number;
  organizations_inactive: number;
  users_active: number;
  users_count: number;
  users_inactive: number;
  users_invited: number;
  users_pending: number;
  users_review: number;
}

export const getContacts = ({
  contactIds = [],
  schema = contactListSchema,
}: IGetContactsParams): Promise<IContact[]> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action(Model.CONTACTS, 'get', {
      params: { user_ids: contactIds },
      schema,
    });

    return objects;
  });
};

export const queryContacts = (
  { ids, q, ...params }: { ids?: ItemId[] | null; q?: string; per?: number; include_deleted?: boolean },
  additionalFilters: ISearchFilter[] = [],
  schema = contactListSchema,
  action: 'action' | 'unfurl' = 'action',
): Promise<IContact[]> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk[action](Model.CONTACTS, 'search', {
      body: parseToSearchParams({ ids, q }, additionalFilters),
      params: { per: Math.max(25, ids?.length || 0), ...params },
      schema,
    });

    return objects;
  });
};

export const searchContacts = (
  filters: ISearchFilter[] = [],
  params?: IQueryParams,
  schema = contactListSchema,
): Promise<IResult<IContact>> => {
  return chipmunk.run(async ({ action }) => {
    return action<IContact>(Model.CONTACTS, 'search', {
      body: { search: { filters } },
      params,
      schema,
    });
  });
};

export const getContactStatistics = (): Promise<IContactStatistics> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action(Model.CONTACT_STATISTICS_DATA, 'member.get');

    return objects;
  });
};

export const loadContactsForSelectionPreview = async ({ selection }): Promise<IContact[]> => {
  const { id } = selection;
  if (!id) {
    return [];
  }

  return loadSelectionItems<IContact>({
    selections: [selection],
    target: Model.CONTACT_GROUP_SET,
    actionName: 'query_users',
    schema: 'id, preview_image, status, first_name, last_name',
    per: 3,
  });
};

export const useContactsForSelectionPreview = ({ selection }): [IContact[], boolean] => {
  const fetchContacts = useCallback(async () => loadContactsForSelectionPreview({ selection }), [selection]);

  const [contacts, loading] = useRemote<IContact[]>(fetchContacts, []);

  return [contacts || [], loading];
};

export const fetchResponsibleContacts: (query: string, id?: ItemId) => Promise<IContact[]> = async (query, id) => {
  const responsibleContactFilter: ISearchFilter[] = [
    ['role.designation', 'eq', 'All'],
    ['role.internal_account', 'ne', true],
  ];

  const params: { q?: string; ids?: ItemId[] } = { q: query };
  if (id) {
    params.ids = [id];
  }
  return queryContacts(params, responsibleContactFilter);
};

export const updateContacts = async (contactsIds: IItemId[], values: Partial<IContact>): Promise<void> =>
  chipmunk.run(async ({ action }) => {
    return action(Model.CONTACTS, 'update', {
      params: { user_ids: contactsIds },
      body: contactsIds.map((id) => ({ ...values, id })),
      multi: true,
    });
  });

export const getContactGroupIds = async (user_ids: IItemId | IItemId[]): Promise<number[]> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.CONTACTS, 'group_ids', {
      params: {
        user_ids,
      },
    });

    return object.group_ids;
  });
};

export const fetchClientContacts = async (params: { ids?: string[]; q?: string }): Promise<IContact[]> => {
  const result = await queryContacts(params, [['role.designation', 'eq', 'Buyer Only']]);
  return result;
};

export const exportContacts = (params: IQueryParams, filters: ISearchFilter[] = []): Promise<void> => {
  const omitParams = omit(params, ['per', 'page']);
  const body = parseToSearchParams(omitParams, filters);

  return chipmunk.run(async ({ action }) => {
    await action(Model.CONTACTS, 'simple_export', { body });
  });
};

export const findEmails = (query: string, emails: Array<string>): Promise<IContact[]> => {
  const body = {
    search: {
      filters: [['email', 'in', emails]],
    },
  };

  return chipmunk.run(async ({ action }) => {
    return (await action(Model.CONTACTS, 'search', { body })).objects;
  });
};

export const findEmail = (email: string): Promise<IContact> => {
  const body = {
    per: 1,
    include_deleted: true,
    search: {
      filters: [['email', 'eq', email]],
    },
  };

  return chipmunk.run(async ({ action }) => {
    return (await action(Model.CONTACTS, 'search', { body })).object;
  });
};
