import React, { useMemo } from 'react'; //
import { observer } from 'mobx-react-lite';

import { IUseActionsOptionParam, Pages } from 'utils/actions/types';

import { AssetsDataSection } from 'components/asset/assets-data-section';

import { DetailsPageTabs, IAsset, IMm3Asset, ISearchFilter } from 'types';
import { AssetType } from 'utils/format-asset-type';
import { EqFilter } from 'helpers/filters/types';
import { IAssetFilters } from 'components/asset/asset-filters';
import { flags } from 'utils/flags';
import { OldAssetAudiosTab } from 'pages/asset-details/asset-audios-tab/asset-audios-tab';
import { AssetOldSubtitlesTab } from 'pages/asset-details/asset-subtitles-tab/asset-old-subtitles-tab';

interface IChildrenTabPreviewProps {
  asset: IAsset | IMm3Asset;
  tab: DetailsPageTabs;
  setAsset: React.Dispatch<React.SetStateAction<IAsset | IMm3Asset>>;
}

export const AssetChildrenTab: React.FC<IChildrenTabPreviewProps> = ({ asset, tab, setAsset }) => {
  const { id } = asset || {};
  const { isMm3Assets, hasCustomClassifications } = flags;

  const [persistentFilters, actionsOptions, initFilters] = useMemo<
    [ISearchFilter[], Partial<IUseActionsOptionParam<IMm3Asset>> | undefined, Partial<IAssetFilters> | undefined]
  >(() => {
    let options: Partial<IUseActionsOptionParam<IMm3Asset>> | undefined = { parentId: id || 0 };

    let filters: ISearchFilter[] = [];
    let initialFilters: Partial<IAssetFilters> | undefined;
    const parentIdFilter: ISearchFilter = ['parent_id', 'eq', id || 0];

    switch (tab) {
      case DetailsPageTabs.REPORTS:
        filters = [
          parentIdFilter,
          ['classification', 'eq', 'document/report'],
          ['main_classification', 'eq', 'document'],
        ];
        break;
      case DetailsPageTabs.SUB_ASSETS:
        filters = [
          parentIdFilter,
          ['classification', 'not_in', ['document/subtitle', 'document/report']],
          ['type', 'not_in', hasCustomClassifications ? [AssetType.AUDIO, AssetType.SUBTITLE] : [AssetType.AUDIO]],
        ];
        break;
      case DetailsPageTabs.SUBTITLES:
        filters = hasCustomClassifications
          ? [parentIdFilter, ['type', 'eq', AssetType.SUBTITLE]]
          : [parentIdFilter, ['classification', 'eq', 'document/subtitle'], ['main_classification', 'eq', 'document']];
        options = undefined;
        break;
      case DetailsPageTabs.AUDIO_TRACKS:
        filters = [parentIdFilter, ['type', 'eq', AssetType.AUDIO]];
        initialFilters = {
          type: new EqFilter('type', AssetType.AUDIO),
        } as Partial<IAssetFilters>;
        break;
    }
    return [filters, options, initialFilters];
  }, [hasCustomClassifications, id, tab]);

  if (!isMm3Assets && tab === DetailsPageTabs.AUDIO_TRACKS) {
    return <OldAssetAudiosTab asset={asset as IAsset} />;
  }
  if (!isMm3Assets && tab === DetailsPageTabs.SUBTITLES) {
    return <AssetOldSubtitlesTab asset={asset as IAsset} setAsset={setAsset} />;
  }
  return (
    <AssetsDataSection
      initFilters={initFilters}
      persistentFilters={persistentFilters}
      actionsOptions={actionsOptions}
      page={Pages.ASSET_SUB_ASSETS_TAB}
    />
  );
};

export default observer(AssetChildrenTab);
