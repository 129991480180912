import { IJsonSchemaSpec } from '@mediafellows/chipmunk';
import { ErrorObject, ValidateFunction } from 'ajv';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';

import { isObject } from 'utils/payload';
import { logger } from 'utils/logger';

import { IValidationObject, IValidationOptions, IValidationResult } from './types';

function buildPath(path: string, key: string | number): string {
  if (!path || (!key && key !== 0)) return path || key?.toString() || '';

  const separator = path.endsWith('/') ? '' : '/';

  return [path, key].join(separator);
}

const isRequiredFiledMissing = (validate: ValidateFunction<IJsonSchemaSpec>, path: string, key: string): boolean => {
  const cleanPath = path.replace(/\/$/, '');
  return Boolean(validate.errors?.find((err) => err.instancePath === cleanPath && err.params.missingProperty === key));
};

const getFieldError = (validate: ValidateFunction<IJsonSchemaSpec>, fieldPath: string): ErrorObject | undefined => {
  validate.errors?.forEach((error) => {
    const limit = error?.params?.limit || 0;
    if (error.keyword.includes('minLength') && limit > 1) {
      error.message = `Please enter at least ${limit} characters`;
    } else if (error.keyword.includes('maxLength') && limit > 1) {
      error.message = `Please enter fewer than ${limit} characters`;
    } else if (error.keyword.includes('minimum') && limit > 1) {
      error.message = `Please enter a value above ${limit}`;
    } else if (error.keyword.includes('maximum') && limit > 1) {
      error.message = `Please enter a value under ${limit}`;
    } else if (error.keyword?.includes('type')) {
      error.message = `Invalid input. Please check and try again`;
    } else {
      error.message = 'This field is required';
    }
  });

  return validate.errors?.find((error) => error.instancePath === fieldPath);
};

function buildValidationObject(valid: boolean, errorMessage?: string): IValidationObject {
  return {
    validation: {
      valid,
      ...(errorMessage ? { errorMessage: capitalize(errorMessage) } : {}),
    },
  };
}

export function calculateValidation<T>(
  values: T,
  validate?: ValidateFunction<IJsonSchemaSpec>,
  { path, depth, maxDepth }: IValidationOptions = { path: '/', depth: 0, maxDepth: 10 },
): IValidationResult<T> {
  if (!values || !validate || depth > maxDepth) return {} as IValidationResult<T>;

  return Object.entries(values).reduce((acc, [key, value]) => {
    const fieldPath = buildPath(path, key);
    if (isObject(value))
      return {
        ...acc,
        [key]: calculateValidation(value, validate, { maxDepth, path: fieldPath, depth: depth + 1 }),
      };

    if (Array.isArray(value) && value.length > 0 && isObject(value[0])) {
      // fix for missing keys when adding an item to a list, see #4387
      const lastItem = value.at(-1);
      if (isEmpty(lastItem)) {
        const path = buildPath(fieldPath, value.length - 1);
        validate?.errors?.map(({ instancePath, keyword, params }) => {
          if (instancePath === path && keyword === 'required' && params?.missingProperty) {
            lastItem[params.missingProperty] = undefined;
          }
        });
      }

      return {
        ...acc,
        [key]: value.map((val, index) =>
          calculateValidation(val, validate, {
            maxDepth,
            path: buildPath(fieldPath, index),
            depth: depth + 1,
          }),
        ),
      };
    }

    // check for required error
    const requiredError = isRequiredFiledMissing(validate, path, key);
    if (requiredError) {
      return { ...acc, [key]: getRequiredError() };
    }

    // check for any other error
    const error = getFieldError(validate, fieldPath);

    // enum values that are not required should be allowed to be null
    const isNullEnum = value === null && error?.keyword === 'enum';

    if (error && !isNullEnum)
      return {
        ...acc,
        [key]: buildValidationObject(false, error.message),
      };
    return { ...acc, [key]: buildValidationObject(true) };
  }, {} as IValidationResult<T>);
}

export function isFormDataValid<T>(validations: IValidationResult<T>, values: T): boolean {
  if (!validations || !values) {
    return false;
  }
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (!acc) return false;

    if (isObject(value)) {
      return isFormDataValid(validations[key], value);
    }

    if (Array.isArray(value) && value.length > 0 && isObject(value[0])) {
      const isValid = value.every((v, index) => isFormDataValid(validations[key][index], v));
      if (!isValid) {
        logger.info('invalid value for: ', key);
      }

      return value.every((v, index) => isFormDataValid(validations[key][index], v));
    }

    const isValid = Boolean(validations[key]?.validation?.valid);
    if (!isValid) {
      logger.info('invalid value for: ', key);
    }

    return isValid;
  }, true);
}

export const getRequiredError = (): IValidationObject => buildValidationObject(false, 'This field is required');
