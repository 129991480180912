import React, { useCallback, useMemo, useEffect, useState, useRef } from 'react';
import isArray from 'lodash/isArray';
import { toJS } from 'mobx';
import { ChevronDown, ChevronRight } from 'blueprint5-icons';
import {
  ActionIcon,
  Pill,
  PillsInput,
  Flex,
  noop,
  Tree,
  Group,
  Checkbox,
  Combobox,
  useCombobox,
  Loader,
  MantineSize,
} from '@mantine/core';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { isEqual } from 'lodash';

import { MantineIcon } from 'utils/ui';
import { IAggregation, InFilter, NotInFilter } from 'helpers/filters/types';
import { FormGroup } from 'helpers/form/fields/form-group';
import { FilterTreeNode, buildTreeWithAggregations } from './utils';
import { ITreeDataProvider } from 'helpers/data-provider/tree-data-provider';
import { getFieldPlaceholder } from 'helpers/form/fields/helpers';

import './style.scss';

type ITreeFilterDefinition = InFilter | NotInFilter;

interface IFilterTreeProps {
  name: string;
  label: string;
  optionsProvider: ITreeDataProvider;
  disabled?: boolean;
  aggregations?: IAggregation[];
  filter: ITreeFilterDefinition;
  onChange?: (newValue: { [key: string]: ITreeFilterDefinition }) => void;
  size?: MantineSize | string;
  placeholder?: string;
}

const FilterTree: React.FC<IFilterTreeProps> = observer((props) => {
  const { name, label, filter, aggregations, onChange, optionsProvider, disabled, size = 'md', placeholder } = props;
  const { value: initialValue } = filter || {};
  const value = useMemo(() => (isArray(initialValue) ? initialValue : [initialValue]), [initialValue]);
  const disableReset = value.length === 0;
  const formattedPlaceholder = getFieldPlaceholder({ placeholder, disabled, defaultPlaceholder: `Select ${label}` });

  const options = toJS(optionsProvider.data);

  useEffect(() => {
    if (value.length && !options.length) optionsProvider.init();
  }, [value, options.length, optionsProvider]);

  const [checked, setChecked] = useState<FilterTreeNode[]>([]);
  const [indeterminate, setIndeterminate] = useState<FilterTreeNode[]>([]);
  const [selectedItems, setSelectedItems] = useState<FilterTreeNode[]>([]);
  const [loadingTree, setLoadingTree] = useState(false);
  const treeDataInitialized = useRef(false);
  const aggregationsRef = useRef(aggregations);

  const [treeData, setTree] = useState<FilterTreeNode[]>([]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const setCheckedStates = useCallback((treeData: FilterTreeNode[]): { checked: FilterTreeNode[] } => {
    const checked = treeData?.flatMap((node) => node.getChecked());
    const indeterminate = treeData?.flatMap((node) => node.getIndeterminate());
    const selected = treeData?.flatMap((node) => node.getSelected());
    setChecked(checked);
    setIndeterminate(indeterminate);
    setSelectedItems(selected);
    return { checked };
  }, []);

  const loadTreeData = useCallback(() => {
    setLoadingTree(true);
    const initialValues = value?.map((v) => v.toString());
    const treeData = buildTreeWithAggregations({ data: options, initialValues, aggregations }).tree;
    setTree(treeData);
    setCheckedStates(treeData);
    setLoadingTree(false);
  }, [aggregations, options, setCheckedStates, value]);

  useEffect(() => {
    if (!options?.length || treeDataInitialized.current || !aggregations) return;
    loadTreeData();
    treeDataInitialized.current = true;
  }, [aggregations, loadTreeData, options, setCheckedStates, value]);

  useEffect(() => {
    if (!isEqual(aggregations, aggregationsRef.current) && treeDataInitialized.current) {
      loadTreeData();
    }
    aggregationsRef.current = aggregations;
  }, [aggregations, loadTreeData]);

  const setCheckedStatesAndForm = useCallback(() => {
    const { checked } = setCheckedStates(treeData);
    onChange?.({ [name]: { ...filter, value: checked.map((node) => Number(node.value)) } });
  }, [setCheckedStates, treeData, onChange, name, filter]);

  const handlePopoverIneraction = useCallback(() => {
    !disabled && combobox.toggleDropdown();
    optionsProvider.init();
  }, [combobox, disabled, optionsProvider]);

  const selectedValues = useMemo(
    () =>
      checked?.map((node) => (
        <Pill
          key={node?.value}
          withRemoveButton
          onRemove={() => {
            node.uncheck();
            setCheckedStatesAndForm();
          }}
          radius="xs"
          removeButtonProps={{ color: 'gray.5' }}
          className="tree-select__pill"
        >
          {node.label}
        </Pill>
      )),
    [checked, setCheckedStatesAndForm],
  );

  const handleReset = useCallback((): void => {
    treeData?.forEach((node) => node.uncheck());
    setCheckedStatesAndForm();
  }, [setCheckedStatesAndForm, treeData]);

  const dataLoading = loadingTree || optionsProvider.loading;
  return (
    <Flex align="center">
      <FormGroup label={label} labelFor={name} className="w-100">
        <Combobox store={combobox} withinPortal position="bottom" offset={3}>
          <Combobox.DropdownTarget>
            <PillsInput
              pointer
              onClick={handlePopoverIneraction}
              size={size}
              classNames={{ input: 'tree-select__pill-input' }}
            >
              <Pill.Group className="justify-content-between flex-nowrap">
                {checked.length > 0 && (
                  <Flex gap="0.4rem" wrap="wrap" className="tree-select__selected-values">
                    {selectedValues}
                  </Flex>
                )}
                <Combobox.EventsTarget>
                  <PillsInput.Field
                    type={selectedValues.length ? 'hidden' : 'visible'}
                    placeholder={formattedPlaceholder}
                    readOnly
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace') {
                        event.preventDefault();
                        const node = selectedItems[selectedItems.length - 1];
                        node.uncheck();
                        setCheckedStatesAndForm();
                      }
                    }}
                  />
                </Combobox.EventsTarget>
              </Pill.Group>
            </PillsInput>
          </Combobox.DropdownTarget>

          <Combobox.Dropdown className="filter-tree-select__dropdown">
            <Combobox.Options
              mah={200}
              style={{ overflowY: dataLoading ? 'hidden' : 'auto' }}
              className={cx({ 'd-flex justify-content-center': dataLoading })}
            >
              {dataLoading && <Loader size="sm" className="m-1" />}
              {!dataLoading && !treeData.length && <Combobox.Empty>No data found</Combobox.Empty>}
              {!dataLoading && !!treeData.length && (
                <Tree
                  data={treeData}
                  renderNode={({ node: treeNode, expanded, hasChildren, elementProps }) => {
                    const node = treeNode as FilterTreeNode;
                    return (
                      <Combobox.Option
                        value={node.value}
                        key={node.value}
                        active={node.isChecked()}
                        style={{ cursor: node.disabled ? 'not-allowed' : 'pointer' }}
                      >
                        <Group gap={5} {...elementProps} onClick={noop} wrap="nowrap">
                          <ActionIcon
                            onClick={hasChildren ? elementProps.onClick : noop}
                            variant="transparent"
                            style={{
                              cursor: hasChildren ? 'pointer' : 'default',
                              fill: !hasChildren ? 'transparent' : 'currentColor',
                            }}
                          >
                            {!expanded && (
                              <MantineIcon
                                icon={<ChevronRight color={!hasChildren ? 'transparent' : 'currentColor'} />}
                              />
                            )}
                            {expanded && (
                              <MantineIcon
                                icon={<ChevronDown color={!hasChildren ? 'transparent' : 'currentColor'} />}
                              />
                            )}
                          </ActionIcon>
                          <Checkbox
                            size="sm"
                            indeterminate={indeterminate.includes(node)}
                            checked={checked.includes(node)}
                            onClick={() => {
                              node.toggleCheck();
                              setCheckedStatesAndForm();
                            }}
                            onChange={noop}
                            label={
                              node?.secondaryLabel ? (
                                <Flex justify="space-between" wrap="wrap">
                                  <div>{node.label}</div>
                                  <div className="filter__item-aggregation">{`(${node.secondaryLabel})`}</div>
                                </Flex>
                              ) : (
                                node.label
                              )
                            }
                            disabled={node.disabled}
                            className="w-100 filter-tree__checkbox"
                            style={{
                              cursor: node.disabled ? 'not-allowed' : 'pointer',
                            }}
                          />
                        </Group>
                      </Combobox.Option>
                    );
                  }}
                />
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      </FormGroup>
      <ActionIcon variant="subtle" color="gray.5" disabled={disableReset} className="mt-2" onClick={handleReset}>
        <MantineIcon icon="cross" />
      </ActionIcon>
    </Flex>
  );
});

export default FilterTree;
