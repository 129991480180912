import { useEffect, useRef, useMemo, useState } from 'react';
import { isEqual, omit } from 'lodash';

import { IAsset, IMm3Asset } from 'types';
import { parseKeyPathToObject } from 'utils/payload';
import { flags } from 'utils/flags';

import { assetFormCustomContext, editAssetOldPresetInitialData } from 'components/asset/asset-edit-form/constants';
import { IAssetFormFields } from 'components/asset/asset-edit-form/sections';

import { Model } from 'helpers/filters/types';
import { useForm, IUseFormReturn, useMm3Form } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { customValidator } from 'components/asset/asset-edit-form/custom-validator';
import { getAssetFormFields } from './utils';

type IOnDataChangeCallback = (object: IAsset | IMm3Asset) => void;
interface IUseAssetEditFormProps {
  asset?: IAsset | IMm3Asset;
  onDataChange?: IOnDataChangeCallback;
  handleSubmit?: (values: IAssetFormFields | IMm3Asset) => Promise<void>;
}
type IUseAssetEditForm = (
  props: IUseAssetEditFormProps,
) => IUseFormReturn<IAssetFormFields> | IUseMm3FormReturn<IMm3Asset>;

export const useAssetEditForm: IUseAssetEditForm = ({ asset = {}, onDataChange, handleSubmit }) => {
  const initialAssetFields = useMemo(
    () =>
      flags.isMm3Assets
        ? asset
        : getAssetFormFields({ ...editAssetOldPresetInitialData, ...(asset as IAssetFormFields) }),
    [asset],
  );

  const latestAssetFields = useRef(initialAssetFields);
  const [oldContext, setOldContext] = useState(assetFormCustomContext);

  const oldform = useForm<IAssetFormFields>(
    (flags.isMm3Assets ? {} : initialAssetFields) as IAssetFormFields,
    Model.ASSETS,
    handleSubmit,
    oldContext,
  );
  const mm3form = useMm3Form<IMm3Asset>(
    omit(asset, 'products') as IMm3Asset,
    Model.MM3_ASSETS,
    handleSubmit,
    customValidator,
  );

  const form = flags.isMm3Assets ? mm3form : oldform;
  const { values, handlers, valid } = form;
  const latestValues = useRef(values);

  useEffect(() => {
    if (isEqual(initialAssetFields, latestAssetFields.current)) {
      return;
    }

    latestAssetFields.current = initialAssetFields;
    handlers.onChange(initialAssetFields);
  }, [handlers, initialAssetFields]);

  useEffect(() => {
    if (isEqual(values, latestValues.current)) {
      return;
    }

    latestValues.current = values;
    onDataChange?.(
      flags.isMm3Assets
        ? (values as IMm3Asset)
        : parseKeyPathToObject<IAssetFormFields, IAsset>(values as IAssetFormFields),
    );
  }, [values, valid, onDataChange]);

  useEffect(() => {
    if (flags.isMm3Assets) return;
    setOldContext((prevContext) => {
      const updatedProperties = { ...prevContext.properties };
      if (form.values.access_level === 'division') {
        updatedProperties['division_ids'] = { required: true, validations: [{ presence: true }] };
      } else {
        updatedProperties['division_ids'] = { required: false };
      }
      return {
        ...prevContext,
        properties: updatedProperties,
      };
    });
  }, [form.values.access_level]);

  return form;
};
