import { merge } from 'lodash';
import { UmGenericListShowcase, UmGenericListCollection, UmGenericListAdvertisement } from '@mediafellows/mm3-types';

import { IFormMultiSelectOption } from 'helpers/form';
import { ICustomItemRendererProps, IProduct, IWebsitePageValues, Product3Types } from 'types';
import { IValidationResult } from 'helpers/form/mm3';

export const isCollectionPage = (v: Partial<UmGenericListShowcase | IGenericListCollectionForm>): boolean =>
  v?.type === 'List::Collection';

export const itemTypeToLabel = {
  'group/event': 'events',
  'list/collection': 'collections',
  user: 'contacts',
  category: 'genres',
  asset: 'assets',
  product: 'products',
  organization: 'organizations',
};

export const formatItemTypesOptions = (value: string): IFormMultiSelectOption => {
  return { label: itemTypeToLabel[value] || value, value, id: value };
};

const initialValues: UmGenericListShowcase = {
  type: 'List::Showcase',
  name: '',
  purpose: '',
  meta: { page: IWebsitePageValues.HOME, category_ids: [] },
  allowed_item_types: [],
  access_level: 'company',
};

export interface IGenericListCollectionForm extends UmGenericListCollection {
  previewImageUrl?: string;
}

export interface IGenericListAdvertisementForm extends UmGenericListAdvertisement {
  previewImageUrl?: string;
}

export const getCollectionInitialValues = (): IGenericListCollectionForm => {
  return {
    sublist: false,
    access_level: 'company',
    name: '',
    purpose: '',
    meta: { category_ids: [] },
    allowed_item_types: ['product'],
    type: 'List::Collection',
    previewImageUrl: '',
  } as IGenericListCollectionForm;
};

export const getShowcaseInitialValues = (section: IWebsitePageValues): UmGenericListShowcase => {
  return { ...initialValues, meta: { ...initialValues.meta, page: section } } as UmGenericListShowcase;
};

export const getAdvertisementInitialValues = (): IGenericListAdvertisementForm => {
  return {
    sublist: false,
    access_level: 'company',
    name: '',
    purpose: '',
    meta: { description: '' },
    allowed_item_types: ['asset'],
    type: 'List::Advertisement',
    previewImageUrl: '',
  } as IGenericListAdvertisementForm;
};

export const formatWebsiteTypes = (type?: string): string => type?.split('::').pop() || 'Showcase';

export function customShowcaseFormValidator(
  { meta }: UmGenericListShowcase,
  validations: IValidationResult<UmGenericListShowcase>,
): IValidationResult<UmGenericListShowcase> {
  if (!meta) return validations;

  return merge({}, validations, {
    categories: { validation: { valid: true } },
    meta: {
      page: {
        required: true,
      },
    },
  }) as IValidationResult<UmGenericListShowcase>;
}

export function customCollectionFormValidator(
  collection: UmGenericListCollection,
  validations: IValidationResult<UmGenericListCollection>,
): IValidationResult<UmGenericListCollection> {
  return validations;
}

function getCollectionProductModifiers(product: IFormMultiSelectOption): {
  isDisabled: boolean;
  isNavigationEnabled: boolean;
} {
  const type = (product as IProduct).type || product?.itemType;
  const isDisabled = false;
  if (!type)
    return {
      isDisabled,
      isNavigationEnabled: false,
    };
  const { ancestry_info } = product as IProduct;

  switch (type) {
    case Product3Types.SEASON:
      return {
        isDisabled,
        isNavigationEnabled: false,
      };
    case Product3Types.SERIES:
      const hasSeasons = Boolean(ancestry_info?.effective_seasons_count);
      return {
        isDisabled,
        isNavigationEnabled: hasSeasons,
      };
    case Product3Types.FORMAT:
      return {
        isDisabled,
        isNavigationEnabled: true,
      };
    default:
      return {
        isDisabled,
        isNavigationEnabled: false,
      };
  }
}

export const showcaseRenderItemParser = (item: IFormMultiSelectOption): ICustomItemRendererProps => {
  const { isDisabled, isNavigationEnabled } = getCollectionProductModifiers(item);
  return { disabled: isDisabled, isNavigationEnabled };
};
