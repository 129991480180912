import React, { useCallback, useMemo } from 'react';

import { startCase } from 'lodash';
import { Tabs } from '@mantine/core';

import { useStore } from 'store';

import { IActionType, RightSection } from 'components/section-header/section-header';
import { ToastError } from 'components/toast';
import AffiliationGeneralTab from './tabs/affiliation-preview-tabs/affiliation-general-tab';
import AffiliationEmailSettingsTab from './tabs/affiliation-preview-tabs/affiliation-email-settings-tab';
import AffiliationMobileAppTab from './tabs/affiliation-preview-tabs/affiliation-mobile-app-tab';
import AffiliationEditGeneralTab from './tabs/affiliation-edit-tabs/affiliation-edit-general-tab';
import AffiliationEditEmailSettingsTab from './tabs/affiliation-edit-tabs/affiliation-edit-email-settings-tab';
import AffiliationEditMobileAppTab from './tabs/affiliation-edit-tabs/affiliation-edit-mobile-app-tab';
import { AffiliationDetailTab, AffiliationTabPanel } from './tabs/affiliation-tabs';
import { TabButtonsWrapper } from 'components/tabs';

import { updateAffiliation } from 'utils/apis/affiliation';
import { useEditMode, useTab } from 'utils/hooks';
import { useForm } from 'helpers/form';
import { deleteUnreferencedAttachments } from 'utils/apis/attachment';
import { prepareAffiliationPayload } from './utils';
import { STORAGE_KEYS, setStorageItem } from 'utils/storage';
import { hasPower } from 'utils/powers';

import { DetailsPageTabs, Globals, ViewMode } from 'types';
import { Model } from 'helpers/filters/types';
import { IAffiliationForm, IAffiliationLoadedProps } from './types';

import './style.scss';

const tabOptions = [DetailsPageTabs.GENERAL, DetailsPageTabs.EMAIL_SETTINGS, DetailsPageTabs.MOBILE_APP];

const AffiliationLoaded: React.FC<IAffiliationLoadedProps> = ({ affiliation, refresh }) => {
  const [activeTab, setActiveTab] = useTab(DetailsPageTabs.GENERAL, tabOptions);
  const { toastStore } = useStore();
  const [editModeEnabled, setEditModeEnabled] = useEditMode();
  const isSuperAdmin = hasPower('*');

  const handleTabChange = useCallback(
    (tab: DetailsPageTabs): void => {
      setActiveTab(tab);
    },
    [setActiveTab],
  );
  const initialValues = useMemo(
    (): IAffiliationForm => ({
      ...affiliation,
      logoUrl: affiliation.logo?.url || '',
      splashScreenUrl: affiliation.splash_screen?.url || '',
      splashScreenVideoUrl: affiliation.mobile_app_splash_screen || '',
      primarySellerDomain: affiliation.primary_seller_domain?.id || 0,
      primaryBuyerDomain: affiliation.primary_buyer_domain?.id || 0,
    }),
    [affiliation],
  );

  const handleConfirm = useCallback(
    async (values: IAffiliationForm): Promise<void> => {
      if (!values) {
        return;
      }
      const affiliationForm = await prepareAffiliationPayload(values);

      try {
        const result = await updateAffiliation(affiliationForm);
        await deleteUnreferencedAttachments(affiliation, result, ['mobile_app_splash_screen']);
        if (values?.brand_header && affiliation.brand_header !== values?.brand_header) {
          setStorageItem(STORAGE_KEYS.WEBSITE_BRAND_HEADER, values?.brand_header);
          window.dispatchEvent(new Event(Globals.AFFILIATION_LOGO_UPDATE));
        }
        refresh();
        setEditModeEnabled(false);
        result && toastStore.success('Affiliation was successfully updated!');
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [affiliation, refresh, setEditModeEnabled, toastStore],
  );

  const affiliationForm = useForm<IAffiliationForm>(initialValues || {}, Model.AFFILIATION, handleConfirm);

  const setMode = useCallback(
    (mode: ViewMode) => {
      setEditModeEnabled(mode === ViewMode.EDIT);
    },
    [setEditModeEnabled],
  );

  const mode = editModeEnabled ? ViewMode.EDIT : ViewMode.PREVIEW;

  const actions = useMemo(() => {
    return mode === ViewMode.PREVIEW
      ? [
          {
            type: 'edit' as IActionType,
            handler: () => setMode(ViewMode.EDIT),
            disabled: activeTab === DetailsPageTabs.EMAIL_SETTINGS && !isSuperAdmin,
          },
        ]
      : [
          {
            type: 'save' as IActionType,
            disabled: !affiliationForm?.valid || !affiliationForm?.valid,
            handler: affiliationForm?.onSubmit,
          },
          {
            type: 'cancel' as IActionType,
            handler: async () => {
              setMode(ViewMode.PREVIEW);
              affiliationForm.resetFields();
            },
          },
        ];
  }, [activeTab, affiliationForm, isSuperAdmin, mode, setMode]);

  return (
    <div className="affiliation-details__content-wrapper flex-full">
      <div className="affiliation-details__content h-100">
        <Tabs className="affiliation-details__tabs h-100" value={activeTab} onChange={handleTabChange}>
          <Tabs.List className="d-flex flex-nowrap">
            <AffiliationDetailTab value={DetailsPageTabs.GENERAL} activeTab={activeTab} />
            <AffiliationDetailTab
              value={DetailsPageTabs.EMAIL_SETTINGS}
              activeTab={activeTab}
              disabled={mode === ViewMode.EDIT && !isSuperAdmin}
              valueParser={startCase}
            />
            <AffiliationDetailTab value={DetailsPageTabs.MOBILE_APP} activeTab={activeTab} valueParser={startCase} />
            <TabButtonsWrapper>
              <RightSection actions={actions} />
            </TabButtonsWrapper>
          </Tabs.List>
          <AffiliationTabPanel
            value={DetailsPageTabs.GENERAL}
            content={
              mode === ViewMode.PREVIEW ? (
                <AffiliationGeneralTab affiliation={affiliation} />
              ) : (
                <AffiliationEditGeneralTab affiliationForm={affiliationForm} disableFields={!isSuperAdmin} />
              )
            }
          />
          <AffiliationTabPanel
            value={DetailsPageTabs.EMAIL_SETTINGS}
            content={
              mode === ViewMode.PREVIEW ? (
                <AffiliationEmailSettingsTab affiliation={affiliation} />
              ) : (
                <AffiliationEditEmailSettingsTab affiliationForm={affiliationForm} />
              )
            }
          />

          <AffiliationTabPanel
            value={DetailsPageTabs.MOBILE_APP}
            content={
              mode === ViewMode.PREVIEW ? (
                <AffiliationMobileAppTab affiliation={affiliation} />
              ) : (
                <AffiliationEditMobileAppTab affiliationForm={affiliationForm} disableFields={!isSuperAdmin} />
              )
            }
          />
        </Tabs>
      </div>
    </div>
  );
};

export default AffiliationLoaded;
