import traverse from 'json-schema-traverse';
import cloneDeep from 'lodash/cloneDeep';
import { IJsonSchemaSpec, IJsonSchemaProperty } from '@mediafellows/chipmunk';

import { chipmunk } from 'utils/chipmunk';
import { IFieldsRequiredAndOptionsSettings } from './types';

type ISchema = IJsonSchemaSpec & {
  required: string[];
  properties: IJsonSchemaProperty | ISchema;
  items: ISchema;
  anyOf?: ISchema[];
  type?: string;
};

export const loadAndAssignRefSchemas = async (schema: IJsonSchemaSpec): Promise<ISchema> => {
  const result = cloneDeep(schema);

  await traverse(result, {
    cb: {
      post: async ({ $ref }, jsonPtr, rootSchema, parentJsonPtr, parentKeyword, parentSchema, keyIndex) => {
        if ($ref && parentKeyword && result?.[parentKeyword]) {
          result[parentKeyword][keyIndex] = await chipmunk.spec($ref);
        }
      },
    },
  });

  return result as ISchema;
};

export function parseSchemaToFormData<T>(schema: ISchema): IFieldsRequiredAndOptionsSettings<T> {
  return Object.entries<ISchema | IJsonSchemaProperty>(schema.properties).reduce((acc, [key, value]) => {
    if (value.properties) {
      return {
        ...acc,
        [key]: parseSchemaToFormData(value as ISchema),
      };
    }

    if (value.items?.properties) {
      // tmp hack to support array of objects up to 10 items per array
      // see https://github.com/mediafellows/mfx-ui-admin-v3/issues/2188
      const itemValidation = parseSchemaToFormData(value.items as ISchema);
      const first10ItemsValidation = Array(10).fill(itemValidation);

      return { ...acc, [key]: first10ItemsValidation };
    }

    if ((value.items?.anyOf as IJsonSchemaSpec[])?.[0]?.properties) {
      const itemValidations = (value.items?.anyOf as IJsonSchemaSpec[]).map(parseSchemaToFormData);

      return { ...acc, [key]: { anyOf: itemValidations } };
    }

    const options = (value as IJsonSchemaProperty).enum || (value as IJsonSchemaProperty).items?.enum;
    const constantValue = (value as IJsonSchemaProperty).const;
    const isNumber = value.type === 'integer' || value.type?.includes('integer');
    const isEmptyValueNull = Boolean(value.type === 'null' || value.type?.includes('null'));

    return {
      ...acc,
      [key]: {
        required: Boolean(schema.required?.includes(key)),
        ...(isNumber ? { isValueNumber: true } : {}),
        ...(options ? { options } : {}),
        isEmptyValueNull,
        ...(constantValue ? { constantValue } : {}),
      },
    };
  }, {} as IFieldsRequiredAndOptionsSettings<T>);
}

// extract required and options information from schema
export async function extractFieldsSettingsFromSchema<T>(
  schema: IJsonSchemaSpec,
): Promise<IFieldsRequiredAndOptionsSettings<T>> {
  const schemaWithRefs = await loadAndAssignRefSchemas(schema);
  return parseSchemaToFormData<T>(schemaWithRefs);
}
