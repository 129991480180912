import { startCase } from 'lodash';
import { GroupTypes } from 'types';
import { flags } from 'utils/flags';

export const formatGroupName = (groupType = ''): string => {
  switch (groupType) {
    case GroupTypes.SELECTION:
      return 'Selection';
    case GroupTypes.MOBILE_APP_SYNC:
      return 'Mobile Selection';
    case GroupTypes.SCREENING_ROOM:
      return 'Screening room';
    default:
      return startCase(groupType?.replace('group/', ''));
  }
};

export const getHiddenGroupTypes = (): GroupTypes[] => {
  const hiddenGroupTypes: GroupTypes[] = [];
  if (flags.showEventFeature === false) hiddenGroupTypes.push(GroupTypes.EVENT);
  if (flags.showRecommendationFeature === false && flags.showAssetRecommendationsFeature)
    hiddenGroupTypes.push(GroupTypes.RECOMMENDATION);
  if (flags.showScreeningRoomFeature === false) hiddenGroupTypes.push(GroupTypes.SCREENING_ROOM);
  if (flags.showAccessPrivilegeFeature === false) hiddenGroupTypes.push(GroupTypes.ACCESS_PRIVILEGE);
  if (flags.showMobileSelectionsFeature === false) hiddenGroupTypes.push(GroupTypes.MOBILE_APP_SYNC);
  return hiddenGroupTypes;
};
