import React from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { LabelValuePair as Row, yn } from 'components/label-value-pair';

import { getPermittedAttribute, getIncludedAttribute } from 'utils/ui';

import { IAccessPrivilegeFormComponent } from './types';
import { parseDifferentContacts } from 'utils/apis/groups';
import { formatDate } from 'utils/date';

export const AccessPrivilegeGrantFormSummary: React.FC<IAccessPrivilegeFormComponent> = ({ form: { values } }) => {
  const contacts = parseDifferentContacts(values.contacts);
  const {
    name,
    access_level,
    permit_download,
    email_message,
    email_subject,
    product_ids,
    organization_ids,
    expires_at,
    delegates_access,
    include_descendants,
    include_future_descendants,
  } = values || {};
  return (
    <FormSectionWrapperV2 padded={false}>
      <Row label="Name" value={name} />
      <Row label="Access level" value={access_level} />
      <Row label="Products" value={product_ids.length} />
      <Row label="Contacts" value={contacts.users.length + contacts.selections_users_count} />
      <Row label="Organizations" value={organization_ids.length} />
      <Row label="Expires at" rawLabel value={formatDate(expires_at)} hideIfNoValue />
      <Row label="Descendant Products" value={getIncludedAttribute(include_descendants)} />
      <Row label="Future Descendant Products" value={getIncludedAttribute(include_future_descendants)} />
      <Row label="Products' Unpublished assets" rawLabel value={getIncludedAttribute(delegates_access)} />
      <Row label="Products' Assets Download" rawLabel value={getPermittedAttribute(permit_download)} />
      <Row label="Send notification email" value={yn(email_subject.length && email_message.length)} />
    </FormSectionWrapperV2>
  );
};
