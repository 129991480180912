import React from 'react';

import { LabelValuePair as Row } from 'components/label-value-pair';

import { getIncludedAttribute, getPermittedAttribute } from 'utils/ui';

import { ISmartGroupFormComponent } from 'types/smart-group';
import { parseDifferentContacts } from 'utils/apis/groups';
import { formatDate } from 'utils/date';
import { DataSectionStore } from 'store/data-section-store';
import { IContact, IProduct } from 'types';
// import { queryAllProducts } from 'utils/apis/product';
// import { getValidIds } from 'utils/general';
// import { searchAssets } from 'utils/apis/asset';
// import { useRemote } from 'utils/hooks';

export const SmartGroupFormSummary: React.FC<
  ISmartGroupFormComponent & {
    productDataSection: DataSectionStore<IProduct>;
    contactDataSection: DataSectionStore<IContact>;
    assetCountLoading: boolean;
    assetsCount: number;
  }
> = ({ contactDataSection, form: { values }, productDataSection, assetsCount, assetCountLoading }) => {
  const contacts = parseDifferentContacts(values.contacts);

  // const countAssets = useCallback(async () => {
  //   if (!isActiveTab) {
  //     return 0;
  //   }
  //   if (values.asset_selection_method === 'static') {
  //     return values.asset_ids?.length || 0;
  //   }
  //   if (!values.asset_filters?.length) {
  //     return 0;
  //   }

  //   let productIds = values.product_ids || [];
  //   if (values.product_selection_method === 'dynamic') {
  //     const products = await queryAllProducts({}, values.product_filters, 'id');
  //     productIds = getValidIds(products);
  //   }
  //   const filters: ISearchFilter[] = [['products.product_id', 'in', productIds], ...(values.asset_filters || [])];

  //   const result = await searchAssets({ per: 1, page: 1 }, filters, 'id');
  //   return result.pagination?.total_count || 0;
  // }, [isActiveTab, values]);

  // const [assetsCount, loadingAssetsCount] = useRemote(countAssets);
  return (
    <div className="d-flex flex-column">
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Name"
        value={values.name}
      />
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Access level"
        value={values.access_level}
      />
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Expires at:"
        value={formatDate(values.expires_at)}
        hideIfNoValue
      />
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Products"
        value={
          productDataSection.searchStore?.running
            ? 'Loading...'
            : values.product_selection_method === 'dynamic'
            ? productDataSection.searchStore.totalCount
            : values.product_ids?.length
        }
      />
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Descendant Products"
        hidden={values.product_selection_method === 'dynamic'}
        value={getIncludedAttribute(values.include_descendants)}
      />
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Future Descendant Products"
        hidden={values.product_selection_method === 'dynamic'}
        value={getIncludedAttribute(values.include_future_descendants)}
      />
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Assets"
        value={
          assetCountLoading
            ? 'Loading...'
            : values.asset_selection_method === 'dynamic'
            ? assetsCount
            : values.asset_ids?.length
        }
      />
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Assets Download"
        hidden={values.product_selection_method === 'dynamic'}
        value={getPermittedAttribute(values.permissions.includes('download'))}
      />
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Contacts"
        value={
          contactDataSection?.searchStore?.running
            ? 'Loading...'
            : values.contact_selection_method === 'dynamic'
            ? contactDataSection?.searchStore?.filters?.length && contactDataSection.searchStore.totalCount
            : (contacts?.users?.length || 0) + (contacts?.selections_users_count || 0)
        }
      />
    </div>
  );
};
