import { flags } from 'utils/flags';

export const oldAssetDetailsSchema = `
  access_level,
  access_change_jobs_count,
  scheduled_jobs_count,
  archive_number,
  aspect_ratio,
  asset_identifier,
  asset_type,
  author,
  barcode,
  caption,
  classification,
  comments,
  created_at,
  default_layer_id,
  default_layer {
    caption,
    copyright_notice,
    description,
    id,
    notes,
    photographer,
    talent_in_image,
    name,
    notes,
    news_date,
    news_location,
    language_id_approved,
  },
  asset_identifier,
  duration,
  effective_permissions,
  external_reference,
  file_name,
  file_size,
  file_status,
  format,
  geography_ids,
  group_ids,
  height,
  id,
  identifier_crid,
  identifier_eidr,
  identifier_isan,
  identifier_umid,
  identifier_uri,
  identifier_visan,
  ingest_notes,
  keywords,
  language_ids,
  language_id_approved,
  main_classification,
  md5,
  name,
  number_of_pages,
  owner_id,
  owner_organization_id,
  owner { division_ids, first_name, last_name, id},
  owner_organization { name },
  pdf_version,
  permissions,
  preview_image,
  preview_image_id,
  products,
  producer,
  protection_levels,
  published_at,
  resolution,
  sharable,
  status,
  stream_url,
  subject,
  subtitles,
  tags,
  title,
  type,
  updated_at,
  user_md5,
  width,
  classification_tag,
  ingest_error_message,
  burned_in_subtitles_language_id,
  storage { is_restorable },
  dubbed_language_id,
  division_ids,
`;

export const mm3AssetDetailsSchema = `
access_level,
access_change_jobs_count,
scheduled_jobs_count,
affiliation_id,
animated_preview_url,
artefacts,
audio_profile,
audio_profile_name,
classification,
created_at,
current_version_id,
deleted_at,
effective_access,
effective_access_level,
effective_file_name,
effective_permissions,
external_reference,
file_size,
file_status,
geography_ids,
id,
imported_from_watchfolder,
ingest_notes,
ingest_uuid,
main_classification,
mediainfo,
meta,
source_meta, 
name,
owner { division_ids, first_name, last_name, id},
division_ids,
owner_id,
owner_organization { name },
owner_organization_id,
parent,
parent_id,
permissions,
preview_image,
preview_image_id,
products,
products_count,
protection_levels,
published_at,
sharable,
skip_access_delegation,
source,
source_asset_ids,
status,
storage,
storage_id,
stream_url,
tags,
type,
updated_at,
available_since,`;

export const mm3AssetListSchema = `
access_level,
access_change_jobs_count,
scheduled_jobs_count,
affiliation_id,
animated_preview_url,
classification,
created_at,
current_version_id,
deleted_at,
effective_access,
effective_access_level,
effective_file_name,
effective_permissions,
external_reference,
file_size,
file_status,
geography_ids,
id,
main_classification,
meta,
name,
division_ids,
owner_id,
owner_organization_id,
parent,
parent_id,
permissions,
preview_image,
preview_image_id,
products,
products_count,
protection_levels,
published_at,
sharable,
skip_access_delegation,
source_asset_ids,
status,
storage,
storage_id,
stream_url,
tags,
type,
updated_at,
available_since,
source_meta`;

export const assetDetailsSchema = flags.isMm3Assets ? mm3AssetDetailsSchema : oldAssetDetailsSchema;

export const oldAssetListSchema = `
  access_level,
  access_change_jobs_count,
  scheduled_jobs_count,
  aspect_ratio,
  classification,
  created_at,
  default_layer_id,
  default_layer,
  deleted_at,
  duration,
  effective_permissions,
  permissions,
  file_name,
  file_size,
  file_status,
  format,
  height,
  id,
  group_ids,
  language_ids,
  main_classification,
  name,
  owner_id,
  division_ids,
  owner_organization_id,
  preview_image,
  preview_image_id,
  products_count,
  products,
  protection_levels,
  published_at,
  sharable,
  status,
  storage { is_restorable },
  stream_url,
  subtitles {id, purpose, storage_url, language_id, label},
  updated_at,
  width,
  dubbed_language_id,
`;

export const assetListSchema = flags.isMm3Assets ? mm3AssetListSchema : oldAssetListSchema;
export const productAssetSchema = `id, asset_type, product_id, asset_id, marketing_use, classification, sequence_number, asset { ${assetListSchema} }`;
