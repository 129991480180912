export const productListSchema = `
access_level,
ancestry_info,
created_at,
default_layer,
default_layer_id,
deleted_at,
display_title,
external_reference,
full_title,
id,
inherited_preview_image,
owner_id,
owner_organization_id,
parent_id,
permissions,
preview_image,
published_at,
scheduled_jobs_count,
sequence_number,
status,
title,
flat_sequence_number,
type,
meta,
permissions,
updated_at,
available_since,
workflow_status,
group_ids,
internal_reference,
division_ids,
default_layer {
  id,
  subtitle,
  title,
  product_id,
  meta
}
`;

export const productDetailsSchema = `${productListSchema}, owner_organization { name }, owner { division_ids, first_name, last_name, id}`;
