import { useCallback, useEffect, useState } from 'react';
import { ItemId, IScheduledJob } from 'types';

import {
  IAssetScheduledJobName,
  IAccessChangeScheduledForm,
  removeScheduledJob,
  loadScheduledJobs,
  createScheduledJobs,
} from 'utils/actions/asset/';

export const useScheduledJobs = <S extends IAccessChangeScheduledForm>(
  jobName: IAssetScheduledJobName,
  itemIds: ItemId[],
): [IScheduledJob[], (ItemId) => void, (data: S) => void] => {
  const [scheduledJobs, setScheduledJobs] = useState<IScheduledJob[]>([]);

  const refresh = useCallback(async () => {
    const remoteJobs = await loadScheduledJobs(jobName, itemIds);

    setScheduledJobs(remoteJobs);
  }, [jobName, itemIds, setScheduledJobs]);

  const removeJob = useCallback(
    async (id) => {
      await removeScheduledJob(id);
      setScheduledJobs(scheduledJobs.filter((job) => job.id !== id));
    },
    [setScheduledJobs, scheduledJobs],
  );

  const createJob = useCallback(
    async (data: S) => {
      try {
        const createdJobs = await createScheduledJobs(jobName, data);
        setScheduledJobs(createdJobs ? [...scheduledJobs, ...createdJobs] : scheduledJobs);
      } catch (err) {
        throw err;
      }
    },
    [setScheduledJobs, jobName, scheduledJobs],
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [scheduledJobs, removeJob, createJob];
};
