import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { Button } from '@mantine/core';

import { Model } from 'helpers/filters/types';
import { isValid, useForm } from 'helpers/form';
import { useStore } from 'store';
import { IContact, IImContact, ItemId } from 'types';
import { useWizardSteps, Wizard, WizardStep } from 'components/wizard';
import { Page } from 'components/layout';
import { ToastError } from 'components/toast';
import { contactInitialValues } from 'pages/upsert-contact/initiale-values';
import { StepAbout } from 'pages/upsert-contact/step-about';
import { FormActionSection } from 'components/form-submit-section';
import { firstStepValidationKeys } from 'pages/upsert-contact/validation-keys';
import { staticContext } from 'pages/upsert-contact/custom-context';

import { MantineIcon } from 'utils/ui/icon';
import { ArrowLeft } from 'blueprint5-icons';

import { StepContact } from './step-contact';
import { StepDone } from './step-done';
import { createUser } from './api';
import { StepIds } from './utils';

import './style.scss';

export interface IContactFields extends Partial<IContact> {
  'address.street'?: string;
  'address.supplement'?: string;
  'address.zip_code'?: string;
  'address.city'?: string;
  'address.country_id'?: string;

  im_contacts?: IImContact[];
}

const additionalContextConfig = [
  { prefix: 'im_contacts', model: 'um.user/im_contact' },
  { prefix: 'phones', model: 'um.user/phone' },
  { prefix: 'address', model: 'um.user/address' },
  { prefix: 'social_media_profiles', model: 'um.user/social_media_profile' },
];

const UpsertContact: React.FC<{ organizationId?: ItemId; modalView?: boolean }> = observer((props) => {
  const { organizationId, modalView } = props;
  const { toastStore } = useStore();
  const [newContact, setNewContact] = useState<IContact[]>([]);
  const [isAboutStepDisabled, setAboutStepDisabled] = useState(false);

  const handleSubmit = useCallback(
    async (contact: IContactFields): Promise<void> => {
      try {
        const newContact = await createUser(contact);
        setNewContact([newContact]);
      } catch (err) {
        toastStore.error(<ToastError error={err} />);
      }
    },
    [toastStore],
  );

  const handleContactSubmit = useCallback(
    (data: IContactFields, valid: boolean) => {
      if (valid) {
        return handleSubmit(data);
      }
    },
    [handleSubmit],
  );

  const contactForm = useForm<IContactFields>(
    { ...contactInitialValues, organization_id: organizationId as string },
    Model.CONTACTS,
    handleContactSubmit,
    staticContext,
    additionalContextConfig,
  );

  const steps = [
    { id: StepIds.Step1, disabled: !isValid(contactForm.formData, firstStepValidationKeys) },
    { id: StepIds.Step2, disabled: !contactForm.valid, action: contactForm.onSubmit, nextButtonTitle: 'Create' },
    { id: StepIds.Step3 },
  ];

  const { nextButton, backButton, currentStepId, setCurrentStepId, isStepDisabled } = useWizardSteps(steps);

  useEffect(() => {
    if (newContact.length) {
      setCurrentStepId(StepIds.Step3);
    }
  }, [setCurrentStepId, newContact]);

  const wizardContent = (
    <Wizard
      selectedStepId={currentStepId}
      onChange={setCurrentStepId}
      footerPadded={false}
      fitInParent
      footer={
        <FormActionSection
          className={cx('d-flex justify-content-between w-100', { 'd-none': currentStepId === StepIds.Step3 })}
        >
          <Button
            className={cx('m-0', { invisible: backButton.hide })}
            onClick={backButton.action}
            variant="subtle"
            leftSection={<MantineIcon icon={<ArrowLeft />} />}
          >
            {backButton.title}
          </Button>
          <Button
            className="m-0"
            variant="primary"
            onClick={nextButton.action}
            disabled={nextButton.disabled || (currentStepId === StepIds.Step1 && isAboutStepDisabled)}
          >
            {nextButton.title}
          </Button>
        </FormActionSection>
      }
    >
      <WizardStep
        id={StepIds.Step1}
        title="About"
        icon="person"
        disabled={isStepDisabled(StepIds.Step1) || isAboutStepDisabled}
        panel={
          <StepAbout
            form={contactForm}
            modalView={modalView}
            organizationId={organizationId}
            setStepState={setAboutStepDisabled}
          />
        }
      />
      <WizardStep
        id={StepIds.Step2}
        title="Contact"
        icon="phone"
        disabled={isStepDisabled(StepIds.Step2)}
        panel={<StepContact form={contactForm} />}
      />
      <WizardStep
        id={StepIds.Step3}
        title="Done"
        icon="tick"
        disabled
        panel={
          <StepDone
            form={contactForm}
            newContact={newContact}
            setCurrentStepId={setCurrentStepId}
            modalView={modalView}
          />
        }
      />
    </Wizard>
  );
  return <>{modalView ? <>{wizardContent}</> : <Page title={'Create New Contact'}>{wizardContent}</Page>}</>;
});

export default UpsertContact;
