import React, { useCallback, useMemo } from 'react';
import { Select, SelectProps } from '@mantine/core';
import { toNumber } from 'lodash';

import { getFieldPlaceholder } from 'helpers/form/fields/helpers';
import { IFieldData, IFieldHandlers } from 'helpers/form/types';

import './style.scss';

type IOptionValue = string | number | null | undefined;

export interface IFormSelectOption {
  value: IOptionValue;
  label: string;
  disabled?: boolean;
}

interface ISelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

type IFormatLabelHelper = (label: string | number | undefined) => string | number;
export interface IFormSelectProps
  extends Omit<SelectProps, 'value' | 'onChange' | 'onBlur'>,
    IFieldData<IOptionValue, (number | string | IFormSelectOption)[]>,
    IFieldHandlers<IOptionValue> {
  name: string;
  label?: string;
  labelComponent?: React.ReactNode;
  large?: boolean;
  inline?: boolean;
  disabled?: boolean;
  placeholder?: string;
  showPositiveValidation?: boolean;
  formatLabel?: IFormatLabelHelper;
  emptyValue?: IOptionValue;
  emptyValueIsUndefined?: boolean;
  noEmptyOption?: boolean;
  withinPortal?: boolean;
  isValueNumber?: boolean;
  isEmptyValueNull?: boolean;
}

const preparePropOptions = (
  options?: (IFormSelectOption | string | number)[],
  formatLabel?: IFormatLabelHelper,
): ISelectOption[] => {
  const props = (options || []).map((op) =>
    typeof op === 'object'
      ? { label: op.label, value: op.value?.toString() || '', disabled: op?.disabled }
      : { label: '' + (formatLabel?.(op) || op), value: op.toString() || '' },
  );
  return props as ISelectOption[];
};

export const FormSelect: React.FC<IFormSelectProps> = (props) => {
  const {
    showPositiveValidation,
    name,
    label = '',
    options,
    disabled,
    placeholder,
    isValueNumber,
    onChange,
    onBlur,
    validation,
    touched,
    value,
    required,
    formatLabel,
    emptyValue = '',
    emptyValueIsUndefined = false,
    size = 'md',
    withinPortal = false,
    labelComponent,
    isEmptyValueNull,
    clearable,
  } = props;

  const formattedPlaceholder = getFieldPlaceholder({ placeholder, disabled, defaultPlaceholder: `Select ${label}` });
  const showError = Boolean(touched && !validation?.valid && validation?.errorMessage);
  const showValid = Boolean(showPositiveValidation && touched && validation?.valid);

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const handleChange = useCallback(
    (v: IOptionValue) => {
      // select does not support numbers as values
      const value = isValueNumber ? toNumber(v) : v;
      const isEmpty = value == '' || (!value && value !== 0);

      if (!isEmpty) {
        onChange?.({ [name]: value });
      } else {
        onChange?.({ [name]: emptyValueIsUndefined ? undefined : isEmptyValueNull ? null : emptyValue });
      }

      handleBlur();
    },
    [isValueNumber, handleBlur, onChange, name, emptyValueIsUndefined, isEmptyValueNull, emptyValue],
  );

  const preparedOptions = useMemo(() => preparePropOptions(options, formatLabel), [formatLabel, options]);
  const formattedValue = value !== undefined && value !== null ? value.toString() : value;
  return (
    <div>
      <Select
        label={labelComponent || label}
        withAsterisk={labelComponent ? false : required}
        placeholder={formattedPlaceholder}
        name={name}
        data={preparedOptions}
        value={formattedValue}
        onChange={handleChange}
        onBlur={handleBlur}
        searchable
        nothingFoundMessage="Nothing found."
        error={showValid || showError ? validation?.errorMessage : undefined}
        disabled={disabled}
        clearable={required ? false : clearable ?? true}
        clearButtonProps={{ size: 'md' }}
        allowDeselect={!required}
        withCheckIcon={false}
        comboboxProps={{ withinPortal, position: 'bottom', middlewares: { flip: false, shift: false }, offset: 3 }}
        size={size}
        scrollAreaProps={{ type: 'always' }}
        autoComplete={name}
      />
    </div>
  );
};

export default FormSelect;
