import React from 'react';

import { useFutureDescendantsFlag } from 'components/access-privilege';
import { FormProducts, FormCheckbox } from 'helpers/form';
import { Text } from 'components/text';
import { IGuildFormComponent } from './types';

import './styles.scss';

export const keysProducts = ['product_ids', 'include_descendants', 'delegates_access', 'permit_download'];

export const GuildFormProducts: React.FC<IGuildFormComponent> = ({ form }) => {
  useFutureDescendantsFlag(form);
  return (
    <div className="d-flex flex-column h-100">
      <div className="guild-form-products">
        <FormProducts
          name="product_ids"
          label="Products"
          {...form.formData.product_ids}
          {...form.handlers}
          fitInParent
        />
      </div>
      <Text>
        All products will be added with their full ancestry (necessary for the correct display of privileged access on
        the client-facing site).
      </Text>
      <FormCheckbox
        name="include_descendants"
        label="Include descendant product(s)"
        {...form.formData.include_descendants}
        {...form.handlers}
        className="mb-0"
      />

      <FormCheckbox
        label="Include the Products’ unpublished Assets"
        name="delegates_access"
        className="mb-0"
        {...form.formData.delegates_access}
        {...form.handlers}
      />

      <FormCheckbox
        label="Permit asset download"
        name="permit_download"
        className="mb-0"
        {...form.formData.permit_download}
        {...form.handlers}
      />
    </div>
  );
};
