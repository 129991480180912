export const supportedLanguages = {
  aws: {
    source_languages: [
      { id: 'af', label: 'Afrikaans' },
      { id: 'sq', label: 'Albanian' },
      { id: 'am', label: 'Amharic' },
      { id: 'ar', label: 'Arabic' },
      { id: 'hy', label: 'Armenian' },
      { id: 'az', label: 'Azerbaijani' },
      { id: 'bn', label: 'Bengali' },
      { id: 'bs', label: 'Bosnian' },
      { id: 'bg', label: 'Bulgarian' },
      { id: 'ca', label: 'Catalan' },
      { id: 'zh', label: 'Chinese (Simplified)' },
      { id: 'zh-TW', label: 'Chinese (Traditional)' },
      { id: 'hr', label: 'Croatian' },
      { id: 'cs', label: 'Czech' },
      { id: 'da', label: 'Danish' },
      { id: 'fa-AF', label: 'Dari' },
      { id: 'nl', label: 'Dutch' },
      { id: 'en', label: 'English' },
      { id: 'et', label: 'Estonian' },
      { id: 'fa', label: 'Farsi (Persian)' },
      { id: 'tl', label: 'Filipino, Tagalog' },
      { id: 'fi', label: 'Finnish' },
      { id: 'fr', label: 'French' },
      { id: 'fr-CA', label: 'French (Canada)' },
      { id: 'ka', label: 'Georgian' },
      { id: 'de', label: 'German' },
      { id: 'el', label: 'Greek' },
      { id: 'gu', label: 'Gujarati' },
      { id: 'ht', label: 'Haitian Creole' },
      { id: 'ha', label: 'Hausa' },
      { id: 'he', label: 'Hebrew' },
      { id: 'hi', label: 'Hindi' },
      { id: 'hu', label: 'Hungarian' },
      { id: 'is', label: 'Icelandic' },
      { id: 'id', label: 'Indonesian' },
      { id: 'ga', label: 'Irish' },
      { id: 'it', label: 'Italian' },
      { id: 'ja', label: 'Japanese' },
      { id: 'kn', label: 'Kannada' },
      { id: 'kk', label: 'Kazakh' },
      { id: 'ko', label: 'Korean' },
      { id: 'lv', label: 'Latvian' },
      { id: 'lt', label: 'Lithuanian' },
      { id: 'mk', label: 'Macedonian' },
      { id: 'ms', label: 'Malay' },
      { id: 'ml', label: 'Malayalam' },
      { id: 'mt', label: 'Maltese' },
      { id: 'mr', label: 'Marathi' },
      { id: 'mn', label: 'Mongolian' },
      { id: 'no', label: 'Norwegian (Bokmål)' },
      { id: 'ps', label: 'Pashto' },
      { id: 'pl', label: 'Polish' },
      { id: 'pt', label: 'Portuguese (Brazil)' },
      { id: 'pt-PT', label: 'Portuguese (Portugal)' },
      { id: 'pa', label: 'Punjabi' },
      { id: 'ro', label: 'Romanian' },
      { id: 'ru', label: 'Russian' },
      { id: 'sr', label: 'Serbian' },
      { id: 'si', label: 'Sinhala' },
      { id: 'sk', label: 'Slovak' },
      { id: 'sl', label: 'Slovenian' },
      { id: 'so', label: 'Somali' },
      { id: 'es', label: 'Spanish' },
      { id: 'es-MX', label: 'Spanish (Mexico)' },
      { id: 'sw', label: 'Swahili' },
      { id: 'sv', label: 'Swedish' },
      { id: 'ta', label: 'Tamil' },
      { id: 'te', label: 'Telugu' },
      { id: 'th', label: 'Thai' },
      { id: 'tr', label: 'Turkish' },
      { id: 'uk', label: 'Ukrainian' },
      { id: 'ur', label: 'Urdu' },
      { id: 'uz', label: 'Uzbek' },
      { id: 'vi', label: 'Vietnamese' },
      { id: 'cy', label: 'Welsh' },
    ],
    target_languages: [
      { id: 'af', label: 'Afrikaans' },
      { id: 'sq', label: 'Albanian' },
      { id: 'am', label: 'Amharic' },
      { id: 'ar', label: 'Arabic' },
      { id: 'hy', label: 'Armenian' },
      { id: 'az', label: 'Azerbaijani' },
      { id: 'bn', label: 'Bengali' },
      { id: 'bs', label: 'Bosnian' },
      { id: 'bg', label: 'Bulgarian' },
      { id: 'ca', label: 'Catalan' },
      { id: 'zh', label: 'Chinese (Simplified)' },
      { id: 'zh-TW', label: 'Chinese (Traditional)' },
      { id: 'hr', label: 'Croatian' },
      { id: 'cs', label: 'Czech' },
      { id: 'da', label: 'Danish' },
      { id: 'fa-AF', label: 'Dari' },
      { id: 'nl', label: 'Dutch' },
      { id: 'en', label: 'English' },
      { id: 'et', label: 'Estonian' },
      { id: 'fa', label: 'Farsi (Persian)' },
      { id: 'tl', label: 'Filipino, Tagalog' },
      { id: 'fi', label: 'Finnish' },
      { id: 'fr', label: 'French' },
      { id: 'fr-CA', label: 'French (Canada)' },
      { id: 'ka', label: 'Georgian' },
      { id: 'de', label: 'German' },
      { id: 'el', label: 'Greek' },
      { id: 'gu', label: 'Gujarati' },
      { id: 'ht', label: 'Haitian Creole' },
      { id: 'ha', label: 'Hausa' },
      { id: 'he', label: 'Hebrew' },
      { id: 'hi', label: 'Hindi' },
      { id: 'hu', label: 'Hungarian' },
      { id: 'is', label: 'Icelandic' },
      { id: 'id', label: 'Indonesian' },
      { id: 'ga', label: 'Irish' },
      { id: 'it', label: 'Italian' },
      { id: 'ja', label: 'Japanese' },
      { id: 'kn', label: 'Kannada' },
      { id: 'kk', label: 'Kazakh' },
      { id: 'ko', label: 'Korean' },
      { id: 'lv', label: 'Latvian' },
      { id: 'lt', label: 'Lithuanian' },
      { id: 'mk', label: 'Macedonian' },
      { id: 'ms', label: 'Malay' },
      { id: 'ml', label: 'Malayalam' },
      { id: 'mt', label: 'Maltese' },
      { id: 'mr', label: 'Marathi' },
      { id: 'mn', label: 'Mongolian' },
      { id: 'no', label: 'Norwegian (Bokmål)' },
      { id: 'ps', label: 'Pashto' },
      { id: 'pl', label: 'Polish' },
      { id: 'pt', label: 'Portuguese (Brazil)' },
      { id: 'pt-PT', label: 'Portuguese (Portugal)' },
      { id: 'pa', label: 'Punjabi' },
      { id: 'ro', label: 'Romanian' },
      { id: 'ru', label: 'Russian' },
      { id: 'sr', label: 'Serbian' },
      { id: 'si', label: 'Sinhala' },
      { id: 'sk', label: 'Slovak' },
      { id: 'sl', label: 'Slovenian' },
      { id: 'so', label: 'Somali' },
      { id: 'es', label: 'Spanish' },
      { id: 'es-MX', label: 'Spanish (Mexico)' },
      { id: 'sw', label: 'Swahili' },
      { id: 'sv', label: 'Swedish' },
      { id: 'ta', label: 'Tamil' },
      { id: 'te', label: 'Telugu' },
      { id: 'th', label: 'Thai' },
      { id: 'tr', label: 'Turkish' },
      { id: 'uk', label: 'Ukrainian' },
      { id: 'ur', label: 'Urdu' },
      { id: 'uz', label: 'Uzbek' },
      { id: 'vi', label: 'Vietnamese' },
      { id: 'cy', label: 'Welsh' },
    ],
  },
  deepl: {
    source_languages: [
      { id: 'AR', label: 'Arabic' },
      { id: 'BG', label: 'Bulgarian' },
      { id: 'CS', label: 'Czech' },
      { id: 'DA', label: 'Danish' },
      { id: 'DE', label: 'German' },
      { id: 'EL', label: 'Greek' },
      { id: 'EN', label: 'English (all English variants)' },
      { id: 'ES', label: 'Spanish' },
      { id: 'ET', label: 'Estonian' },
      { id: 'FI', label: 'Finnish' },
      { id: 'FR', label: 'French' },
      { id: 'HU', label: 'Hungarian' },
      { id: 'ID', label: 'Indonesian' },
      { id: 'IT', label: 'Italian' },
      { id: 'JA', label: 'Japanese' },
      { id: 'KO', label: 'Korean' },
      { id: 'LT', label: 'Lithuanian' },
      { id: 'LV', label: 'Latvian' },
      { id: 'NB', label: 'Norwegian Bokmål' },
      { id: 'NL', label: 'Dutch' },
      { id: 'PL', label: 'Polish' },
      { id: 'PT', label: 'Portuguese (all Portuguese variants)' },
      { id: 'RO', label: 'Romanian' },
      { id: 'RU', label: 'Russian' },
      { id: 'SK', label: 'Slovak' },
      { id: 'SL', label: 'Slovenian' },
      { id: 'SV', label: 'Swedish' },
      { id: 'TR', label: 'Turkish' },
      { id: 'UK', label: 'Ukrainian' },
      { id: 'ZH', label: 'Chinese (all Chinese variants)' },
    ],
    target_languages: [
      { id: 'AR', label: 'Arabic' },
      { id: 'BG', label: 'Bulgarian' },
      { id: 'CS', label: 'Czech' },
      { id: 'DA', label: 'Danish' },
      { id: 'DE', label: 'German' },
      { id: 'EL', label: 'Greek' },
      {
        id: 'EN',
        label: 'English (unspecified variant for backward compatibility; please select EN-GB or EN-US instead)',
      },
      { id: 'EN-GB', label: 'English (British)' },
      { id: 'EN-US', label: 'English (American)' },
      { id: 'ES', label: 'Spanish' },
      { id: 'ET', label: 'Estonian' },
      { id: 'FI', label: 'Finnish' },
      { id: 'FR', label: 'French' },
      { id: 'HU', label: 'Hungarian' },
      { id: 'ID', label: 'Indonesian' },
      { id: 'IT', label: 'Italian' },
      { id: 'JA', label: 'Japanese' },
      { id: 'KO', label: 'Korean' },
      { id: 'LT', label: 'Lithuanian' },
      { id: 'LV', label: 'Latvian' },
      { id: 'NB', label: 'Norwegian Bokmål' },
      { id: 'NL', label: 'Dutch' },
      { id: 'PL', label: 'Polish' },
      {
        id: 'PT',
        label: 'Portuguese (unspecified variant for backward compatibility; please select PT-BR or PT-PT instead)',
      },
      { id: 'PT-BR', label: 'Portuguese (Brazilian)' },
      { id: 'PT-PT', label: 'Portuguese (all Portuguese variants excluding Brazilian Portuguese)' },
      { id: 'RO', label: 'Romanian' },
      { id: 'RU', label: 'Russian' },
      { id: 'SK', label: 'Slovak' },
      { id: 'SL', label: 'Slovenian' },
      { id: 'SV', label: 'Swedish' },
      { id: 'TR', label: 'Turkish' },
      { id: 'UK', label: 'Ukrainian' },
      {
        id: 'ZH',
        label: 'Chinese (unspecified variant for backward compatibility; please select ZH-HANS or ZH-HANT instead)',
      },
      { id: 'ZH-HANS', label: 'Chinese (simplified)' },
      { id: 'ZH-HANT', label: 'Chinese (traditional)' },
    ],
  },
  xl8: {
    source_languages: [
      { id: 'ar', label: 'Arabic' },
      { id: 'bg', label: 'Bulgarian' },
      { id: 'bs', label: 'Bosnian' },
      { id: 'cs', label: 'Czech' },
      { id: 'da', label: 'Danish' },
      { id: 'de', label: 'German' },
      { id: 'el', label: 'Greek' },
      { id: 'en', label: 'English' },
      { id: 'es', label: 'Spanish' },
      { id: 'es-419', label: 'Spanish | Latin America' },
      { id: 'et', label: 'Estonian' },
      { id: 'fi', label: 'Finnish' },
      { id: 'tl', label: 'Tagalog ⚠️ XL8 uses fil.' },
      { id: 'fr', label: 'French' },
      { id: 'he', label: 'Hebrew' },
      { id: 'hi', label: 'Hindi' },
      { id: 'hr', label: 'Croatian' },
      { id: 'hu', label: 'Hungarian' },
      { id: 'id', label: 'Indonesian' },
      { id: 'it', label: 'Italian' },
      { id: 'ja', label: 'Japanese' },
      { id: 'ko', label: 'Korean' },
      { id: 'lt', label: 'Lithuanian' },
      { id: 'lv', label: 'Latvian' },
      { id: 'ms', label: 'Malay' },
      { id: 'my', label: 'Burmese' },
      { id: 'my-zawgyi', label: 'Burmese' },
      { id: 'nl', label: 'Dutch' },
      { id: 'no', label: 'Norwegian' },
      { id: 'pl', label: 'Polish' },
      { id: 'pt', label: 'Portuguese' },
      { id: 'pt-br', label: 'Portuguese | Brazil ⚠️ XL8 uses pt-BR.' },
      { id: 'ro', label: 'Romanian' },
      { id: 'ru', label: 'Russian' },
      { id: 'sk', label: 'Slovak' },
      { id: 'sl', label: 'Slovenian' },
      { id: 'sq', label: 'Albanian' },
      { id: 'sr', label: 'Serbian' },
      { id: 'sv', label: 'Swedish' },
      { id: 'th', label: 'Thai' },
      { id: 'tl', label: 'Tagalog' },
      { id: 'tr', label: 'Turkish' },
      { id: 'uk', label: 'Ukrainian' },
      { id: 'vi', label: 'Vietnamese' },
      { id: 'zh-Hans', label: 'Chinese | Simplified Chinese' },
      { id: 'zh-Hant', label: 'Chinese | Traditional Chinese' },
    ],
    target_languages: [
      { id: 'ar', label: 'Arabic' },
      { id: 'bg', label: 'Bulgarian' },
      { id: 'bs', label: 'Bosnian' },
      { id: 'cs', label: 'Czech' },
      { id: 'da', label: 'Danish' },
      { id: 'de', label: 'German' },
      { id: 'el', label: 'Greek' },
      { id: 'en', label: 'English' },
      { id: 'es', label: 'Spanish' },
      { id: 'es-419', label: 'Spanish | Latin America' },
      { id: 'et', label: 'Estonian' },
      { id: 'fi', label: 'Finnish' },
      { id: 'tl', label: 'Tagalog ⚠️ XL8 uses fil.' },
      { id: 'fr', label: 'French' },
      { id: 'he', label: 'Hebrew' },
      { id: 'hi', label: 'Hindi' },
      { id: 'hr', label: 'Croatian' },
      { id: 'hu', label: 'Hungarian' },
      { id: 'id', label: 'Indonesian' },
      { id: 'it', label: 'Italian' },
      { id: 'ja', label: 'Japanese' },
      { id: 'ko', label: 'Korean' },
      { id: 'lt', label: 'Lithuanian' },
      { id: 'lv', label: 'Latvian' },
      { id: 'ms', label: 'Malay' },
      { id: 'my', label: 'Burmese' },
      { id: 'my-zawgyi', label: 'Burmese' },
      { id: 'nl', label: 'Dutch' },
      { id: 'no', label: 'Norwegian' },
      { id: 'pl', label: 'Polish' },
      { id: 'pt', label: 'Portuguese' },
      { id: 'pt-br', label: 'Portuguese | Brazil ⚠️ XL8 uses pt-BR.' },
      { id: 'ro', label: 'Romanian' },
      { id: 'ru', label: 'Russian' },
      { id: 'sk', label: 'Slovak' },
      { id: 'sl', label: 'Slovenian' },
      { id: 'sq', label: 'Albanian' },
      { id: 'sr', label: 'Serbian' },
      { id: 'sv', label: 'Swedish' },
      { id: 'th', label: 'Thai' },
      { id: 'tl', label: 'Tagalog' },
      { id: 'tr', label: 'Turkish' },
      { id: 'uk', label: 'Ukrainian' },
      { id: 'vi', label: 'Vietnamese' },
      { id: 'zh-Hans', label: 'Chinese | Simplified Chinese' },
      { id: 'zh-Hant', label: 'Chinese | Traditional Chinese' },
    ],
  },
};
