import React, { useMemo } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { useDataSectionStore } from 'store/hooks';

import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';
import { ShowcaseActionsList } from 'components/action';

import useSelectedOption from 'utils/hooks/selected-option';
import { pluralWordInflect } from 'utils/general';
import { getPreviewImage, getEntityType } from 'utils/list-item';
import { IUseActionsOptionParam } from 'utils/actions';
import { Classes } from 'utils/ui';

import { IExtendedGenericList } from 'components/website-data-section/utils';
import { ActionsMenu } from 'components/action/utils';
import { IListItem, IThumbnailImage } from 'types';

import './style.scss';

export const noPreviewImages = [
  {
    image: undefined,
    assetType: undefined,
  },
];

export const getThumbnailImages = (items?: IListItem[]): IThumbnailImage[] => {
  if (!items?.length) {
    return noPreviewImages;
  }

  return items.map((item) => {
    const previewImage = getPreviewImage(item);
    const assetType = getEntityType(item, 'entity');

    return { image: previewImage?.url, assetType };
  });
};

interface IWebsiteDataSectionItemProps {
  showcase: IExtendedGenericList;
  handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  options: IUseActionsOptionParam;
  setActiveShowcaseItems?;
  activeShowcaseItems?;
}

export const WebsiteDataSectionItem: React.FC<IWebsiteDataSectionItemProps> = observer(
  ({ showcase, options, handleClick }) => {
    const { id, name, access_level, listItems: items, itemsCount } = showcase;

    const { getIsActive } = useDataSectionStore();

    const thumbnailImages = useMemo(() => getThumbnailImages(items), [items]);
    const selectedOption = useSelectedOption({ value: access_level });

    return (
      <div
        className={cx('website-list-item', {
          'entity-list-item--active': getIsActive(id),
        })}
        data-id={id}
        onClick={handleClick}
      >
        <div
          className={cx('website-list-item__access-level', {
            [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
          })}
        ></div>
        <div className="entity-list-item-row__multi-thumb">
          <MemoizedMultiThumbnail thumbnailImages={thumbnailImages} />
        </div>
        <div className="text-truncate text-nowrap">
          <div className="d-flex justify-content-between selections-list-item__title">
            <div className="text-nowrap text-truncate">{name}</div>

            <ActionsMenu component={ShowcaseActionsList} items={[showcase]} options={options} />
          </div>
          <div className={`selections-list-item__row--assets ${Classes.TEXT_MUTED}`}>
            {pluralWordInflect('item', itemsCount || 0)}
          </div>
        </div>
      </div>
    );
  },
);
