import React, { useMemo, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Stepper } from '@mantine/core';

import { useMm3Form } from 'helpers/form';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';
import { Model } from 'helpers/filters/types';
import { useDialogStore } from 'store/hooks';
import { IWebsitePageValues } from 'types';

import ShowcaseFormFirstStep from 'components/showcase-form/showcase-form';
import CollectionFormFirstStep from 'components/showcase-form/collection-form';
import AdvertisementFormFirstStep from 'components/showcase-form/advertisement-form';

import {
  IGenericListCollectionForm,
  customShowcaseFormValidator,
  customCollectionFormValidator,
  getShowcaseInitialValues,
  getCollectionInitialValues,
  getAdvertisementInitialValues,
  IGenericListAdvertisementForm,
} from 'components/showcase-form/utils';
import ShowcaseFormItemsSelector from './showcase-items-form';
import { IModalSize } from 'components/dialogs/types';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

enum Steps {
  Step1,
  Step2,
}

type IFormConfig = {
  title: string;
  FirstStepComponent: React.FC<
    IUseMm3FormReturn<UmGenericListShowcase | IGenericListCollectionForm> & {
      hideNextButton?: boolean;
      section?: IWebsitePageValues;
    }
  >;
};

const ENTITY_CONFIG: Record<string, IFormConfig> = {
  [IWebsitePageValues.COLLECTIONS]: {
    title: 'Collection',
    FirstStepComponent: CollectionFormFirstStep,
  },
  [IWebsitePageValues.ADVERTISEMENTS]: {
    title: 'Advertisement',
    FirstStepComponent: AdvertisementFormFirstStep,
  },
  default: {
    title: 'Showcase',
    FirstStepComponent: ShowcaseFormFirstStep,
  },
};

const CreateShowcase: React.FC<{ section: IWebsitePageValues }> = observer(({ section }) => {
  const [currentStep, setCurrentStep] = useState(Steps.Step1);

  const handleNext = useCallback(() => {
    setCurrentStep(Steps.Step2);
  }, []);

  const goBack = useCallback(() => {
    setCurrentStep(Steps.Step1);
  }, []);

  const config = ENTITY_CONFIG[section] || ENTITY_CONFIG.default;
  const { title, FirstStepComponent } = config;

  const showcaseForm = useMm3Form<UmGenericListShowcase>(
    getShowcaseInitialValues(section),
    Model.SHOWCASES,
    handleNext,
    customShowcaseFormValidator,
  );

  const collectionForm = useMm3Form<IGenericListCollectionForm>(
    getCollectionInitialValues(),
    Model.COLLECTIONS,
    handleNext,
    customCollectionFormValidator,
  );

  const advertisementForm = useMm3Form<IGenericListAdvertisementForm>(
    getAdvertisementInitialValues(),
    Model.ADVERTISEMENTS,
    handleNext,
  );

  const currentForm = useMemo(() => {
    switch (section) {
      case IWebsitePageValues.COLLECTIONS:
        return collectionForm;
      case IWebsitePageValues.ADVERTISEMENTS:
        return advertisementForm;
      default:
        return showcaseForm;
    }
  }, [section, collectionForm, advertisementForm, showcaseForm]);

  const firstStepPanel = useMemo(
    () => <FirstStepComponent {...currentForm} section={section} />,
    [FirstStepComponent, currentForm, section],
  );

  return (
    <Stepper active={currentStep} onStepClick={setCurrentStep}>
      <Stepper.Step key={Steps.Step1} label={title}>
        {firstStepPanel}
      </Stepper.Step>
      <Stepper.Step key={Steps.Step2} label="Items" allowStepSelect={Boolean(currentForm.valid)}>
        <ShowcaseFormItemsSelector showcaseForm={currentForm} goBack={goBack} entityType={title} />
      </Stepper.Step>
    </Stepper>
  );
});

export const useCreateShowcase = ({ title, section }: { title: string; section: IWebsitePageValues }): (() => void) => {
  const { openModal } = useDialogStore();

  return () => openModal({ title, size: IModalSize.S, body: () => <CreateShowcase section={section} /> });
};

export default CreateShowcase;
