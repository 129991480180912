import { IAsset, IAssetPresetFields, UserRoles } from 'types';
import { parseObjectToKeyPath } from 'utils/payload';
import { IAssetFormFields } from './sections';

export const getAssetFormFields = (fields: IAsset | IAssetFormFields | IAssetPresetFields): IAssetFormFields =>
  parseObjectToKeyPath(fields, [
    'permissions',
    'protection_levels',
    'tags',
    'language_ids',
    'geography_ids',
  ]) as IAssetFormFields;

const allowedAmWriteProtectedAttributesRoles = [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.MANAGER];
export const hasUserRoleAmWriteProtectedAttributesPower = (role_name: string): boolean =>
  !!allowedAmWriteProtectedAttributesRoles.includes(role_name as UserRoles);
