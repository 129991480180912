import { chipmunk, IResult } from 'utils/chipmunk';
import type { IGuild } from 'components/guilds';
import { uniq } from 'lodash';

import {
  addProductToGroup,
  addParentProductsToGroup,
  addDifferentContactEntitiesToGroup,
  addOrganizationsToGroup,
  loadGroupsUsers,
  loadGroupsOrganizationsUsers,
  addAssetToGroup,
} from 'utils/apis/groups';
import { ItemId, GroupTypes } from 'types';
import { Model } from 'helpers/filters/types';
import { guildDetailsSchema } from 'utils/schemas';

export const createGuild = async (values: IGuild): Promise<IResult> => {
  return chipmunk.run(async ({ action }) => {
    const {
      include_descendants,
      organization_ids,
      product_ids,
      asset_ids,
      subAssetIds,
      contacts,
      delegates_access,
      permit_download,
      permitAssetDownload,
      ...formData
    } = values;
    const result = await action(Model.GROUPS, 'create', {
      body: {
        type: GroupTypes.GUILD,
        ...formData,
      },
    });
    const { object: group } = result;
    const group_id = group.id;

    const assetsPromise = addAssetToGroup({
      group_id,
      item_ids: uniq([...(asset_ids || []), ...(subAssetIds || [])]) as ItemId[],
      permissions: permitAssetDownload ? ['download'] : [],
    });

    const productsPromise = addProductToGroup({
      group_id,
      item_ids: product_ids,
      delegates_access,
      include_descendants,
      include_future_descendants: false,
      permissions: permit_download ? ['download'] : [],
    });

    const parentProducts = addParentProductsToGroup({ group_id, item_ids: product_ids });

    const contactsPromise = addDifferentContactEntitiesToGroup({ group_id, contacts });

    const organizationsPromise = addOrganizationsToGroup({ group_id, item_ids: organization_ids });

    await Promise.all([productsPromise, parentProducts, assetsPromise, contactsPromise, organizationsPromise]);

    return group;
  });
};

export const loadGuildAllUserIds = async (group_id: ItemId): Promise<ItemId[]> => {
  const users = await loadGroupsUsers([group_id]);
  const organizations_users = await loadGroupsOrganizationsUsers([group_id]);

  return [...users.map(({ id }) => id), ...organizations_users.map(({ id }) => id)];
};

export const guildCodeCountUpdate = async (data: IGuild, schema = guildDetailsSchema): Promise<IResult | undefined> => {
  const { id } = data;

  if (!id) return;

  return chipmunk.action(Model.GUILDS, 'member.update', {
    params: { group_ids: id },
    body: {
      id: id,
      requested_codes_count: data.requested_codes_count,
    },
    schema,
  });
};
