import { merge } from 'lodash';
import { getRequiredError, IValidationResult } from 'helpers/form/mm3';
import { IAsset, IGenericMm3Asset, IMm3AssetType, ItemId } from 'types';
import { flags } from 'utils/flags';

export const labels = { confirm: 'Generate' };

type ITranscriptionService = 'aws' | 'xl8' | 'speechmatics' | 'ibm' | 'azure';

type ITranslationService = 'xl8' | 'deepl' | 'aws';

export const transcriptionOptions = [
  { value: 'xl8', label: 'XL8' },
  { value: 'aws', label: 'AWS' },
  { value: 'speechmatics', label: 'Speechmatics' },
  { value: 'ibm', label: 'IBM' },
  { value: 'azure', label: 'Azure' },
  { value: 'google', label: 'Google' },
];

export const translationOptions = [
  { value: 'xl8', label: 'XL8' },
  { value: 'aws', label: 'AWS' },
  { value: 'deepl', label: 'DeepL' },
];

export type ISubtitleGenerationFormData = {
  id: ItemId;
  source_language_id: string;
  target_language_ids: string[];
  transcription_service?: ITranscriptionService;
  translation_service?: ITranslationService;
  format?: string;
  show_in_player?: boolean;
};

export const isAudio = (asset: IGenericMm3Asset | IAsset): boolean => {
  if (flags.isMm3Assets) return IMm3AssetType.AUDIO === (asset as IGenericMm3Asset)?.type;

  return !!asset.classification?.includes('audio');
};

export const isSubtitle = (asset: IGenericMm3Asset | IAsset): boolean => {
  if (flags.isMm3Assets) return IMm3AssetType.SUBTITLE === (asset as IGenericMm3Asset).type;

  return !!asset.classification?.includes('subtitle');
};

export function customFormValidator(
  formData: ISubtitleGenerationFormData,
  validations: IValidationResult<ISubtitleGenerationFormData>,
): IValidationResult<ISubtitleGenerationFormData> {
  if (!formData) return validations;
  const isServiceNotRequired =
    formData.target_language_ids.length === 1 && formData.target_language_ids[0] === formData.source_language_id;

  return merge({}, validations, {
    source_language_id: {
      required: true,
      value: formData.source_language_id,
      ...(formData.source_language_id ? {} : getRequiredError()),
    },
    target_language_ids: {
      required: true,
      ...formData.target_language_ids,
      ...(formData.target_language_ids.length ? {} : getRequiredError()),
    },
    translation_service: {
      required: !isServiceNotRequired,
      value: formData.translation_service,
      ...(formData.translation_service || isServiceNotRequired ? {} : getRequiredError()),
    },
    transcription_service: {
      required: true,
      value: formData.transcription_service,
      ...(formData.transcription_service ? {} : getRequiredError()),
    },
  });
}
