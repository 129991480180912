import React, { useMemo } from 'react';

import { IAsset, IMm3Asset, IMm3AssetType } from 'types';
import { formatDuration } from 'utils/date';
import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { AssetFilters, withAssetVersion } from 'utils/asset';
import { FormInput, FormHtmlInput, FormSelect } from 'helpers/form';
import { CustomAboutEditRow, CustomAssetMetadataEditRow } from 'pages/asset-details/asset-metadata-tab/custom-row';

import { IAssetMetadataEditTabProps } from 'components/asset/edit-sections/types';
import { useBasicStore } from 'store/hooks';
import { formatAssetType, getEntityType } from 'utils/general';
import { filterClassificationsByType } from 'utils/asset-classification';

export const AssetOldMetadataAboutEditSection: React.FC<IAssetMetadataEditTabProps<IAsset>> = ({ asset, form }) => {
  const { formData, handlers } = form;
  const { languagesDataOptions } = useBasicStore();

  const { duration, classification, main_classification } = asset || {};
  const assetItemType = getEntityType(classification);
  const isVideo: boolean = assetItemType === AssetFilters.VIDEO;
  const isAudio: boolean = assetItemType === AssetFilters.AUDIO;

  const classificationOptions = useMemo(() => {
    if (!main_classification) {
      return formData.classification?.options || [];
    }
    return (formData.classification?.options || []).filter((classification) =>
      classification.startsWith(main_classification),
    );
  }, [main_classification, formData.classification?.options]);

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="About">
      <Row
        label="Name"
        showAsterisk
        value={
          <FormInput
            name="default_layer.name"
            placeholder="Type Name"
            large
            {...formData['default_layer.name']}
            {...handlers}
          />
        }
        size="m"
      />
      <Row
        label="Asset Type"
        showAsterisk
        value={
          <FormSelect
            name="classification"
            placeholder="Select Asset Type"
            {...formData.classification}
            {...handlers}
            large
            options={classificationOptions}
            formatLabel={formatAssetType}
          />
        }
        size="m"
      />

      <CustomAssetMetadataEditRow formData={formData} handlers={handlers} />

      <Row label="Duration" value={formatDuration(duration, 'seconds')} size="m" hidden={!(isVideo || isAudio)} />
      <Row
        label="Notes"
        value={
          <FormHtmlInput label="" name="default_layer.notes" large {...handlers} {...formData['default_layer.notes']} />
        }
      />
      <Row
        rawLabel
        label="Burned-in Subtitles"
        hidden={!isVideo}
        value={
          <FormSelect
            placeholder="Select Burned-in Subtitles"
            name="burned_in_subtitles_language_id"
            {...formData.burned_in_subtitles_language_id}
            {...handlers}
            options={languagesDataOptions}
          />
        }
      />
    </InfoBoxV2>
  );
};

export const AssetMm3MetadataAboutEditSection: React.FC<IAssetMetadataEditTabProps<IMm3Asset>> = ({ asset, form }) => {
  const { formData, handlers } = form;

  const { source_meta, type } = asset || {};
  const { duration } = source_meta || {};

  const isVideo: boolean = type === IMm3AssetType.VIDEO;
  const isAudio: boolean = type === IMm3AssetType.AUDIO;
  const isSubtitle: boolean = type === IMm3AssetType.SUBTITLE;

  const classificationOptions = useMemo(() => {
    return filterClassificationsByType(type || '', formData.classification?.options);
  }, [formData.classification?.options, type]);

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="About">
      <Row
        label="Name"
        showAsterisk
        value={<FormInput name="name" placeholder="Type Name" {...formData.name} {...handlers} />}
        size="m"
      />
      <Row
        label="Asset Type"
        showAsterisk
        value={
          <FormSelect
            name="classification"
            placeholder="Select Asset Type"
            {...formData.classification}
            {...handlers}
            formatLabel={formatAssetType}
            options={classificationOptions}
            large
          />
        }
        size="m"
      />

      <CustomAssetMetadataEditRow formData={formData} handlers={handlers} />
      <CustomAboutEditRow formData={formData} handlers={handlers} />
      <Row label="Duration" value={formatDuration(duration, 'seconds')} size="m" hidden={!(isVideo || isAudio)} />
      <Row
        label="Label for video player"
        value={<FormInput name="meta.label" {...handlers} {...formData.meta?.label} />}
        hidden={!(isSubtitle || isAudio)}
      />
      <Row
        label="Notes"
        value={<FormHtmlInput label="" name="meta.notes" large {...handlers} {...formData.meta?.notes} />}
      />
    </InfoBoxV2>
  );
};

export const AssetMetadataAboutEditSection = withAssetVersion(
  AssetOldMetadataAboutEditSection,
  AssetMm3MetadataAboutEditSection,
);
