import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { ToastStore } from 'store/toast-store';
import { DetailsPageTabs, IContact, IPhone, ItemId } from 'types';
import { ISectionHeaderAction } from 'components/section-header';
import { IUseFormReturn } from 'helpers/form';
import { Tab, TabPanel } from 'components/tabs';

import { loadContact } from './api';
import { getContactName } from 'utils/ui';
import { useRemote } from 'utils/hooks';
import { getContacts } from 'utils/apis/contacts';
import { getIsTabDisabled } from 'utils/general';
import { useSessionStore } from 'store/session-store';
import { flags } from 'utils/flags';
import { hasRequiredPowers } from 'components/check-power/check-power';

import { UserRoles } from 'types';

interface IRenderPhonesMenuResult {
  type: 'phone';
  handler?: () => void;
  submenu?: {
    type: 'phone';
    title: string;
    handler: () => void;
  }[];
}

const userManagementPowers = {
  [UserRoles.CLIENT]: ['um/client_user_management'],
  [UserRoles.ADMIN]: ['um/admin_user_management'],
  [UserRoles.SUPER_ADMIN]: ['um/super_user_management'],
};

export const renderPhonesMenu = (phones: IPhone[]): IRenderPhonesMenuResult | undefined => {
  if (!phones) {
    return;
  }

  if (phones.length === 1) {
    return {
      type: 'phone',
      handler: () => {
        document.location.href = `tel:${phones[0].number}`;
      },
    };
  }

  if (phones.length > 1) {
    return {
      type: 'phone',
      submenu: phones.map((phone) => ({
        type: 'phone',
        title: `${phone.label}: ${phone.number}`,
        handler: () => (document.location.href = `tel:${phone.number}`),
      })),
    };
  }
};

export const useRefreshContact = (
  contactId?: string,
  toastStore?: ToastStore,
): [boolean, () => Promise<void>, IContact | null | undefined, (contact: IContact) => void] => {
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState<IContact | null>(null);

  const refresh = useCallback(async () => {
    try {
      if (!contactId) {
        return;
      }
      setLoading(true);
      const contact = await loadContact(contactId);
      setContact(contact);
    } catch {
      toastStore?.error('Failed to load contact');
    } finally {
      setLoading(false);
    }
  }, [contactId, toastStore]);

  return [loading, refresh, contact, setContact];
};

interface IUseHeaderActionParams {
  contact: IContact;
  contactForm: IUseFormReturn<IContact>;
  editModeEnabled: boolean;
  setEditModeEnabled: (enabled: boolean) => void;
}

export const useHeaderActions = ({
  contact,
  contactForm,
  editModeEnabled,
  setEditModeEnabled,
}: IUseHeaderActionParams): { headerActions: ISectionHeaderAction[] } => {
  const headerActions: ISectionHeaderAction[] = [];

  const { email, phones = [], role_name } = contact || {};

  if (email) {
    headerActions.push({
      type: 'email',
      handler: () => {
        window.location.href = `mailto:${email}`;
      },
    });
  }

  const phoneElement = renderPhonesMenu(phones);
  if (phoneElement) {
    headerActions.push(phoneElement);
  }
  headerActions.push({ divider: true });

  if (editModeEnabled) {
    headerActions.push({
      type: 'save',
      disabled: !contactForm.valid,
      handler: contactForm.onSubmit,
    });
    headerActions.push({
      type: 'cancel',
      handler: () => {
        setEditModeEnabled(false);
        contactForm.resetFields();
      },
    });
  } else {
    headerActions.push({
      type: 'edit',
      handler: () => {
        setEditModeEnabled(true);
      },
      disabled: !hasRequiredPowers(userManagementPowers[role_name]),
    });
  }

  return { headerActions };
};

export const useRefreshPreviewForImageIngest = (
  contactId: string,
  setContact: (contact: IContact) => void,
  contact?: IContact | null,
): void => {
  const { preview_image_id, preview_image } = contact || {};
  useEffect(() => {
    const id = setInterval(async () => {
      if (preview_image_id && !preview_image?.id) {
        const contact = await loadContact(contactId);
        setContact(contact);
        if (contact?.preview_image?.id) {
          clearInterval(id);
        }
      }
    }, 20 * 1000);

    return () => clearInterval(id);
  }, [preview_image_id, preview_image?.id, contactId, setContact]);
};

export const ContactAssistants: React.FC<{ assistantIds?: ItemId[] }> = ({ assistantIds }) => {
  const fetchAssistants = useCallback(async (): Promise<IContact[] | undefined> => {
    if (!assistantIds?.length) return [];
    const assistants = await getContacts({ contactIds: assistantIds });
    return assistants;
  }, [assistantIds]);

  const [assistants] = useRemote<IContact[]>(fetchAssistants, []);
  if (!assistants?.length) return null;
  return (
    <>
      {assistants.map((assistant, i) => (
        <>
          <Link key={assistant.id} to={`/contacts/${assistant.id}`}>
            {getContactName(assistant)}
          </Link>
          {i < assistants.length - 1 && <span>,&nbsp;</span>}
        </>
      ))}
    </>
  );
};

export const tabOptions = [
  DetailsPageTabs.OVERVIEW,
  DetailsPageTabs.ANALYTICS,
  DetailsPageTabs.LISTS,
  DetailsPageTabs.TIMELINE,
  DetailsPageTabs.ACTIVITY,
  DetailsPageTabs.ACCESS,
];

export const editableTabs = [DetailsPageTabs.OVERVIEW];

export const initialContactForm = { valid: false } as IUseFormReturn<IContact>;

export const ContactDetailTab: React.FC<{
  value: DetailsPageTabs;
  activeTab: DetailsPageTabs;
  editModeEnabled: boolean;
  hidden?: boolean;
}> = ({ value, activeTab, editModeEnabled, hidden = false }) => {
  return (
    <Tab
      value={value}
      className={cx(`contact-detail__tab`, {
        'contact-detail__tab--active': activeTab === value,
      })}
      disabled={getIsTabDisabled(value, editableTabs, editModeEnabled)}
      hidden={hidden}
    />
  );
};

export const ContactTabPanel: React.FC<{ value: DetailsPageTabs; content: JSX.Element }> = ({ value, content }) => {
  return <TabPanel value={value} className="contact-detail__tab-content h-100" content={content} />;
};

export const showNotificationPreferences = (contactId: string): boolean => {
  const userId = useSessionStore.getState().user?.id;

  return userId === contactId && flags.showMeetingsFeature;
};
