import React from 'react';
import { Stack } from '@mantine/core';

import { useFutureDescendantsFlag } from 'components/access-privilege';
import { FormProducts, FormCheckbox } from 'helpers/form';
import { Text } from 'components/text';
import { IAccessGroupFormComponent } from './types';

import './style.scss';
export const keysProducts = ['product_ids', 'include_descendants', 'delegates_access', 'permit_download'];

export const AccessGroupFormProducts: React.FC<IAccessGroupFormComponent> = ({ form }) => {
  useFutureDescendantsFlag(form);
  return (
    <div className="d-flex flex-column h-100">
      <div className="access-group-grant-form-products">
        <FormProducts
          name="product_ids"
          label="Products"
          {...form.formData.product_ids}
          {...form.handlers}
          fitInParent
        />
      </div>
      <Stack gap={0}>
        <Text>
          All products will be added with their full ancestry (necessary for the correct display of privileged access on
          the client-facing site).
        </Text>
        <FormCheckbox
          name="include_descendants"
          label="Include descendant product(s)"
          {...form.formData.include_descendants}
          {...form.handlers}
          className="mb-0"
        />
        <FormCheckbox
          label="Automatically include all future descendant products"
          name="include_future_descendants"
          className="ms-3 mb-0"
          {...form.formData.include_future_descendants}
          {...form.handlers}
          disabled={!form.values.include_descendants}
        />

        <FormCheckbox
          label="Include the Products’ unpublished Assets"
          name="delegates_access"
          className="mb-0"
          {...form.formData.delegates_access}
          {...form.handlers}
        />

        <FormCheckbox
          label="Permit asset download"
          name="permit_download"
          className="mb-0"
          {...form.formData.permit_download}
          {...form.handlers}
        />
      </Stack>
    </div>
  );
};
