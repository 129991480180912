import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from '@mantine/core';

import {
  FormCheckbox,
  FormChecklist,
  FormSelect as Select,
  FormMultiSelect,
  FormHtmlInput,
  FormProducts,
  FormRemoteSelect,
  FormAccessLevel,
  FormDivisions,
  FormAssetStatus,
  FormInput,
} from 'helpers/form';
import { IFormData, IFormHandlers } from 'helpers/form/types';

import { IAsset, IAssetPresetFields, ISearchFilter, ItemId } from 'types';
import { useAssetsUploadStore, useBasicStore } from 'store/hooks';
import { getWatermarkOptions } from 'components/asset/asset-upload-components/utils';
import { CustomAssetRow } from 'components/asset/asset-edit-form/sections/custom-row';
import { MarketingUseField } from 'components/asset/asset-upload-components/preset-form/marketing-use-field';

import { AssetFilters } from 'utils/asset';
import { getEntityType } from 'utils/general';
import { queryMainAssets } from 'utils/apis/asset';
import { flags } from 'utils/flags';
import { MantineIcon } from 'utils/ui';
import { AssetType } from 'utils/format-asset-type';

import './index.scss';

export interface IAssetPresetFieldsProps {
  formId: string;
  formData: IFormData<Partial<IAssetPresetFields>>;
  handlers: IFormHandlers<Partial<IAssetPresetFields>>;
  values: IAssetPresetFields;
  shouldShowErrors: boolean;
  withParentField?: boolean;
}

const fetchParentAssets = (q: string, id: ItemId): Promise<IAsset[]> =>
  queryMainAssets({ q, ids: id ? [id] : undefined }, [['type', 'eq', AssetType.VIDEO]] as ISearchFilter[]);

export const AssetPresetFields: React.FC<IAssetPresetFieldsProps> = ({
  formId,
  formData,
  handlers,
  values,
  shouldShowErrors,
  withParentField,
}) => {
  const { languagesDataOptions } = useBasicStore();
  const { initialValues, productPresetApplied } = useAssetsUploadStore();
  const FormSelect = useCallback(
    ({ touched, ...props }): JSX.Element => <Select {...props} touched={shouldShowErrors || touched} />,
    [shouldShowErrors],
  );

  const classification = values?.classification || '';
  const watermarkOptions = getWatermarkOptions(formData.watermark as { options: string[] });
  const purposeOptions = ['subtitle', 'caption'];
  const assetItemType = getEntityType(classification);
  const isVideo: boolean = assetItemType === AssetFilters.VIDEO;
  const isAudio: boolean = assetItemType === AssetFilters.AUDIO;
  const isSubtitle: boolean = classification.includes('subtitle');

  const onAccessLevelChange = useCallback(
    ({ access_level }) => {
      if (access_level !== 'division') handlers.onChange({ division_ids: [], access_level });
      else handlers.onChange({ access_level });
    },
    [handlers],
  );

  return (
    <div className="asset-upload-components__preset-form-container mt-3">
      <div className="asset-upload-components__preset-form-left">
        <div className="mb-3">
          <MarketingUseField formData={formData} handlers={handlers} formId={formId} values={values} />
          <div className="d-flex justify-content-space-between">
            <FormCheckbox
              formId={formId}
              name="sharable"
              label="Sharable"
              className="mb-1"
              {...formData.sharable}
              {...handlers}
            />
            <Tooltip label="Allow non-Administrator contacts to share this asset with other contacts">
              <div className="info-sign_container">
                <MantineIcon icon="info-sign" size={11} color="white" />
              </div>
            </Tooltip>
          </div>
          <FormChecklist
            hasLabelOptionsCapitalized
            formId={formId}
            name="permissions"
            className="mb-1"
            {...formData.permissions}
            {...handlers}
          />
        </div>
        <div className="mt-3">
          <FormChecklist
            formId={formId}
            name="protection_levels"
            label="Protection Levels"
            {...formData.protection_levels}
            {...handlers}
            hasLabelOptionsCapitalized
          />
        </div>
      </div>
      <div className="asset-upload-components__preset-form-right">
        {!values.parent_id && (
          <div className="asset-upload-components__preset-form-products">
            <FormProducts
              name="products"
              label="Products"
              {...formData.products}
              {...handlers}
              forceReInitiating={productPresetApplied}
            />
          </div>
        )}
        <FormSelect
          name="classification"
          label="Type"
          large
          {...formData.classification}
          {...handlers}
          disabled={Boolean(initialValues.classification)}
        />
        {flags.isMm3Assets && withParentField && !values.products?.length && (
          <FormRemoteSelect
            fetchOptions={fetchParentAssets}
            name="parent_id"
            label="Main Asset"
            large
            {...formData.parent_id}
            {...handlers}
            emptyValue={null}
            disabled={Boolean(initialValues.parent_id)}
          />
        )}
        <CustomAssetRow formData={formData} handlers={handlers} />
        {Boolean(watermarkOptions.length) && (
          <FormSelect
            name="watermark"
            label="Watermark"
            large
            {...formData.watermark}
            {...handlers}
            options={watermarkOptions}
          />
        )}
        <FormAccessLevel
          name="access_level"
          label="Access"
          large
          {...formData.access_level}
          {...handlers}
          onChange={onAccessLevelChange}
          filterOptionsByUserRole
        />
        {formData?.access_level?.value === 'division' && (
          <FormDivisions
            label="Divisions"
            name="division_ids"
            {...formData.division_ids}
            {...handlers}
            large
            isValueNumber
          />
        )}
        {flags.isMm3Assets ? (
          <FormSelect
            name="meta.language_id"
            label="Language"
            {...formData['meta.language_id']}
            {...handlers}
            options={languagesDataOptions}
          />
        ) : (
          <FormMultiSelect
            name="languages"
            label="Languages"
            {...formData.languages}
            {...handlers}
            options={languagesDataOptions}
          />
        )}

        {(isSubtitle || isAudio) && flags.isMm3Assets && (
          <FormCheckbox
            name="meta.language_id_approved"
            label="Language Approved"
            {...formData['meta.language_id_approved']}
            {...handlers}
            groupClassName="my-auto"
          />
        )}

        <FormAssetStatus name="status" label="Status" large {...formData.status} {...handlers} />

        {isVideo && (
          <>
            <FormSelect
              name="burned_in_subtitles_language_id"
              label="Burned-in Subtitles"
              large
              {...formData.burned_in_subtitles_language_id}
              {...handlers}
              options={languagesDataOptions}
            />

            <FormSelect
              name="dubbed_language_id"
              label="Dubbed language"
              large
              {...formData.dubbed_language_id}
              {...handlers}
              options={languagesDataOptions}
            />
          </>
        )}

        {flags.isMm3Assets && isSubtitle && (
          <FormSelect
            name="meta.purpose"
            label="Purpose"
            {...{ ...formData['meta.purpose'], options: purposeOptions }}
            {...handlers}
          />
        )}

        {(isSubtitle || isAudio) && flags.isMm3Assets && (
          <FormInput name="meta.label" label="Label for video player" {...formData['meta.label']} {...handlers} />
        )}

        <div className="asset-upload-components__preset-form-products">
          <FormHtmlInput
            formId={formId}
            name="layer.notes"
            type="textarea"
            label="Notes"
            large
            {...formData['layer.notes']}
            {...handlers}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(observer(AssetPresetFields));
