import { IAddToGroupEntityType, IAddToGroupForm, useAddToGroupDialog } from 'components/add-to-group-dialog';
import type { IFormMultiSelectOption } from 'helpers/form/fields/select-helpers';
import { IUseActionsOptionParam } from 'utils/actions';

import { IGroupType, IIdentifiable, IGroupVariety } from 'types';
import { IGroupActionName, IGroupConfig, IShouldDisplayGroupActionFunction } from './types';

export type { IShouldDisplayGroupActionFunction };

export interface IGroupActionProps {
  entities: (IGroupVariety & IIdentifiable)[];
  options: IUseActionsOptionParam;
}

export interface IUseAddEntitiesToGroupActionOptions extends IUseActionsOptionParam {
  title?: string;
  groupLabel?: string;
  dialogTitle?: string;
  entityType: IAddToGroupEntityType;
  passPropsFromExistingItems?: boolean;
  hideProductsParams?: boolean;
  groupType: IGroupType;
  shouldDisplay: IShouldDisplayGroupActionFunction;
  addSelectedProductAncestors?: boolean;
  withEmailNotification?: boolean;
  items?: IFormMultiSelectOption[];
  omni?: boolean;
  initialValues?: Partial<IAddToGroupForm>;
}

export const useAddEntitiesToGroupAction = (
  items: IGroupVariety[],
  options: IUseAddEntitiesToGroupActionOptions,
): IGroupConfig => {
  const [openDialog] = useAddToGroupDialog({
    group: items[0] as unknown as IGroupVariety,
    entityType: options.entityType,
    options,
    items: options.items,
    groupType: options.groupType,
    title: options?.dialogTitle,
    groupLabel: options?.groupLabel,
    passPropsFromExistingItems: options?.passPropsFromExistingItems,
    hideProductsParams: options?.hideProductsParams,
    addSelectedProductAncestors: options?.addSelectedProductAncestors,
    withEmailNotification: options?.withEmailNotification,
    omni: options?.omni,
  });

  return {
    name: IGroupActionName.ADD_ENTITIES,
    shouldDisplay: options.shouldDisplay,
    icon: 'add',
    title: options?.title ?? `Add to this ${options?.groupLabel}`,
    handler: openDialog,
  };
};
