import React, { useCallback, useMemo } from 'react';

import { useStore } from 'store';

import {
  FormDate,
  FormChecklist,
  useForm,
  IFormData,
  IFormHandlers,
  FormAccessLevel,
  useMm3Form,
  IFormMm3Handlers,
} from 'helpers/form';
import { FormSubmitSection } from 'components/form-submit-section';
import { IAccessChangeScheduledAccess } from 'utils/actions/asset';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { ScheduledJobList } from 'components/scheduled-job-list';
import { ToastError } from 'components/toast';

import { useScheduledJobs } from './hooks';
import { accessContextExtension } from './utils';
import { minAccessDate, maxAccessDate } from 'utils/constants';
import { Model } from 'helpers/filters/types';
import { getValidIds } from 'utils/general';
import { flags } from 'utils/flags';
import { IAsset, IMm3Asset, IScheduledJob } from 'types';

import 'components/scheduled-job-list/style.scss';

const renderDetails = (job: IScheduledJob): JSX.Element => (
  <>
    {job?.access_level}
    <br />
    {job?.permissions?.join(', ')}
  </>
);

export const AccessChangeScheduledAccessForm: React.FC<{ items: (IAsset | IMm3Asset)[]; readOnly?: boolean }> = ({
  items,
  readOnly = false,
}) => {
  const formId = `asset-access-change-scheduled-access`;
  const { toastStore } = useStore();

  const itemIds = useMemo(() => getValidIds(items), [items]);

  const [scheduledJobs, removeJob, scheduleJob] = useScheduledJobs<IAccessChangeScheduledAccess>(
    'change_access_level_on_asset',
    itemIds,
  );

  const submitForm = useCallback(
    async (data: IAccessChangeScheduledAccess) => {
      try {
        if (!items.every((item) => item.division_ids?.length) && data.access_level === 'division') {
          toastStore.error('Does not belong to any divisions');
          return;
        }
        await scheduleJob(data);
        toastStore.success('Access changed');
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder="Something went wrong" />);
      }
    },
    [items, scheduleJob, toastStore],
  );
  const initialValues = {
    scheduled_for: '',
    access_level: '',
    permissions: [],
    item_ids: itemIds,
  };

  const mm3Form = useMm3Form<IAccessChangeScheduledAccess>(initialValues, Model.MM3_ASSETS, submitForm);
  const coreForm = useForm<IAccessChangeScheduledAccess>(
    initialValues,
    Model.ASSETS,
    submitForm,
    accessContextExtension,
  );

  const currentForm = flags.isMm3Assets ? mm3Form : coreForm;
  const { formData, handlers, valid, onSubmit } = currentForm;

  if (readOnly && scheduledJobs.length === 0) {
    return null;
  }

  const disableSubmit = scheduledJobs.find(({ scheduled_for }) => scheduled_for === formData.scheduled_for.value);

  return (
    <form onSubmit={onSubmit}>
      {Boolean(scheduledJobs.length) && (
        <FormSectionWrapperV2 title="Scheduled Access" gridTemplateColumns="1fr" padded>
          <ScheduledJobList
            jobs={scheduledJobs}
            onRemove={removeJob}
            renderDetails={renderDetails}
            readOnly={readOnly}
          />
        </FormSectionWrapperV2>
      )}
      {!readOnly && (
        <FormElements valid={valid && !disableSubmit} formId={formId} formData={formData} handlers={handlers} />
      )}
    </form>
  );
};

const FormElements: React.FC<{
  formId: string;
  formData: IFormData<IAccessChangeScheduledAccess>;
  handlers: IFormHandlers<IAccessChangeScheduledAccess> | IFormMm3Handlers<IAccessChangeScheduledAccess>;
  valid: boolean;
}> = ({ formId, formData, handlers, valid }) => {
  return (
    <>
      <FormSectionWrapperV2 title="Schedule new access" gridTemplateColumns={'1fr'}>
        <FormDate
          name="scheduled_for"
          label="Date"
          large
          withTime={true}
          minDate={minAccessDate}
          maxDate={maxAccessDate}
          {...formData.scheduled_for}
          {...handlers}
        />
        <div>
          <FormAccessLevel
            name="access_level"
            label="Access"
            large
            {...formData.access_level}
            {...handlers}
            withinPortal
          />
          <FormChecklist
            name="permissions"
            label="Permissions"
            formId={formId}
            large
            {...formData.permissions}
            {...handlers}
          />
        </div>
      </FormSectionWrapperV2>
      <FormSubmitSection submitDisabled={!valid} labels={{ confirm: 'Schedule', cancel: 'Close' }} />
    </>
  );
};
