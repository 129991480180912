import React, { useCallback } from 'react';
import { useStore } from 'store';
import { IAsset, IMm3Asset } from 'types';
import { FormChecklist, FormCheckbox, useForm, FormAccessLevel, FormAssetStatus, useMm3Form } from 'helpers/form';
import { FormSubmitSection } from 'components/form-submit-section';
import { ToastError } from 'components/toast';
import { IAccessChangeImmediateForm, IAssetActionName } from 'utils/actions/asset/';
import { changeAssetAccessImmediate } from 'utils/apis/asset';
import { useRefreshDataSection } from 'utils/hooks';
import { IUseActionsOptionParam } from 'utils/actions';
import { getValidIds } from 'utils/general';
import { Model } from 'helpers/filters/types';
import { flags } from 'utils/flags';

export const AccessChangeImmediateForm: React.FC<{
  items: (IAsset | IMm3Asset)[];
  options: IUseActionsOptionParam;
}> = ({ items, options }) => {
  const formId = `asset-access-change-immediate`;

  const { dialogStore, toastStore } = useStore();
  const refreshDataSection = useRefreshDataSection();
  const submitForm = useCallback(
    async (data: IAccessChangeImmediateForm) => {
      try {
        if (!items.every((item) => item.division_ids?.length) && data.access_level === 'division') {
          toastStore.error('Does not belong to any divisions');
          return;
        }
        const updatedAsset = await changeAssetAccessImmediate(data);
        options.context === 'single' && options?.onSuccess?.(IAssetActionName.CHANGE_ACCESS, updatedAsset.object);
        toastStore.success('Access changed');
        dialogStore.close();
        refreshDataSection();
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder="Something went wrong" />);
      }
    },
    [dialogStore, items, options, refreshDataSection, toastStore],
  );
  const initialValues =
    items?.length > 1
      ? {
          status: '',
          access_level: '',
          permissions: [],
          protection_levels: [],
          skip_access_delegation: false,
          item_ids: getValidIds(items),
        }
      : {
          status: items[0].status,
          access_level: items[0].access_level,
          permissions: items[0].permissions,
          protection_levels: items[0].protection_levels,
          skip_access_delegation: false,
          item_ids: getValidIds(items),
        };

  const mm3Form = useMm3Form<IAccessChangeImmediateForm>(initialValues, Model.MM3_ASSETS, submitForm);
  const coreForm = useForm<IAccessChangeImmediateForm>(initialValues, Model.ASSETS, submitForm);
  const currentForm = flags.isMm3Assets ? mm3Form : coreForm;
  const { formData, handlers, valid, onSubmit } = currentForm;
  return (
    <form onSubmit={onSubmit}>
      <FormAssetStatus name="status" label="Status" large {...formData.status} {...handlers} required />
      <FormAccessLevel name="access_level" label="Access" large {...formData.access_level} {...handlers} required />
      <FormChecklist
        name="permissions"
        label="Permissions"
        formId={formId}
        large
        {...formData.permissions}
        {...handlers}
      />
      <FormChecklist
        name="protection_levels"
        label="Protection Levels"
        formId={formId}
        large
        {...formData.protection_levels}
        {...handlers}
      />
      <FormCheckbox
        name="skip_access_delegation"
        label="Prevent (Product) Access Delegation"
        formId={formId}
        {...formData.skip_access_delegation}
        {...handlers}
      />
      <FormSubmitSection submitDisabled={!valid} />
    </form>
  );
};
