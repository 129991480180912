import React from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';

import { getIncludedAttribute, getPermittedAttribute } from 'utils/ui';

import { IAccessGroupFormComponent } from './types';
import { parseDifferentContacts } from 'utils/apis/groups';
import { formatDate } from 'utils/date';

export const AccessGroupFormSummary: React.FC<IAccessGroupFormComponent> = ({ form: { values } }) => {
  const contacts = parseDifferentContacts(values.contacts);
  return (
    <FormSectionWrapperV2 padded={false}>
      <Row label="Name" value={values.name} />
      <Row label="Access level" value={values.access_level} />
      <Row label="Products" value={values.product_ids.length} />
      <Row label="Assets" value={values.asset_ids.length || values.assetList.length} />
      <Row label="Contacts" value={contacts.users.length + contacts.selections_users_count} />
      <Row label="Organizations" value={values.organization_ids.length} />
      <Row label="Expires at" rawLabel value={formatDate(values.expires_at)} hideIfNoValue />
      <Row label="Descendant Products" value={getIncludedAttribute(values.include_descendants)} />
      <Row label="Future Descendant Products" value={getIncludedAttribute(values.include_future_descendants)} />
      <Row label="Unpublished assets" value={getIncludedAttribute(values.delegates_access)} />
      <Row label="Products' Assets Download" rawLabel value={getPermittedAttribute(values.permit_download)} />
      <Row label="Other Assets Download" rawLabel value={getPermittedAttribute(values.permitAssetDownload)} />
    </FormSectionWrapperV2>
  );
};
