import React, { useCallback, useMemo } from 'react';
import { useStore } from 'store';
import { IAsset, IMm3Asset } from 'types';
import { FormDate, FormChecklist, useForm, IFormData, IFormHandlers, useMm3Form, IFormMm3Handlers } from 'helpers/form';
import { FormSubmitSection } from 'components/form-submit-section';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { ToastError } from 'components/toast';

import { useScheduledJobs } from './hooks';
import { ScheduledJobList } from 'components/scheduled-job-list';
import { protectionContextExtension } from './utils';
import { minAccessDate, maxAccessDate } from 'utils/constants';
import { Model } from 'helpers/filters/types';
import { getValidIds } from 'utils/general';
import { IAccessChangeScheduledProtection } from 'utils/actions/asset';
import { flags } from 'utils/flags';

const renderDetails = (job): string => job.protection_levels.join(', ');

export const AccessChangeScheduledProtectionForm: React.FC<{ items: (IAsset | IMm3Asset)[]; readOnly?: boolean }> = ({
  items,
  readOnly = false,
}) => {
  const formId = `asset-access-change-scheduled-protection`;
  const { toastStore } = useStore();

  const itemIds = useMemo(() => getValidIds(items), [items]);

  const [scheduledJobs, removeJob, scheduleJob] = useScheduledJobs<IAccessChangeScheduledProtection>(
    'change_protection_levels_on_asset',
    itemIds,
  );

  const submitForm = useCallback(
    async (data: IAccessChangeScheduledProtection) => {
      try {
        await scheduleJob(data);
        toastStore.success('Protection changed');
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder={'Sorry, something went wrong'} />);
      }
    },
    [toastStore, scheduleJob],
  );
  const initialValues = {
    scheduled_for: '',
    protection_levels: [],
    item_ids: itemIds,
  };
  const mm3Form = useMm3Form<IAccessChangeScheduledProtection>(initialValues, Model.MM3_ASSETS, submitForm);

  const coreForm = useForm<IAccessChangeScheduledProtection>(
    initialValues,
    Model.ASSETS,
    submitForm,
    protectionContextExtension,
  );
  const currentForm = flags.isMm3Assets ? mm3Form : coreForm;
  const { formData, handlers, valid, onSubmit } = currentForm;
  const disableSubmit = scheduledJobs.find(({ scheduled_for }) => scheduled_for === formData.scheduled_for.value);

  return (
    <form onSubmit={onSubmit}>
      {Boolean(scheduledJobs.length) && (
        <FormSectionWrapperV2 title="Scheduled Protections" gridTemplateColumns="1fr" padded>
          <ScheduledJobList
            jobs={scheduledJobs}
            onRemove={removeJob}
            renderDetails={renderDetails}
            readOnly={readOnly}
          />
        </FormSectionWrapperV2>
      )}

      {!readOnly && (
        <FormElements valid={valid && !disableSubmit} formId={formId} formData={formData} handlers={handlers} />
      )}
    </form>
  );
};

const FormElements: React.FC<{
  formId: string;
  formData: IFormData<IAccessChangeScheduledProtection>;
  handlers: IFormHandlers<IAccessChangeScheduledProtection> | IFormMm3Handlers<IAccessChangeScheduledProtection>;
  valid: boolean;
}> = ({ formId, formData, handlers, valid }) => {
  return (
    <>
      <FormSectionWrapperV2 title="Schedule new protection" gridTemplateColumns="1fr">
        <FormDate
          name="scheduled_for"
          label="Date"
          large
          withTime={true}
          minDate={minAccessDate}
          maxDate={maxAccessDate}
          {...formData.scheduled_for}
          {...handlers}
        />
        <br />
        <FormChecklist
          name="protection_levels"
          label="Protection Levels"
          formId={formId}
          large
          {...formData.protection_levels}
          {...handlers}
        />
      </FormSectionWrapperV2>

      <FormSubmitSection submitDisabled={!valid} labels={{ confirm: 'Schedule', cancel: 'Close' }} />
    </>
  );
};
