import {
  DeliveriesGenericConnection,
  DeliveriesGenericConnectionAspera,
  DeliveriesGenericConnectionS3,
  DeliveriesGenericConnectionSftp,
  DeliveriesGenericConnectionWyvern,
} from '@mediafellows/mm3-types';

import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

export interface IDeliveryConnection
  extends DeliveriesGenericConnection,
    DeliveriesGenericConnectionAspera,
    DeliveriesGenericConnectionS3,
    DeliveriesGenericConnectionSftp,
    Omit<DeliveriesGenericConnectionWyvern, 'description'> {
  host: string;
  port: number;
  username: string;
  password: string;
  description: string;
}

export interface IConnectionForm {
  form: IUseMm3FormReturn<IDeliveryConnection>;
  newConnection?: boolean;
}

export interface IConnectionContainerProps {
  connection: IDeliveryConnection;
  organizationId: number;
  onFinish: (connection: IDeliveryConnection) => void;
}

export interface IConnectiontFormProps {
  saveConnection: (values: IDeliveryConnection) => void;
  values: IDeliveryConnection;
}

export const enum ConnectionType {
  ASPERA = 'Connection::Aspera',
  AZURE = 'Connection::AzureBlobStorage',
  GOOGLE = 'Connection::GoogleCloudStorage',
  SFTP = 'Connection::Sftp',
  S3 = 'Connection::S3',
  WYVERN = 'Connection::Wyvern',
}

export type IConnectionType =
  | ConnectionType.S3
  | ConnectionType.ASPERA
  | ConnectionType.SFTP
  | ConnectionType.WYVERN
  | ConnectionType.AZURE
  | ConnectionType.GOOGLE;
