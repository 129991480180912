import React, { useCallback } from 'react';
import pick from 'lodash/pick';

import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { ToastError } from 'components/toast';
import { AssignCastCrewToProductForm } from 'components/cast-crew';
import type { IAssignCastCrewToProductFormData } from 'components/cast-crew';
import { updateProductLayer } from 'utils/apis/product';
import { cleanUp } from 'utils/payload';

import { ICastCrewActionConfig, ICastCrewActionName } from './types';
import { shouldDisplayOnProductPageSingleItem } from './acl';
import { IExtendedProductCastCrew } from 'types/product';

export const useEditAssignmentToProductsAction = (
  items: IExtendedProductCastCrew[],
  options: IUseActionsOptionParam,
): ICastCrewActionConfig => {
  const { dialogStore, toastStore } = useStore();

  const handleSubmit = useCallback(
    async (values: IAssignCastCrewToProductFormData): Promise<void> => {
      const { cast_character, kind, cast_role, crew_position } = values;
      const index = items[0]?.index;
      const product = options.product;
      const newValue = kind === 'Cast' ? { cast_character, cast_role } : { crew_position };
      try {
        if (!product?.id) {
          return;
        }

        const data = cleanUp({
          id: product.default_layer_id,
          product_id: product.id,
          meta: {
            ...product.default_layer.meta,
            cast_crew: (product.default_layer?.meta?.cast_crew || []).map((e, i) =>
              i === index ? { ...e, kind, ...newValue } : e,
            ),
          },
        });
        const result = await updateProductLayer(data);
        toastStore.success('Success');
        options?.onSuccess?.(ICastCrewActionName.EDIT_ASSIGNMENT, items[0], result);
        dialogStore.close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
        options?.onFailure?.();
      }
    },
    [dialogStore, items, options, toastStore],
  );

  const handler = (): void => {
    dialogStore.openModal({
      title: 'Edit Assignment to Products',
      body: () => {
        if (!options?.product?.id || !items?.[0]?.cast_crew_id) {
          return <></>;
        }

        return (
          <AssignCastCrewToProductForm
            hiddenFields={['product_ids', 'cast_crew_ids']}
            data={{
              ...pick(items[0], ['cast_character', 'cast_role', 'crew_position', 'kind']),
              cast_crew_ids: items.reduce((acc, e) => (e.cast_crew_id ? [...acc, e.cast_crew_id] : acc), []),
              product_ids: [options.product.id],
              products: [options.product],
            }}
            handleSubmit={handleSubmit}
          />
        );
      },
    });
  };

  return {
    name: ICastCrewActionName.EDIT_ASSIGNMENT,
    icon: 'edit',
    title: 'Edit Assignment',
    shouldDisplay: shouldDisplayOnProductPageSingleItem,
    handler,
  };
};
