import React, { useEffect, useCallback } from 'react';
import { map, difference } from 'lodash';

import { IAsset, IGenericMm3Asset, IMm3Asset, IMm3AssetType } from 'types';
import { FormCheckbox, FormMultiSelect, FormRemoteSelect, FormSelect, useMm3Form } from 'helpers/form';
import { Model } from 'helpers/filters/types';

import { useStore } from 'store';
import { useDialogStore, useBasicStore } from 'store/hooks';

import { ToastError } from 'components/toast';
import { FormSubmitSection } from 'components/form-submit-section';

import { flags } from 'utils/flags';
import { ITranslateProps, generateSubtitle } from 'utils/apis/generate-subtitle';
import { getAssets, querySubAssets } from 'utils/apis/asset';

import { supportedLanguages } from 'utils/transcribe-supported-languages';
import { supportedLanguages as translationSupportedLanguages } from 'utils/translation-supported-languages';

import {
  ISubtitleGenerationFormData,
  isSubtitle,
  labels,
  transcriptionOptions,
  translationOptions,
  customFormValidator,
} from './utils';
import { getLanguageById } from 'utils/general';

const initialValues: ISubtitleGenerationFormData = {
  id: '',
  source_language_id: '',
  target_language_ids: [],
  translation_service: undefined,
  transcription_service: undefined,
  format: '.srt',
  show_in_player: true,
};

export const GenerateSubtitleForm: React.FC<{ asset: IGenericMm3Asset | IAsset }> = ({ asset }) => {
  const { languagesDataOptions, languages } = useBasicStore();
  const [sourceAsset, setSourceAsset] = React.useState<IGenericMm3Asset | IAsset>();

  const { close } = useDialogStore();

  const { toastStore } = useStore();
  const handleSubmit = useCallback(
    async (values: ISubtitleGenerationFormData, valid: boolean): Promise<void> => {
      try {
        if (!valid) {
          return;
        }
        const promises = [] as Promise<ITranslateProps>[];

        map(values.target_language_ids, (target_language_id) => {
          if (
            target_language_id.toLowerCase() === values.source_language_id &&
            isSubtitle(sourceAsset as IGenericMm3Asset)
          ) {
            toastStore.info('The language defined on the source asset is the same as the selected output language.');
            return;
          } else {
            promises.push(
              generateSubtitle({
                id: values.id,
                source_language_id: values.source_language_id,
                target_language_id,
                transcribe_service_name: values.translation_service,
                translate_service_name: values.translation_service,
              } as ITranslateProps),
            );
          }
        });

        await Promise.all(promises);

        toastStore.clearAll();
        toastStore.success('Subtitle Generation was successfully requested!');
        close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [close, sourceAsset, toastStore],
  );

  const { formData, onSubmit, handlers, valid, values } = useMm3Form<ISubtitleGenerationFormData>(
    initialValues,
    Model.MM3_AUDIO,
    handleSubmit,
    customFormValidator,
  );

  const fetchSubAssetsOptions = useCallback(
    () =>
      querySubAssets([asset?.id as string], undefined, ['type', 'in', [IMm3AssetType.AUDIO, IMm3AssetType.SUBTITLE]]),
    [asset.id],
  );

  useEffect(() => {
    if (flags.isMm3Assets) return;

    if (!(asset as IAsset)?.language_ids?.[0]) {
      toastStore.info('Before you begin, please define the language on the source asset.');
      return;
    }

    setSourceAsset(asset);
    handlers.onChange({ id: (asset as IAsset).id, source_language_id: (asset as IAsset).language_ids?.[0] });
  }, [asset, handlers, toastStore]);

  useEffect(() => {
    if (!flags.isMm3Assets) return;

    const fetchAsset = async (): Promise<void> => {
      if (values.id) {
        const assets = await getAssets([values.id], 'id, type, main_classification, meta { language_id }');
        const asset = assets[0] as unknown as IMm3Asset;
        setSourceAsset(asset);

        if (!asset.meta?.language_id) {
          toastStore.error('Before you begin, please define the language on the source asset.');
          return;
        }

        if (!asset.meta?.language_id && !asset?.meta?.language_id_approved) {
          toastStore.error(
            "Before you begin, please confirm that the language defined on the source asset is correct by ticking the 'Language approved' checkbox in its edit form.",
          );
          return;
        }

        handlers.onChange({ source_language_id: asset.meta?.language_id });
      }
    };

    fetchAsset();
  }, [values.id, handlers, toastStore]);

  const sourceLanguage = getLanguageById(languages, values.source_language_id);

  // Checks for supported language by selected service
  useEffect(() => {
    if (!values?.transcription_service) return;
    const serviceSupportedLanguages = supportedLanguages[values?.transcription_service as string]?.supported_languages
      ?.join(', ')
      .toLowerCase()
      .split(', ');

    if (!serviceSupportedLanguages?.includes(values.source_language_id.toLowerCase())) {
      toastStore.error(
        `The selected transcription service doesn't support the selected source language ${sourceLanguage}.`,
      );
    }
  }, [values.source_language_id, values.transcription_service, sourceLanguage, toastStore]);

  // Checks for supported language by selected service
  useEffect(() => {
    if (!values?.translation_service) return;

    const sourceLanguageIds = map(
      translationSupportedLanguages[values?.translation_service as string]?.source_languages,
      'id',
    )
      .join(', ')
      .toLowerCase()
      .split(', ');

    const targetLanguageIds = map(
      translationSupportedLanguages[values?.translation_service as string]?.target_languages,
      'id',
    )
      .join(', ')
      .toLowerCase()
      .split(', ');
    const selectedTargetLanguageIds = values.target_language_ids.join(', ').toLowerCase().split(', ');

    const unsupportedLanguages = difference(selectedTargetLanguageIds, targetLanguageIds);
    const unsupportedLanguageLabels = map(unsupportedLanguages, (id) => getLanguageById(languages, id));

    if (sourceLanguageIds.length && !sourceLanguageIds?.includes(values.source_language_id.toLocaleLowerCase())) {
      toastStore.error(
        `The selected translation service doesn't support the selected source language ${sourceLanguage}.`,
      );
    }

    if (targetLanguageIds.length && unsupportedLanguages.length) {
      toastStore.error(
        `The selected translation service doesn't support the selected target language ${unsupportedLanguageLabels.join(
          ', ',
        )}.`,
      );
    }
  }, [
    values.target_language_ids,
    values.source_language_id,
    values.translation_service,
    sourceLanguage,
    languages,
    toastStore,
  ]);

  const disabledServices = !sourceLanguage || !values.target_language_ids.length;

  return (
    <form onSubmit={onSubmit}>
      {flags.isMm3Assets && (
        <FormRemoteSelect
          name="id"
          label="Source Asset"
          {...formData.id}
          {...handlers}
          onChange={handlers.onChange}
          fetchOptions={fetchSubAssetsOptions}
        />
      )}

      {sourceAsset && <div className="mb-3">Source Language: {sourceLanguage}</div>}

      <FormMultiSelect
        name="target_language_ids"
        label="Target Language"
        {...formData.target_language_ids}
        {...handlers}
        onChange={handlers.onChange}
        options={languagesDataOptions}
        className="mb-0"
      />

      <FormSelect
        name="transcription_service"
        label="Transcription Service"
        {...formData.transcription_service}
        {...handlers}
        options={transcriptionOptions}
        disabled={disabledServices || isSubtitle(sourceAsset as IGenericMm3Asset)}
      />

      <FormSelect
        name="translation_service"
        label="Translation Service"
        {...formData.translation_service}
        {...handlers}
        options={translationOptions}
        disabled={disabledServices}
      />

      <FormSelect
        name="format"
        label="Format"
        className="mb-3"
        {...formData.format}
        {...handlers}
        options={[{ label: 'SRT', value: '.srt' }]}
        disabled
      />

      <FormCheckbox
        name="show_in_player"
        label="Make available in video player"
        {...formData.show_in_player}
        {...handlers}
      />
      <FormSubmitSection submitDisabled={!valid} labels={labels} />
    </form>
  );
};
