import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import { Row } from 'components/label-value-pair';

import { IGroupItem, IProduct, ItemId } from 'types';
import { queryProductGroupItems } from 'utils/apis/groups';

export const useGroupItemInfo = (
  productId?: ItemId | null,
): { groupItem: Partial<IGroupItem> | undefined; groupName: string } => {
  const params = useParams();
  const groupId =
    params?.accessPrivilegeId ||
    params?.mobileSyncId ||
    params?.accessGroupId ||
    params?.guildId ||
    params?.smartGroupId;
  const [groupItem, setGroupItem] = useState<IGroupItem>();

  useEffect(() => {
    if (!productId || !groupId) {
      return;
    }

    queryProductGroupItems(groupId, [productId]).then(({ object }) => setGroupItem(object as IGroupItem));
  }, [groupId, productId]);
  const { include_descendants, delegates_access, permissions, id, include_future_descendants } = groupItem || {};

  let groupName = 'Group';
  const paramsId = Object.keys(params).filter((item) => item.toLowerCase().includes('id'))[0];
  switch (paramsId) {
    case 'accessGroupId':
      groupName = 'Access Group';
      break;
    case 'guildId':
      groupName = 'Guild';
      break;
    case 'smartGroupId':
      groupName = 'Smart Group';
      break;
    case 'accessPrivilegeId':
      groupName = 'Access Privilege';
      break;
    case 'mobileSyncId':
      groupName = 'Mobile Selection';
      break;
  }

  if (params?.mobileSyncId) return { groupItem: { include_descendants, id }, groupName };
  if (params?.guildId)
    return {
      groupItem: { delegates_access, include_descendants, permissions, id },
      groupName,
    };
  if (params?.smartGroupId)
    return {
      groupItem: { include_future_descendants, include_descendants },
      groupName,
    };
  return { groupItem, groupName };
};

export const getIncludedAttribute = (attribute?: boolean): string | undefined => {
  const attributeIsDefined = attribute !== undefined && attribute !== null;
  return attributeIsDefined ? (attribute ? 'Included' : 'Not included') : undefined;
};
export const CustomRow: React.FC<{ product: IProduct }> = ({ product: { default_layer } }) => {
  const { produced_by } = default_layer?.meta || {};

  return <Row rawLabel label="Produced by" value={produced_by} size="m" />;
};
