import React from 'react';
import { FormSelect, IFieldOptions, IFormSelectProps } from 'helpers/form';

const hiddenStatuses = ['archiving', 'unarchiving', 'offline', 'deleted'];

export const filterAssetStatusOptions = (options?: IFieldOptions): IFieldOptions | undefined => {
  return options?.filter((option) => !hiddenStatuses.includes(option));
};

export const FormAssetStatus: React.FC<IFormSelectProps> = (props) => {
  const { options } = props;
  const statusOptions = filterAssetStatusOptions(options as IFieldOptions);

  return <FormSelect {...props} options={statusOptions} />;
};
