import React, { useCallback } from 'react';
import { FormCheckbox, FormMultiSelect, IFormMultiSelectOption } from 'helpers/form';
import { queryAssets } from 'utils/apis/asset';
import { queryAssetSelections } from 'utils/apis/selection';
import { IAsset, IGroup, IIdentifiable, IQueryParams, ISearchFilter } from 'types';
import { parseAssets } from 'components/recommendation/recommend-assets/utils';
import { IGuildFormComponent } from './types';

export const queryAssetsSelections = async (
  params: IQueryParams,
  filters: ISearchFilter[] = [],
): Promise<((IAsset | IGroup) & IIdentifiable)[]> => {
  if (params?.ids?.length === 0) {
    return [];
  }
  return queryAssetSelections(params, filters);
};

export const GuildFormAssets: React.FC<IGuildFormComponent> = ({ form: { formData, handlers } }) => {
  const setAssets = useCallback(
    (options: IFormMultiSelectOption[]): void => {
      handlers.onChange(parseAssets(options));
    },
    [handlers],
  );

  const fetchAssets = useCallback(({ q, ids }: IQueryParams): Promise<(IAsset | IGroup | string)[]> => {
    let assetPromise;
    let selectionPromise;
    if (!ids?.length) {
      assetPromise = queryAssets({ q });
      selectionPromise = queryAssetsSelections({ q });
    } else {
      assetPromise = queryAssets({ ids });
      selectionPromise = queryAssetsSelections({ ids });
    }

    return Promise.all([assetPromise, selectionPromise]).then((result) => result.flatMap((item) => item));
  }, []);

  return (
    <div className="d-flex flex-column h-100">
      <div className="access-group-grant-form-assets">
        <FormMultiSelect
          name="assetList"
          label="Assets"
          fitInParent
          omni
          {...handlers}
          {...formData.assetList}
          onSelectedItemsChange={setAssets}
          fetchValues={fetchAssets}
        />
      </div>
      <FormCheckbox
        label="Permit download"
        name="permitAssetDownload"
        className="mb-0 mt-1"
        {...formData.permitAssetDownload}
        {...handlers}
      />
    </div>
  );
};
