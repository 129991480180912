import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { PmItvstudiosLayer3MotionPictureStandard, PmItvstudiosProduct3 } from '@mediafellows/mm3-types';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import {
  FormMultiSelect,
  FormNumericInput,
  FormSelect,
  FormDate,
  FormInput,
  IFieldValidationResults,
  FormCheckbox,
} from 'helpers/form';

import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { useBasicStore } from 'store/hooks';

import { fetchProducerCompanies } from 'utils/apis/organization';
import { getCommissioning, getCoProducers, getExternalProducers } from './apis';

import { minDate, maxDate } from 'components/product-edit-sections/utils';
import { Product3Types } from 'types/product';

interface IProductEditProductionSectionProps {
  layerForm: IUseMm3FormReturn<PmItvstudiosLayer3MotionPictureStandard>;
  productForm: IUseMm3FormReturn<PmItvstudiosProduct3>;
}

const showBBFC = new Set([Product3Types.PROGRAM, Product3Types.SERIES, Product3Types.FORMAT]);

export const ProductEditProductionSection: React.FC<IProductEditProductionSectionProps> = observer(
  ({ layerForm, productForm }) => {
    const { formData, handlers } = layerForm;
    const {
      values: { type },
    } = productForm;
    const { languagesDataOptions } = useBasicStore();

    const handleChangeCcid = useCallback(
      (newValue) => productForm.handlers.onChange(!newValue['meta.ccid'] ? { ['meta.ccid']: undefined } : newValue),
      [productForm.handlers],
    );

    return (
      <FormSectionWrapperV2 gridTemplateColumns="1fr 1fr" title="Production">
        <FormMultiSelect
          name="meta.producer_organization_ids"
          key="producer_organization_ids"
          label="Produced by (ITV label)"
          rawLabel
          placeholder="Search Producer Organizations..."
          {...formData.meta?.producer_organization_ids}
          {...handlers}
          fetchValues={fetchProducerCompanies}
        />
        <FormMultiSelect
          name="meta.produced_by_external"
          label="Produced by (External)"
          rawLabel
          allowNewItems
          fetchValues={getExternalProducers}
          {...formData.meta?.produced_by_external}
          {...handlers}
          noResults={null}
        />
        <FormMultiSelect
          name="meta.commissioning_broadcasters"
          label="Commissioning Broadcaster"
          allowNewItems
          fetchValues={getCommissioning}
          {...formData.meta?.commissioning_broadcasters}
          {...handlers}
          noResults={null}
        />
        <FormMultiSelect
          name="meta.co_producers"
          label="Co-production Partners"
          rawLabel
          allowNewItems
          fetchValues={getCoProducers}
          {...formData.meta?.co_producers}
          {...handlers}
          noResults={null}
        />
        {type === Product3Types.PROGRAM ? (
          <FormCheckbox
            name="meta.bw_content"
            key="bw_content"
            label="Black & White"
            {...formData.meta?.bw_content}
            {...handlers}
          />
        ) : (
          <></>
        )}
        {showBBFC.has(type as Product3Types) ? (
          <FormSelect
            emptyValue={null}
            name="meta.bbfc"
            key="meta.bbfc"
            label="BBFC"
            {...formData.meta?.bbfc}
            {...handlers}
            large
          />
        ) : (
          <></>
        )}
        <FormMultiSelect
          name="meta.resolutions"
          key="resolutions"
          label="Resolutions"
          {...formData.meta?.resolutions}
          {...handlers}
        />
        <FormMultiSelect
          name="meta.language_ids"
          key="language_ids"
          label="Languages"
          {...formData.meta?.language_ids}
          {...handlers}
          options={languagesDataOptions}
        />
        <FormSelect
          name="meta.product_state"
          key="product_state"
          label="Production Status"
          {...formData.meta?.product_state}
          {...handlers}
          emptyValue={null}
          large
        />
        <FormNumericInput
          name="meta.year_of_production"
          key="year_of_production"
          label="Year of Production"
          {...formData.meta?.year_of_production}
          {...handlers}
        />
        <FormDate
          name="meta.production_start"
          key="production_start"
          label="Production Start"
          minDate={minDate}
          maxDate={maxDate}
          {...formData.meta?.production_start}
          {...handlers}
          emptyValue={null}
          large
        />
        <FormDate
          name="meta.production_end"
          key="production_end"
          label="Production End"
          minDate={minDate}
          maxDate={maxDate}
          {...formData.meta?.production_end}
          {...handlers}
          emptyValue={null}
          large
        />
        <FormDate
          name="meta.due_delivery_date"
          key="due_delivery_date"
          label="Due Delivery Date"
          minDate={minDate}
          maxDate={maxDate}
          {...formData.meta?.due_delivery_date}
          {...handlers}
          emptyValue={null}
          large
        />
        <FormInput
          name="meta.ccid"
          key="ccid"
          label="CCID"
          {...productForm.formData.meta?.ccid}
          {...productForm.handlers}
          validation={{
            ...(productForm.formData.meta?.ccid?.validation as IFieldValidationResults),
            errorMessage: 'Must be 7 alphanumeric characters',
          }}
          onChange={handleChangeCcid}
        />
        <FormInput
          name="meta.paris_id"
          key="paris_id"
          label="ParisID"
          {...productForm.formData.meta?.paris_id}
          {...productForm.handlers}
        />
      </FormSectionWrapperV2>
    );
  },
);

export default ProductEditProductionSection;
