import React from 'react';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';

import { FormInput, FormAccessLevel } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { ShowcaseCommonForm } from 'components/showcase-form/showcase-common-parts';

import { IWebsitePageValues } from 'types';

const AdvertisementFormFirstStep: React.FC<
  IUseMm3FormReturn<UmGenericListShowcase> & {
    hideNextButton?: boolean;
    section?: IWebsitePageValues;
  }
> = ({ handlers, formData, onSubmit, valid, values, section, hideNextButton = false }) => {
  return (
    <ShowcaseCommonForm
      handlers={handlers}
      formData={formData}
      onSubmit={onSubmit}
      valid={valid}
      values={values}
      hideNextButton={hideNextButton}
      section={section}
    >
      <FormAccessLevel large name="access_level" label="Access level" {...handlers} {...formData?.access_level} />
      <FormInput textarea name="meta.description" label="Description" {...handlers} {...formData?.meta?.description} />
    </ShowcaseCommonForm>
  );
};

export default AdvertisementFormFirstStep;
