import React from 'react';
import { uniqBy } from 'lodash';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';

import { IMobileAppSyncForm } from 'components/mobile-app-sync-wizard/types';
import { flags } from 'utils/flags';
import { getIncludedAttribute } from 'utils/ui';

export const MobileAppSyncFormSummary: React.FC<{ form: IMobileAppSyncForm }> = ({ form }) => {
  const {
    name,
    access_level,
    include_descendants,
    product_ids = [],
    descendants_ids,
    include_related_products,
    relatedProductIds,
  } = form.values || {};

  const allAssetIds = uniqBy([...(form.values.asset_ids || []), ...(form.values.independentAssetIds || [])], (n) =>
    n.toString(),
  );

  const assetIdsCount = allAssetIds.length || 0;
  const productsCount =
    product_ids.length +
    (include_descendants ? descendants_ids?.length || 0 : 0) +
    (flags.showRelatedProductsFeature && include_related_products ? relatedProductIds?.length || 0 : 0);

  return (
    <FormSectionWrapperV2 padded={false} className="d-flex flex-column">
      <Row
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border"
        label="Name"
        value={name}
      />
      <Row
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border"
        label="Access level"
        value={access_level}
      />
      <Row
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border"
        label="Products"
        value={productsCount}
      />
      <Row
        label="Descendant Products"
        value={getIncludedAttribute(include_descendants)}
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border"
      />

      <Row
        label="Related Products"
        value={getIncludedAttribute(include_related_products)}
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border"
        hidden={!flags.showRelatedProductsFeature}
      />
      <Row
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border"
        label="Assets"
        value={assetIdsCount}
      />
    </FormSectionWrapperV2>
  );
};
