import { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import { AmGenericAsset3DigitalSubtitle } from '@mediafellows/mm3-types';
import { IGroupItem, ILanguage, ISubtitle, ItemId } from 'types';
import { getLanguageById } from 'utils/general';
import { queryAssetGroupItems } from 'utils/apis/groups';

export const parseSubtitles = (type: string, languages: ILanguage[], subtitles?: ISubtitle[]): string => {
  return (
    subtitles
      ?.filter(({ purpose }) => purpose === type)
      .map(({ language_id }) => getLanguageById(languages, language_id))
      .join(', ') || ''
  );
};

export const parseMm3Subtitles = (
  type: string,
  languages: ILanguage[],
  subtitles?: AmGenericAsset3DigitalSubtitle[],
): string => {
  return (
    subtitles
      ?.reduce((acc: string[], { meta }) => {
        if (meta?.purpose === type && meta?.language_id) {
          acc.push(getLanguageById(languages, meta.language_id));
        }
        return acc;
      }, [])
      .join(', ') || ''
  );
};

export const useGroupAssetItemInfo = (
  assetId?: ItemId | null,
): { groupItem: Partial<IGroupItem> | undefined; groupName: string } => {
  const params = useParams();
  const groupId = params?.accessGroupId || params?.guildId || params?.smartGroupId;

  const [groupItem, setGroupItem] = useState<IGroupItem>();

  useEffect(() => {
    if (!assetId || !groupId) {
      return;
    }

    queryAssetGroupItems(groupId, [assetId]).then(({ object }) => setGroupItem(object as IGroupItem));
  }, [groupId, assetId]);
  let groupName = 'Group';
  const paramsId = Object.keys(params).filter((item) => item.toLowerCase().includes('id'))[0];

  switch (paramsId) {
    case 'accessGroupId':
      groupName = 'Access Group';
      break;
    case 'guildId':
      groupName = 'Guild';
      break;
    case 'smartGroupId':
      groupName = 'Smart Group';
      break;
  }
  return { groupItem, groupName };
};
