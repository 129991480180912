import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormsStore } from 'store';
import cx from 'classnames';
import { useNavigate } from 'react-router';

import { ISectionHeaderAction, SectionHeader } from 'components/section-header';
import { ProductActionsList } from 'components/action';
import { ProductAncestryBreadcrumbs as AncestryBreadcrumbs } from 'components/breadcrumb-product-ancestry';

import { DetailsPageTabs, IProduct, IProductAncestryInfo, ViewMode } from 'types';
import { Pages } from 'utils/actions/types';
import { changeProductAccessImmediate } from 'utils/actions/product/api';
import { IProductActionName } from 'utils/actions/product/types';
import { changeAccessLevelSlider } from 'utils/change-access';
import { getProductSubtitle } from 'components/product/product-translated-title/get-product-translated-title';

import { Routes } from 'utils/routes';
import { hasAncestry } from 'utils/general';
import { formatType } from 'utils/format-type';
import { productDetailsSchema } from 'utils/schemas/product';
import { Classes } from 'utils/ui';

import './style.scss';
import { ActionsMenu } from 'components/action/utils';

const page = Pages.DETAILS;

interface IProductDetailsHeaderProps {
  product: IProduct;
  setProduct: React.Dispatch<React.SetStateAction<IProduct>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  mode: ViewMode;
  setMode: React.Dispatch<React.SetStateAction<ViewMode>>;
  ancestry: IProductAncestryInfo;
  refreshAncestry: () => Promise<void>;
  refreshProduct: () => Promise<void>;
}

const ProductDetailsHeader: React.FC<IProductDetailsHeaderProps> = observer(
  ({ product, setProduct, setActiveTab, ancestry, mode, setMode, refreshAncestry, refreshProduct }) => {
    const { id, inherited_preview_image, access_level, division_ids, type, default_layer } = product || {};

    const { forms } = useFormsStore();
    const { productForm, layerForm } = forms || {};

    const navigate = useNavigate();
    const onSuccess = useCallback(
      async (action: IProductActionName, product: IProduct, layer): Promise<void> => {
        switch (action) {
          case IProductActionName.ASSOCIATE:
            setProduct((p) => ({ ...p, meta: { ...p.meta, related_product_ids: product.meta.related_product_ids } }));
            setActiveTab(DetailsPageTabs.RELATED);
            break;
          case IProductActionName.ADD_CAST_CREW:
            setProduct((p) => ({ ...p, default_layer: layer }));
            setActiveTab(DetailsPageTabs.CAST_CREW);
            break;
          case IProductActionName.CHANGE_ACCESS:
            refreshProduct();
            break;
          case IProductActionName.DELETE:
            navigate(`${Routes.PRODUCTS}`);
            break;
          case IProductActionName.ASSIGN_ASSET_TO_SELECTION:
          case IProductActionName.CHANGE_ACCESS:
            setProduct(product);
            break;
          case IProductActionName.REASSIGN_PARENT:
            refreshAncestry();
            break;
        }
      },
      [refreshAncestry, navigate, setActiveTab, refreshProduct, setProduct],
    );

    const handleAccessLevelChange = useCallback(
      async (newAccessLevel: string) => {
        return await changeAccessLevelSlider<IProduct>({
          apiCall: (data) => changeProductAccessImmediate({ ...data, item_ids: [id] }, productDetailsSchema),
          divisionIds: division_ids,
          newAccessLevel,
          entitySetter: setProduct,
        });
      },
      [division_ids, id, setProduct],
    );

    const customLeftRenderer = (): JSX.Element => {
      if (!default_layer?.title) {
        return <></>;
      }
      const showBreadcrumbs = hasAncestry(ancestry);
      const subTitle = getProductSubtitle(product);

      return (
        <div className="my-0 d-flex flex-column">
          {showBreadcrumbs && <AncestryBreadcrumbs ancestryInfo={ancestry} layout="detail" productId={id} />}
          <div className="mt-1 d-flex">
            <h3 className="product-details__header-title">
              {default_layer.title}
              {Boolean(subTitle) ? (
                <>
                  &nbsp; | &nbsp;
                  <span className="product-details__header__subtitle">{subTitle}</span>
                </>
              ) : (
                <></>
              )}
            </h3>
          </div>
          {type && <span className={`${Classes.TEXT_MUTED}`}>{formatType(type)}</span>}
        </div>
      );
    };

    const actions =
      mode === ViewMode.PREVIEW
        ? [
            {
              type: 'edit',
              handler: () => {
                setMode(ViewMode.EDIT);
              },
            },
          ]
        : [
            {
              type: 'save',
              disabled: !productForm?.valid || !layerForm?.valid,
              handler: productForm?.onSubmit,
            },
            {
              type: 'cancel',
              handler: async () => {
                setMode(ViewMode.PREVIEW);
                productForm?.resetFields();
                layerForm?.resetFields();
              },
            },
          ];

    return (
      <div>
        <SectionHeader
          className={cx({ 'section-header--product-with-image': inherited_preview_image })}
          onAccessLevelChange={handleAccessLevelChange}
          accessLevel={access_level}
          actions={actions as ISectionHeaderAction[]}
          useBackground
          dropdownMenu={
            <ActionsMenu
              component={ProductActionsList}
              items={[product]}
              options={{
                context: 'single',
                page,
                onSuccess,
              }}
            />
          }
          customLeftRenderer={customLeftRenderer}
          hideDivisionAccessLevel={false}
        />
      </div>
    );
  },
);

export default ProductDetailsHeader;
