import './assets/styles/main.scss';
import React, { useEffect } from 'react';
import { setRootStore, storeContext } from 'store';
import { RootStore } from 'store/root-store';
import { AppRoutes } from 'routes';
import { FocusStyleManager } from '@blueprintjs/core';
import { MantineColorScheme, MantineProvider } from '@mantine/core';
import { Globals } from 'types';
import config from 'config';
import { playerConfig } from '@mediafellows/videoplayer';
import { chipmunk } from 'utils/chipmunk';
import { createRoot } from 'react-dom/client';
import { theme, variantColorResolver } from 'theme';
import { Notifications } from '@mantine/notifications';

FocusStyleManager.onlyShowFocusOnTabs();

if (localStorage.getItem(Globals.THEME_LIGHT_LS)) {
  document.body.classList.remove(Globals.MODE_DARK_CLASS);
}

if (localStorage.getItem(Globals.SIDEBAR_COLLAPSED_LS)) {
  document.body.classList.add(Globals.SIDEBAR_COLLAPSED_CLASS);
}

if (localStorage.getItem(Globals.DATASECTION_SIDEBAR_COLLAPSED_LS)) {
  document.body.classList.add(Globals.DATASECTION_SIDEBAR_COLLAPSED_CLASS);
}

export const StoreProvider = ({ children }): JSX.Element => {
  const store = setRootStore(new RootStore());

  store.customization = config;

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

const App = (): React.ReactElement => {
  useEffect(() => {
    playerConfig({
      chipmunk,
      analytics: false,
      subtitles: true,
    });
  }, []);

  return (
    <StoreProvider>
      <MantineProvider theme={{ ...theme, variantColorResolver }} defaultColorScheme={'dark' as MantineColorScheme}>
        <Notifications position="top-right" />
        <AppRoutes />
      </MantineProvider>
    </StoreProvider>
  );
};

const container = document.getElementById('app') as HTMLElement;
const root = createRoot(container);
root.render(<App />);

if (module['hot']) {
  module['hot'].accept();
}
