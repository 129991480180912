import {
  videoViews as productScreeningQueries,
  productPageViews as productViewsQueries,
} from 'components/analytics/queries/product-queries';
import { IAnalyticsPageValues, IUserRole, DetailsPageTabs, IAnalyticsFilters, IContact } from 'types';
import { Pages } from 'utils/actions/types';
import { IAnalyticsEntity, IAnalyticsEntityValues } from 'types/analytics';
import { flags } from 'utils/flags';

export enum ProductInteractions {
  SCREENINGS = 'Video views',
  VIEWS = 'Product page views',
  // DOWNLOADS = 'downloads', TODO
}

export enum RecommendationInteractions {
  TOP_RECIPIENTS = 'Top Recipients',
  TOP_SENDERS = 'Top Senders',
}

export const productInteractionToQuery = {
  [ProductInteractions.VIEWS]: productViewsQueries.productList,
  [ProductInteractions.SCREENINGS]: productScreeningQueries.screeningList,
};

export const productInteractionOptions = Object.values(ProductInteractions);

export enum AssetInteractions {
  SCREENINGS = 'Video views',
  // DOWNLOADS = 'downloads', TODO
}

export const assetInteractionOptions = Object.values(AssetInteractions);

export enum EventInteractions {
  VIDEO_SCREENINGS = 'Video views',
  PRODUCT_VIEWS = 'Product page views',
  ATTENDANCE = 'Attendance',
  // DOWNLOADS = 'downloads', TODO
}
export const eventInteractionOptions = Object.values(EventInteractions);

export enum ContactInteractions {
  SCREENINGS = 'Video views ',
  VIEWS = 'Product page views',
  LOGINS = 'Logins',
  RECOMMENDATIONS_RECEIVED = 'Recommendations received',
  RECOMMENDATIONS_SENT = 'Recommendations sent',
  RECOMMENDATIONS_DETAILS = 'Recommendations',
  // if new options are added make sure to fix logic in getContactInteractionOptions
  // DOWNLOADS = 'downloads', TODO
  // PRODUCT = 'Product', TODO
}

export enum RecommendationDetailsInteractions {
  RECIPINETS = 'By Recipients',
  PRODUCT_ASSET = 'By Product/Asset',
}

export const recommendationDetailsInteractionsOptions = Object.values(RecommendationDetailsInteractions);

export enum RecommendationInteractions {
  RECOMMENDATIONS_DETAILS = 'Recommendations details',
}

export const contactShowProductsFilterOptions = [ContactInteractions.SCREENINGS, ContactInteractions.VIEWS];
export const contactInteractionOptions = [
  ...contactShowProductsFilterOptions,
  ContactInteractions.LOGINS,
  ContactInteractions.RECOMMENDATIONS_RECEIVED,
];
const contactRecoInteractions = [
  ContactInteractions.RECOMMENDATIONS_RECEIVED,
  ContactInteractions.RECOMMENDATIONS_SENT,
  ContactInteractions.RECOMMENDATIONS_DETAILS,
];

export const recommendationInteractionOptions = [RecommendationInteractions.RECOMMENDATIONS_DETAILS];

function filterRecommendationOptions<T>({
  interactions,
  recoInteractions,
}: {
  interactions: T[];
  recoInteractions: T[];
}): T[] {
  const isRecommendationFeatureEnabled = flags.showRecommendationFeature || flags.showAssetRecommendationsFeature;
  return isRecommendationFeatureEnabled
    ? interactions
    : interactions.filter((interaction) => !recoInteractions.includes(interaction));
}
export const getContactInteractionOptions = (role?: IUserRole, page?: Pages): ContactInteractions[] => {
  if (page === Pages.LIST) {
    return filterRecommendationOptions<ContactInteractions>({
      interactions: [
        ...contactShowProductsFilterOptions,
        ContactInteractions.LOGINS,
        ContactInteractions.RECOMMENDATIONS_DETAILS,
      ],
      recoInteractions: contactRecoInteractions,
    });
  }
  if (role?.designation.toLowerCase() === 'all') {
    return filterRecommendationOptions<ContactInteractions>({
      interactions: Object.values(ContactInteractions).filter(
        (interaction) => interaction !== ContactInteractions.RECOMMENDATIONS_DETAILS,
      ),
      recoInteractions: contactRecoInteractions,
    });
  }

  return filterRecommendationOptions<ContactInteractions>({
    interactions: contactInteractionOptions,
    recoInteractions: contactRecoInteractions,
  });
};

export const interactionOptions = {
  [IAnalyticsPageValues.ANALYTICS_PRODUCTS]: productInteractionOptions,
  [IAnalyticsPageValues.ANALYTICS_ASSETS]: assetInteractionOptions,
  [IAnalyticsPageValues.ANALYTICS_CONTACTS]: contactInteractionOptions,
  [IAnalyticsPageValues.ANALYTICS_RECOMMENDATION]: recommendationInteractionOptions,
};

export type IAnalyticsInteractionOption =
  | ProductInteractions
  | AssetInteractions
  | ContactInteractions
  | RecommendationInteractions
  | EventInteractions
  | RecommendationDetailsInteractions;

export const getSectionInteractionOptions = (
  section: IAnalyticsPageValues,
  user?: IContact | null,
  page?: Pages,
): IAnalyticsInteractionOption[] => {
  let options = interactionOptions[section];

  if (sectionToEntity[section] === IAnalyticsEntityValues.CONTACT && user) {
    options = getContactInteractionOptions(user?.role, page);
  }
  return options;
};

export const sectionToEntity = {
  [IAnalyticsPageValues.ANALYTICS_PRODUCTS]: IAnalyticsEntityValues.PRODUCT,
  [IAnalyticsPageValues.ANALYTICS_ASSETS]: IAnalyticsEntityValues.ASSET,
  [IAnalyticsPageValues.ANALYTICS_CONTACTS]: IAnalyticsEntityValues.CONTACT,
  [IAnalyticsPageValues.ANALYTICS_RECOMMENDATION]: IAnalyticsEntityValues.RECOMMENDATION,
};

export interface IAnalyticsFiltersToApplyParams extends IAnalyticsFilters {
  section?: IAnalyticsPageValues;
  tab: DetailsPageTabs;
  interaction?: string;
  entity?: IAnalyticsEntity | string;
}

export const getAnalyticsFiltersToApply = (params: IAnalyticsFiltersToApplyParams): IAnalyticsFilters => {
  const {
    dateRange,
    userIds,
    geoScopeIds,
    productIds,
    includeDescendants,
    section,
    tab,
    interaction,
    entity,
    assetIds,
    includeNonScreeningsAssets,
    responsibleUserId,
  } = params;

  const validFilters: IAnalyticsFilters = {
    dateRange,
  };

  // @todo improve this function
  const isRecommendation =
    section === IAnalyticsPageValues.ANALYTICS_RECOMMENDATION ||
    interaction === ContactInteractions.RECOMMENDATIONS_DETAILS;

  const showContactFilter =
    !isRecommendation &&
    (Boolean(section) || ((entity === 'product' || entity === 'asset') && tab === DetailsPageTabs.ANALYTICS));

  const showProductFilterContactOnInteraction = [ContactInteractions.VIEWS, ContactInteractions.SCREENINGS].includes(
    interaction as ContactInteractions,
  );
  const showProductFilter =
    (section === IAnalyticsPageValues.ANALYTICS_CONTACTS &&
      (tab === DetailsPageTabs.LIST ||
        (tab === DetailsPageTabs.DETAILED_STATS && interaction && showProductFilterContactOnInteraction))) ||
    (section === IAnalyticsPageValues.ANALYTICS_ASSETS && tab !== DetailsPageTabs.DETAILED_STATS) ||
    (entity === 'user' && tab === DetailsPageTabs.ANALYTICS && interaction && showProductFilterContactOnInteraction) ||
    (section === IAnalyticsPageValues.ANALYTICS_PRODUCTS &&
      (tab === DetailsPageTabs.DETAILED_STATS || tab === DetailsPageTabs.LIST));

  const showIncludeDescendantsFilter = showProductFilter || (entity === 'product' && tab === DetailsPageTabs.ANALYTICS);
  const showIncludeNonScreeningAssetsFilter = interaction === RecommendationDetailsInteractions.PRODUCT_ASSET;

  const showTerritoriesFilter =
    section === IAnalyticsPageValues.ANALYTICS_ASSETS ||
    section === IAnalyticsPageValues.ANALYTICS_PRODUCTS ||
    section === IAnalyticsPageValues.ANALYTICS_CONTACTS ||
    DetailsPageTabs.ANALYTICS;

  const showAssetsFilter = section === IAnalyticsPageValues.ANALYTICS_ASSETS && tab === DetailsPageTabs.DETAILED_STATS;

  validFilters.userIds = showContactFilter ? userIds : undefined;
  validFilters.geoScopeIds = showTerritoriesFilter ? geoScopeIds : undefined;
  validFilters.productIds = showProductFilter ? productIds : undefined;
  validFilters.includeDescendants = showIncludeDescendantsFilter ? includeDescendants : undefined;
  validFilters.assetIds = showAssetsFilter ? assetIds : undefined;
  validFilters.recipientIds = isRecommendation ? params.recipientIds : undefined;
  validFilters.senderIds = isRecommendation ? params.senderIds : undefined;
  validFilters.includeNonScreeningsAssets = showIncludeNonScreeningAssetsFilter
    ? includeNonScreeningsAssets
    : undefined;
  validFilters.responsibleUserId = showContactFilter ? responsibleUserId : undefined;

  return validFilters;
};
