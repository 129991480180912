import React from 'react';
import { observer } from 'mobx-react-lite';
import { Tabs } from '@mantine/core';
import cx from 'classnames';

import { DataSectionSelectedTab } from 'components/data-section-selected-tab';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useStore } from 'store';
import {
  DataSectionSidebarTab,
  DataSectionSidebarTabPanel,
  DataSectionSidebarTabs,
  IDataSectionSidebarTabs,
  getDefaultTab,
} from './utils';

import './style.scss';

interface IProductSidebarProps {
  className?: string;
  contextMenu?: React.ReactElement;
  tabs?: IDataSectionSidebarTabs;
}

const DataSectionSidebar: React.FC<IProductSidebarProps> = observer(({ className, contextMenu, tabs }) => {
  const {
    dataSectionStore: { checked, active, activeTab, updateStore, searchStore, isAllItemsSelected },
  } = useStore();

  const { totalCount, paramsAndFiltersCount } = searchStore || {};

  const handleTabChange = (tab): void => {
    updateStore({ activeTab: tab });
  };
  return (
    <div className={cx('data-section-sidebar', className)}>
      <Tabs
        className="data-section-sidebar__tabs"
        value={activeTab}
        onChange={handleTabChange}
        defaultValue={getDefaultTab(active, tabs)}
        keepMounted
      >
        <Tabs.List key={'list'} grow>
          <DataSectionSidebarTab
            key={DataSectionSidebarTabs.PREVIEW}
            value={DataSectionSidebarTabs.PREVIEW}
            activeTab={activeTab}
            disabled={!active}
            hidden={!tabs?.preview}
          />
          <DataSectionSidebarTab
            key={DataSectionSidebarTabs.SELECTED_ITEMS}
            value={DataSectionSidebarTabs.SELECTED_ITEMS}
            activeTab={activeTab}
            disabled={checked?.length < 1}
            hidden={!tabs?.selectedItemRenderer}
            title="Selected"
            rightSectionText={isAllItemsSelected ? totalCount : checked.length}
          />
          <DataSectionSidebarTab
            key={DataSectionSidebarTabs.FILTERS}
            value={DataSectionSidebarTabs.FILTERS}
            activeTab={activeTab}
            disabled={false}
            hidden={!tabs?.filters}
            title="Filters"
            rightSectionText={paramsAndFiltersCount}
          />
        </Tabs.List>

        <DataSectionSidebarTabPanel
          key={DataSectionSidebarTabs.PREVIEW}
          value={DataSectionSidebarTabs.PREVIEW}
          content={<div className="data-section-sidebar__content">{!!tabs?.preview && tabs.preview}</div>}
        />
        <DataSectionSidebarTabPanel
          key={DataSectionSidebarTabs.SELECTED_ITEMS}
          value={DataSectionSidebarTabs.SELECTED_ITEMS}
          content={
            <div className="data-section-sidebar__content">
              {!!tabs?.selectedItemRenderer && (
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <DndProvider backend={HTML5Backend} context={window}>
                    <DataSectionSelectedTab tabs={tabs} contextMenu={contextMenu} />
                  </DndProvider>
                </div>
              )}
            </div>
          }
        />
        <DataSectionSidebarTabPanel
          key={DataSectionSidebarTabs.FILTERS}
          value={DataSectionSidebarTabs.FILTERS}
          content={<div className="data-section-sidebar__content">{!!tabs?.filters && tabs.filters}</div>}
        />
      </Tabs>
    </div>
  );
});

export default DataSectionSidebar;
