import React, { useCallback } from 'react';

import { useStore } from 'store';

import { FormAddToAccessPrivilegeItems } from './form-add-to-access-privilege-items';

import { getEntityLabel } from 'utils/general';
import { formatGroupName } from 'utils/group';

import { GroupTypes } from 'types';

import type { IAddToGroupForm, IUseSelectionDialogProps } from 'components/add-to-group-dialog/types';
import { addToAccessPrivilegeOnConfirm } from './utils';

export const useAddToAccessPrivilegeDialog = ({
  entityType,
  items,
  group,
  options,
  groupType = GroupTypes.ACCESS_PRIVILEGE,
  title,
  passPropsFromExistingItems,
  hideProductsParams,
  groupLabel,
  addSelectedProductAncestors = false,
  withEmailNotification = false,
  omni = false,
  initialValues,
}: IUseSelectionDialogProps): [() => void] => {
  const { dialogStore, toastStore } = useStore();
  const groupTitle = groupLabel ?? formatGroupName(groupType);
  const entityLabel = getEntityLabel(entityType);

  const onConfirm = useCallback(
    async (values: IAddToGroupForm): Promise<void> => {
      addToAccessPrivilegeOnConfirm(
        values,
        dialogStore,
        toastStore,
        entityLabel,
        groupTitle,
        { group, passPropsFromExistingItems, options, addSelectedProductAncestors },
        entityType,
        withEmailNotification,
      );
    },
    [
      dialogStore,
      toastStore,
      entityLabel,
      groupTitle,
      group,
      passPropsFromExistingItems,
      options,
      addSelectedProductAncestors,
      entityType,
      withEmailNotification,
    ],
  );

  const defaultTitle = `Add ${entityLabel}(s) to ${group?.id ? 'this ' : ''}${groupTitle}`;

  const body = useCallback((): JSX.Element => {
    return (
      <FormAddToAccessPrivilegeItems
        items={items}
        group={group}
        onConfirm={onConfirm}
        onCancel={dialogStore.close}
        entity={entityType}
        groupType={groupType}
        groupLabel={groupLabel}
        hideProductsParams={hideProductsParams}
        addSelectedProductAncestors={addSelectedProductAncestors}
        withEmailNotification={withEmailNotification}
        omni={omni}
        initialValues={initialValues}
      />
    );
  }, [
    items,
    group,
    onConfirm,
    entityType,
    groupType,
    hideProductsParams,
    groupLabel,
    addSelectedProductAncestors,
    withEmailNotification,
    omni,
    dialogStore,
    initialValues,
  ]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: title ?? defaultTitle,
      body,
    });
  };

  return [openDialog];
};
