import React, { useState, useMemo } from 'react';
import { Tabs } from '@mantine/core';

import {
  GroupDetailProductsTab,
  GroupDetailContactsTab,
  GroupDetailAssetsTab,
} from 'components/marketing-entity-detail';
import { SortButton } from 'components/sort-buttons';
import { EmptySectionMessage } from 'components/section-message/section-message';
import { OrganizationDataSection } from 'components/organization';
import { DataSectionControls } from 'components/data-section-controls';
import { TabButtonsWrapper } from 'components/tabs';

import { Pages } from 'utils/actions/types';

import { IAccessGroup, DetailsPageTabs } from 'types';
import { ITitleDividerActionName } from 'utils/actions/types';
import { IFilterOption } from 'utils/hooks';
import { AccessGroupDetailTab, AccessGroupTabPanel } from './utils';
import { AddItemsToAccessGroupButton } from './tab-buttons';

const options = {
  page: Pages.ACCESS_GROUP_DETAILS,
  groupName: ITitleDividerActionName.ACCESS_GROUP,
  groupParamName: 'accessGroupId',
};

interface IAccessGroupDetailsTabsView {
  group?: IAccessGroup;
  editModeEnabled?: boolean;
  currentTab: DetailsPageTabs;
  onTabChangeHandle: (DetailsPageTabs) => void;
  onSuccess: () => void;
}

const showDataSectionButtons = (tab: DetailsPageTabs): boolean =>
  [DetailsPageTabs.CONTACTS, DetailsPageTabs.ASSETS, DetailsPageTabs.PRODUCTS, DetailsPageTabs.ORGANIZATIONS].includes(
    tab,
  );

const canAddItemsToTab = (tab: DetailsPageTabs): boolean =>
  [DetailsPageTabs.PRODUCTS, DetailsPageTabs.CONTACTS, DetailsPageTabs.ORGANIZATIONS, DetailsPageTabs.ASSETS].includes(
    tab,
  );

export const AccessGroupDetailsTabsView: React.FC<IAccessGroupDetailsTabsView> = ({
  group,
  currentTab,
  onTabChangeHandle,
  editModeEnabled,
  onSuccess,
}) => {
  const [isSortModeEnabled, setIsSortModeEnabled] = useState<boolean>(false);
  const { id } = group || {};

  const groupPersistentFilters = useMemo<IFilterOption[]>(() => (id ? [['group_ids', 'eq', id]] : []), [id]);

  const actionsOptions = useMemo(
    () => ({
      ...options,
      groupId: id,
      onSuccess,
    }),
    [id, onSuccess],
  );

  if (!id || !group) {
    return <EmptySectionMessage />;
  }

  return (
    <Tabs value={currentTab} onChange={onTabChangeHandle} className="recommendation-details__tabs h-100">
      <Tabs.List className="d-flex flex-nowrap">
        <AccessGroupDetailTab
          value={DetailsPageTabs.PRODUCTS}
          isSortModeEnabled={isSortModeEnabled}
          editModeEnabled={editModeEnabled}
          activeTab={currentTab}
        />
        <AccessGroupDetailTab
          value={DetailsPageTabs.ASSETS}
          isSortModeEnabled={isSortModeEnabled}
          editModeEnabled={editModeEnabled}
          activeTab={currentTab}
        />
        <AccessGroupDetailTab
          value={DetailsPageTabs.CONTACTS}
          activeTab={currentTab}
          isSortModeEnabled={isSortModeEnabled}
          editModeEnabled={editModeEnabled}
        />
        <AccessGroupDetailTab
          value={DetailsPageTabs.ORGANIZATIONS}
          activeTab={currentTab}
          editModeEnabled={editModeEnabled}
          isSortModeEnabled={isSortModeEnabled}
        />
        <TabButtonsWrapper>
          <SortButton
            isSortModeEnabled={isSortModeEnabled}
            setIsSortModeEnabled={setIsSortModeEnabled}
            hidden={currentTab !== DetailsPageTabs.PRODUCTS}
          />

          <AddItemsToAccessGroupButton
            disabled={isSortModeEnabled}
            accessGroup={group}
            currentTab={currentTab}
            onSuccess={onSuccess}
            hidden={!canAddItemsToTab(currentTab)}
          />

          <DataSectionControls
            disabled={isSortModeEnabled}
            customRefresh={onSuccess}
            hidden={!showDataSectionButtons(currentTab)}
          />
        </TabButtonsWrapper>
      </Tabs.List>
      <AccessGroupTabPanel
        value={DetailsPageTabs.PRODUCTS}
        content={
          <GroupDetailProductsTab
            isSortModeEnabled={isSortModeEnabled}
            showParentsOnlyFilter
            parentFilterDefaultValue={false}
            groupId={id}
            options={actionsOptions}
            page={Pages.ACCESS_GROUP_DETAILS_PRODUCT_TAB}
          />
        }
      />
      <AccessGroupTabPanel
        value={DetailsPageTabs.ASSETS}
        content={
          <GroupDetailAssetsTab isSortModeEnabled={isSortModeEnabled} groupId={id} actionOptions={actionsOptions} />
        }
      />
      <AccessGroupTabPanel
        value={DetailsPageTabs.CONTACTS}
        content={<GroupDetailContactsTab groupId={id} options={actionsOptions} page={Pages.ACCESS_GROUP_DETAILS} />}
      />
      <AccessGroupTabPanel
        value={DetailsPageTabs.ORGANIZATIONS}
        content={<OrganizationDataSection actionsOptions={actionsOptions} persistentFilters={groupPersistentFilters} />}
      />
    </Tabs>
  );
};
