import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { AmGenericNews } from '@mediafellows/mm3-types';
import { IObject } from '@mediafellows/chipmunk/dist/src/action';

import { LoaderWithText, ThumbnailWithRefresh } from 'components/thumbnail';
import { PreviewInfo, PreviewTab } from 'components/preview-tab';
import { useDataSectionStore } from 'store';
import { IconAlias } from 'icons';
import { changeAccessLevelSlider } from 'utils/change-access';
import { Row } from 'components/label-value-pair';

import { dateWithoutHours, formatDate, formatStringDate } from 'utils/date';
import { ReadMore } from 'components/read-more';
import { updateNews } from 'utils/apis/news';
import { CustomRow, CustomTextRow, CustomTitleRow } from 'components/news/news-data-section/preview-tab/custom-row';
import { useEntityOwnership } from 'utils/hooks/use-divisions';

export const NewsPreviewTab: React.FC = observer(() => {
  const { activeItem } = useDataSectionStore<AmGenericNews>();
  const news = activeItem;
  const id = news?.id;

  const handleAccessLevelChange = useCallback(
    async (newAccessLevel: string): Promise<boolean | IObject> => {
      if (!id) {
        return false;
      }

      return changeAccessLevelSlider({
        apiCall: async ({ access_level }) => {
          const object = await updateNews({ id, access_level } as Pick<AmGenericNews, 'id' | 'access_level'>);
          return { object, objects: [object] };
        },
        newAccessLevel,
      });
    },
    [id],
  );
  const { access_level, created_at, date, meta, preview_image, title, updated_at, preview_image_id, owner } =
    news || {};
  const { ownershipInfo, loadingOwnership } = useEntityOwnership(news, owner?.division_ids);
  if (!news?.id) {
    return <span className="entity-preview-tab__no-preview">No preview</span>;
  }

  return (
    <PreviewTab
      title={title}
      accessLevel={access_level}
      handleAccessLevelChange={handleAccessLevelChange}
      customThumbnail={
        <ThumbnailWithRefresh
          fallbackIconName={IconAlias.NEWS}
          entity={{ preview_image_id, preview_image }}
          placeholder={<LoaderWithText text="processing" />}
        />
      }
    >
      <PreviewInfo gridTemplateColumns="90px 1fr">
        <Row label="Title" value={title} />
        <CustomTitleRow meta={meta} />
        <Row label="News Date" value={formatDate(formatStringDate(date), dateWithoutHours)} />
        <Row label="Location" value={meta?.location} size="m" />
        <CustomRow meta={meta} />
        <Row label="Ownership" value={ownershipInfo} loading={loadingOwnership} />
        <Row label="Created at" rawLabel value={formatDate(created_at)} size="m" />
        <Row label="Last Updated" value={formatDate(updated_at)} size="m" />
      </PreviewInfo>
      <ReadMore className="mx-3 pb-3" label="Text" value={meta?.content} />
      <CustomTextRow meta={meta} />
    </PreviewTab>
  );
});
