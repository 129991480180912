import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import ScrollWrapper from 'components/scroll-wrapper';

import { FormCheckbox, FormColorPicker, FormImage } from 'helpers/form';
import { IUseFormReturn } from 'helpers/form/use-form';
import { IAffiliationForm } from 'pages/affiliation/types';

const AffiliationEditMobileAppTab: React.FC<{
  affiliationForm: IUseFormReturn<Partial<IAffiliationForm>>;
  disableFields: boolean;
}> = ({ affiliationForm, disableFields }) => {
  const { formData, handlers, values } = affiliationForm || {};

  return (
    <ScrollWrapper>
      <InfoBoxV2 gridTemplateColumns="1fr 1fr 1fr" className="text-no-transform " wrapperClassName="py-3">
        <FormCheckbox
          className="uses_conferencing"
          name="uses_conferencing"
          key="uses_conferencing"
          label="Uses Conferencing"
          {...formData?.uses_conferencing}
          {...handlers}
          disabled={disableFields}
        />
        <FormCheckbox
          className="uses_meeting_calendars"
          name="uses_meeting_calendars"
          key="uses_meeting_calendars"
          label="Uses Meeting Calendars"
          {...formData?.uses_meeting_calendars}
          {...handlers}
          disabled={disableFields}
        />
        <FormCheckbox
          className="uses_product_baskets"
          name="uses_product_baskets"
          key="uses_product_baskets"
          label="Uses Product Baskets"
          {...formData?.uses_product_baskets}
          {...handlers}
          disabled={disableFields}
        />
        <FormCheckbox
          className="uses_mm3_products"
          name="uses_mm3_products"
          key="uses_mm3_products"
          label="Uses MM3 Products"
          {...formData?.uses_mm3_products}
          {...handlers}
          disabled={disableFields}
        />
        <FormCheckbox
          className="uses_mm3_assets"
          name="uses_mm3_assets"
          key="uses_mm3_assets"
          label="Uses MM3 Assets"
          {...formData?.uses_mm3_assets}
          {...handlers}
          disabled={disableFields}
        />
        <div></div>
        <FormColorPicker
          name="mobile_app_background_color"
          label="Background Color (Generic App)"
          type="color"
          {...formData?.mobile_app_background_color}
          {...handlers}
          value={values?.mobile_app_background_color}
          disabled={disableFields}
        />
        <FormColorPicker
          name="mobile_app_signature_color"
          label="Signature Color (Generic App)"
          type="color"
          {...formData?.mobile_app_signature_color}
          {...handlers}
          value={values?.mobile_app_signature_color}
          disabled={disableFields}
        />
        <div></div>
        <FormImage
          type="preview"
          name="splashScreenUrl"
          label="Splash Screen Image"
          large
          {...formData?.splashScreenUrl}
          {...handlers}
        />

        <FormImage
          type="attachment"
          name="splashScreenVideoUrl"
          label="Splash Screen Video"
          large
          {...formData?.splashScreenVideoUrl}
          {...handlers}
          extensions={['.mp4']}
          customMessage="Select a video to upload"
          fileLabel="video"
        />
      </InfoBoxV2>
    </ScrollWrapper>
  );
};

export default AffiliationEditMobileAppTab;
