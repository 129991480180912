import React from 'react';

import { GroupTypes, IMarketingEvent, ISearchFilter } from 'types';
import { IAddToGroupEntityType, IAddToGroupForm, IUseSelectionDialogProps } from './types';

import { pluralEntityInflect } from 'utils/general';
import { IGroupActionName } from 'utils/actions/common';
import { ToastError } from 'components/toast';
import { DialogStore } from 'store/dialog-store';
import { ToastStore } from 'store/toast-store';
import { defaultInitialValues } from 'components/add-to-group-dialog/constants';
import {
  addAssetToGroup,
  addAssetEntitiesToGroup,
  addProductToGroup,
  addContactToGroup,
  addEventsToGroup,
  addOrganizationsToGroup,
  addDifferentContactEntitiesToGroup,
  addContactSelectionsToGroup,
  loadPropsFromExistingProductItems,
  parseDifferentContacts,
  parseGroupAssets,
} from 'utils/apis/groups';

export function buildFilters({
  query,
  groupType,
  groupId,
  entity,
}: {
  query: string;
  groupType: string;
  groupId?: string | number;
  entity: string;
}): ISearchFilter[] {
  if (groupId) {
    return [['id', 'in', [groupId]]];
  }

  const filters: ISearchFilter[] = [
    ['+main', 'q', query],
    ['type', 'eq', groupType],
  ];

  if (groupType === GroupTypes.SELECTION) {
    return [...filters, ['main_entity_type', 'eq', entity]];
  }

  return filters;
}

export const createInitialAddToGroupValues = (
  itemIds: Array<string | number>,
  groupIds: string | number | undefined,
): IAddToGroupForm => ({
  ...defaultInitialValues,
  group_id: groupIds || '',
  item_ids: itemIds || [],
  contacts: [],
});

export const entityTypeToCallback: { [key in IAddToGroupEntityType] } = {
  assets: addAssetToGroup,
  assets_and_selections: addAssetEntitiesToGroup,
  products: addProductToGroup,
  users: addContactToGroup,
  events: addEventsToGroup,
  organizations: addOrganizationsToGroup,
  contacts_and_selections: addDifferentContactEntitiesToGroup,
  contact_selections: addContactSelectionsToGroup,
};

export const existingItemsPropsFetchers = {
  products: loadPropsFromExistingProductItems,
};

export const processAddToGroupData = async (
  values: IAddToGroupForm,
  entityLabel: string,
  props: Partial<IUseSelectionDialogProps>,
  entityType: IAddToGroupEntityType,
): Promise<{ itemsLength: number; entityTypeLabel: string }> => {
  const { group, passPropsFromExistingItems, addSelectedProductAncestors } = props;
  const group_id = group?.id || values.group_id;
  const loadExistingProps = passPropsFromExistingItems ? existingItemsPropsFetchers[entityType] : undefined;

  const existingProps = await loadExistingProps?.(group_id);

  const addToGroupCb = entityTypeToCallback[entityType];
  let itemIds = values.item_ids;
  if (entityType === 'events') {
    const event = group as IMarketingEvent;
    itemIds = [...values.item_ids, ...(event.subevent_group_ids || [])];
  }

  await addToGroupCb({
    ...values,
    group_id,
    item_ids: itemIds,
    ...existingProps,
    addSelectedProductAncestors,
  });

  const labels = {
    contacts_and_selections: 'contact',
    assets_and_selections: 'asset',
  };

  const parsedContacts = parseDifferentContacts(values.contacts);
  const entityTypeLabel = labels[entityType] || entityLabel;
  let itemsLength;
  if (entityType === 'contacts_and_selections') {
    itemsLength = parsedContacts.users.length + parsedContacts.selections_users_count;
  } else if (entityType === 'assets_and_selections') {
    const p = parseGroupAssets(values.contacts);
    itemsLength = values.item_ids.length + p.selectionAssetsCount;
  } else {
    itemsLength = values.item_ids.length;
  }
  return { itemsLength, entityTypeLabel };
};

export const addToGroupOnConfirm = async (
  values: IAddToGroupForm,
  dialogStore: DialogStore,
  toastStore: ToastStore,
  entityLabel: string,
  groupTitle: string,
  props: Partial<IUseSelectionDialogProps>,
  entityType: IAddToGroupEntityType,
): Promise<void> => {
  const { options } = props;
  try {
    const { itemsLength, entityTypeLabel } = await processAddToGroupData(values, entityLabel, props, entityType);

    const { entityWithCount } = pluralEntityInflect(entityTypeLabel, itemsLength);
    toastStore.success(`${entityWithCount} will be added to the ${groupTitle}`);
    options?.onSuccess?.(IGroupActionName.ADD_ENTITIES);
    dialogStore.close();
  } catch (error) {
    toastStore.error(<ToastError error={error} />);
  }
};
