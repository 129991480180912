export const supportedLanguages = {
  ibm: {
    supported_languages: [
      'nl-NL',
      'en-US',
      'en-AU',
      'en-GB',
      'fr-FR',
      'fr-CA',
      'de-DE',
      'it-IT',
      'ja-JP',
      'ko-KR',
      'pt-BR',
      'es-ES',
    ],
  },
  google: {
    supported_languages: [
      'bn-BD',
      'bg-BG',
      'cs-CZ',
      'da-DK',
      'nl-NL',
      'en-US',
      'en-AU',
      'en-IN',
      'en-SG',
      'en-GB',
      'en-US',
      'fr-FR',
      'fr-CA',
      'de-DE',
      'hi-IN',
      'hu-HU',
      'id-ID',
      'it-IT',
      'ja-JP',
      'kn-IN',
      'km-KH',
      'ko-KR',
      'ml-IN',
      'mr-IN',
      'pt-BR',
      'ru-RU',
      'rw-RW',
      'st-ZA',
      'es-ES',
      'es-US',
      'sv-SE',
      'ta-IN',
      'te-IN',
      'tr-TR',
      'ts-ZA',
      've-ZA',
    ],
  },
  azure: {
    supported_languages: [
      'af-ZA',
      'am-ET',
      'ar-AE',
      'ar-BH',
      'ar-DZ',
      'ar-EG',
      'ar-IL',
      'ar-IQ',
      'ar-JO',
      'ar-KW',
      'ar-LB',
      'ar-LY',
      'ar-MA',
      'ar-OM',
      'ar-PS',
      'ar-QA',
      'ar-SA',
      'ar-SY',
      'ar-TN',
      'ar-YE',
      'az-AZ',
      'bg-BG',
      'bn-IN',
      'bs-BA',
      'ca-ES',
      'cs-CZ',
      'cy-GB',
      'da-DK',
      'de-AT',
      'de-CH',
      'de-DE',
      'el-GR',
      'en-AU',
      'en-CA',
      'en-GB',
      'en-GH',
      'en-HK',
      'en-IE',
      'en-IN',
      'en-KE',
      'en-NG',
      'en-NZ',
      'en-PH',
      'en-SG',
      'en-TZ',
      'en-US',
      'en-ZA',
      'es-AR',
      'es-BO',
      'es-CL',
      'es-CO',
      'es-CR',
      'es-CU',
      'es-DO',
      'es-EC',
      'es-ES',
      'es-GQ',
      'es-GT',
      'es-HN',
      'es-MX',
      'es-NI',
      'es-PA',
      'es-PE',
      'es-PR',
      'es-PY',
      'es-SV',
      'es-US',
      'es-UY',
      'es-VE',
      'et-EE',
      'eu-ES',
      'fa-IR',
      'fi-FI',
      'fil-PH',
      'fr-BE',
      'fr-CA',
      'fr-CH',
      'fr-FR',
      'ga-IE',
      'gl-ES',
      'gu-IN',
      'he-IL',
      'hi-IN',
      'hr-HR',
      'hu-HU',
      'hy-AM',
      'id-ID',
      'is-IS',
      'it-CH',
      'it-IT',
      'ja-JP',
      'jv-ID',
      'ka-GE',
      'kk-KZ',
      'km-KH',
      'kn-IN',
      'ko-KR',
      'lo-LA',
      'lt-LT',
      'lv-LV',
      'mk-MK',
      'ml-IN',
      'mn-MN',
      'mr-IN',
      'ms-MY',
      'mt-MT',
      'my-MM',
      'nb-NO',
      'ne-NP',
      'nl-BE',
      'nl-NL',
      'pl-PL',
      'ps-AF',
      'pt-BR',
      'pt-PT',
      'ro-RO',
      'ru-RU',
      'si-LK',
      'sk-SK',
      'sl-SI',
      'so-SO',
      'sq-AL',
      'sr-RS',
      'sv-SE',
      'sw-KE',
      'sw-TZ',
      'ta-IN',
      'te-IN',
      'th-TH',
      'tr-TR',
      'uk-UA',
      'uz-UZ',
      'vi-VN',
      'wuu-CN',
      'yue-CN',
      'zh-CN',
      'zh-CN-sichuan',
      'zh-HK',
      'zh-TW',
      'zu-ZA',
    ],
  },
  speechmatics: {
    supported_languages: [
      'ar',
      'ba',
      'eu',
      'be',
      'bg',
      'yue',
      'ca',
      'hr',
      'cs',
      'da',
      'nl',
      'en',
      'eo',
      'et',
      'fi',
      'fr',
      'gl',
      'de',
      'el',
      'hi',
      'hu',
      'ia',
      'it',
      'id',
      'ja',
      'ko',
      'lv',
      'lt',
      'ms',
      'cmn',
      'mr',
      'mn',
      'no',
      'pl',
      'pt',
      'ro',
      'ru',
      'sk',
      'sl',
      'es',
      'sv',
      'ta',
      'th',
      'tr',
      'ug',
      'uk',
      'vi',
      'cy',
    ],
  },
  xl8: {
    supported_languages: [
      'en-US',
      'en-GB',
      'ko-KR',
      'es-ES',
      'ja-JP',
      'tr-TR',
      'ar-SA',
      'bg-BG',
      'cs-CZ',
      'da-DK',
      'de-DE',
      'el-GR',
      'fi-FI',
      'fr-FR',
      'he-IL',
      'hi-IN',
      'hr-HR',
      'hu-HU',
      'id-ID',
      'it-IT',
      'ms-MY',
      'nl-NL',
      'no-NO',
      'pl-PL',
      'pt-BR',
      'pt-PT',
      'ro-RO',
      'ru-RU',
      'sv-SE',
      'ta-IN',
      'th-TH',
      'uk-UA',
      'vi-VN',
      'yue-Hant-HK',
      'zh',
      'zh-TW',
    ],
  },
  aws: {
    supported_languages: [
      'en-US',
      'ar-AE',
      'te-IN',
      'zh-TW',
      'ta-IN',
      'gd-GB',
      'th-TH',
      'tr-TR',
      'ru-RU',
      'pt-BR',
      'nl-NL',
      'it-IT',
      'id-ID',
      'fr-FR',
      'es-ES',
      'de-DE',
      'ga-IE',
      'af-ZA',
      'ko-KR',
      'hi-IN',
      'cy-GB',
      'ms-MY',
      'he-IL',
      'da-DK',
      'fa-IR',
      'ja-JP',
    ],
  },
};
